import * as mutationName from "@/store/modules/carrental/mutationTypes";
import CarRentalAPI from "@/apis/CarRentalAPI";
import { ElMessage } from "element-plus";
import CarRentalUtils from "@/utils/CarRentalUtils";

const actions = {
  searchAvailVehicles({ commit }, payload) {
    CarRentalAPI.vehicleAvail(
      payload.pickupDateTime,
      payload.returnDateTime,
      payload.pickupLocationCode,
      payload.returnLocationCode,
      payload.driverNationCode,
      payload.vehicleClassCode
    )
      .then((res) => {
        let vehAvailRS = res.data;
        // //console.info(vehAvailRS)
        if (payload.vehicleClassCode) {
          commit(mutationName.UPDATE_VEHICLE_DETAIL, {
            vehAvailRS: vehAvailRS,
          });
        } else {
          commit(mutationName.UPDATE_VEHICLES_INFO, {
            vehAvailRS: vehAvailRS,
          });
        }
      })
      .catch((error) => {
        //console.info(error);
        ElMessage({
          message: error.message,
          type: "error",
        });
      });
  },
  paginationVehicles({ commit }, payload) {
    commit(mutationName.UPDATE_VEHICLES_SHOW, payload);
  },
  updateDriverInfo({ commit }, payload) {
    commit(mutationName.UPDATE_DRIVER_INFO, payload);
  },
  async bookCarRental({ commit }, payload) {
    CarRentalAPI.vehicleRes(
      payload.pickUpDatetime,
      payload.returnDatetime,
      payload.pickupLocationCode,
      payload.returnLocationCode,
      payload.driverInfo,
      payload.vehClassCode,
      payload.rateQualifier,
      payload.coveragePrefs,
      payload.status
    )
      .then((res) => {
        if (res.errors != null) {
          //console.info(res.errors.errors[0]);
          ElMessage({
            message: res.errors.errors[0],
            type: "error",
          });
        } else {
          let vehicleResRQ = CarRentalUtils.getVehicleResRQ(
            payload.pickUpDatetime,
            payload.returnDatetime,
            payload.pickupLocationCode,
            payload.returnLocationCode,
            payload.driverInfo,
            payload.vehClassCode,
            payload.rateQualifier,
            payload.coveragePrefs,
            payload.status
          );
          let vehResRS = res.data;

          commit(mutationName.UPDATE_VEHICLE_BOOKING, {
            vehResRS: vehResRS,
            vehicleResRQ: vehicleResRQ,
          });
        }
      })
      .catch((error) => {
        //console.info(error);
        ElMessage({
          message: error,
          type: "error",
        });
      });
  },
};

export default actions;
