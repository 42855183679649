import * as mutationName from "@/store/modules/booking/mutationTypes";
import BookingAPI from "@/apis/BookingAPI";
import BookingUtils from "@/utils/BookingUtils";

const actions = {
  searchBookings({ commit }, payload) {
    let bookingsRetrieveRQ = BookingUtils.getBookingsRetrieveRQ(
      payload.username,
      payload.bookingId,
      payload.offset,
      payload.limit,
      payload.start,
      payload.end,
      payload.status
    );
    BookingAPI.bookingsRetrieve(bookingsRetrieveRQ)
      .then((res) => {
        let bookingsRS = res.data;
        if (payload.username != null) {
          commit(mutationName.UPDATE_BOOKINGS_INFO, {
            bookingsRS: bookingsRS,
          });
        } else if (payload.bookingId != null) {
          commit(mutationName.UPDATE_BOOKING_DETAILS_INFO, {
            bookingDetailsRS: bookingsRS,
          });
        }
      })
      .catch((error) => {
        //console.info(error);
      });
  },
};

export default actions;
