import traveldocAPI from "@/apis/traveldocAPI";
import traveldocUtils from "@/utils/traveldocUtils";
import * as mutationName from "@/store/modules/traveldoc/mutationTypes";
import {ElMessage} from "element-plus";

const actions = {
  searchTravelDocs({ commit }, payload) {
    const documentQuoteRQ = traveldocUtils.getDocumentQuoteRQ(
      payload.fromCountryCode,
      payload.toCountryCode
    );
    return traveldocAPI.documentQuote(documentQuoteRQ).then((res) => {
      if (res.data && res.data.services) {
        const services = res.data.services;
        let documentViewItems = traveldocUtils.getDocumentViewItems(services);
        commit(mutationName.UPDATE_DOCUMENT_VIEW_ITEMS, {
          documentViewItems: documentViewItems,
        });
      } else {
        commit(mutationName.UPDATE_DOCUMENT_VIEW_ITEMS, {
          documentViewItems: null,
        });
      }
    }).catch((error) => {
      //console.info(error);
      ElMessage({
        message: error.message,
        type: "error",
      });
    })
  },
  bookTravelDoc({ commit }, payload) {
    const documentBookRQ = traveldocUtils.getDocumentBookRQ(
      payload.arrivalDate,
      payload.departureDate,
      payload.emailAddress,
      payload.guestInfos,
      payload.documentTypeId
    );
    return traveldocAPI.documentBook(documentBookRQ).then((res) => {
      return res.data;
    });
  },
};

export default actions;
