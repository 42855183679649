import * as mutationName from "@/store/modules/cruise/mutationTypes";
import cruiseAPI from "@/apis/CruiseAPI";
import cruiseTools from "@/tools/cruiseTools";
import {ElMessage} from "element-plus";

const actions = {
  availCruise({ commit }, payload) {
    const availCruiseRQ = cruiseTools.getSailAvailRQ(payload);
    //搜索可租车辆
    return cruiseAPI
      .sailAvail(availCruiseRQ)
      .then((res) => {
        const availCruiseRS = res.data;
        let cruiseViewItems = null;
        if (
          availCruiseRS.sailingOptions &&
          availCruiseRS.sailingOptions.length > 0
        ) {
          cruiseViewItems = cruiseTools.getCruiseViewItems(
            availCruiseRS.sailingOptions
          );
        }

        commit(mutationName.UPDATE_CRUISE_VIEW_ITEMS, {
          cruiseViewItems: cruiseViewItems,
        });
      })
        .catch((error) => {
          //console.info(error);
          ElMessage({
            message: error.message,
            type: "error",
          });
        });
  },
  categoryFaresAndItinerary({ commit }, payload) {
    //列表中符合该shipCode的所有starDate
    let shipCode = payload.shipCode;
    let startDateTime = payload.startDateTime;
    let cruisePackageCode = payload.cruisePackageCode;
    let startDateList = payload.startDateList;
    const startDate = startDateTime ? startDateTime.split("T")[0] : null;
    const itineraryDescRQ = cruiseTools.getItineraryDescRQ(
      shipCode,
      startDate,
      cruisePackageCode
    );
    let promises = [];
    const itineraryDescRSP = cruiseAPI
      .itineraryDesc(itineraryDescRQ)
      .then((res) => {
        return res.data;
      });
    promises.push(itineraryDescRSP);

    let categoryListRQ = null;
    let categoryLocationFareRSP = null;

    startDateList.forEach((startDate) => {
      categoryListRQ = cruiseTools.getCategoryListRQ(shipCode, startDate, 1);
      categoryLocationFareRSP = cruiseAPI
        .categoryAvail(categoryListRQ)
        .then((res) => {
          return res.data;
        });
      promises.push(categoryLocationFareRSP);
    });

    return Promise.all(promises).then((res) => {
      const itineraryDescRS = res[0];
      const cruiseItinInfos = itineraryDescRS.cruiseItinInfos
        ? itineraryDescRS.cruiseItinInfos
        : [];
      let itineraryDescriptions = [];
      if (cruiseItinInfos && cruiseItinInfos.length > 0) {
        cruiseItinInfos.forEach((cruiseItinInfoItem) => {
          if (cruiseItinInfoItem.portCode) {
            const portCode = cruiseItinInfoItem.portCode;
            const portName = cruiseItinInfoItem.portName;
            const information = cruiseItinInfoItem.information
              ? cruiseItinInfoItem.information.text
              : "";
            const dateTimeDescriptions =
              cruiseItinInfoItem.dateTimeDescriptions;
            let arrivalDateTime = "";
            let arrivalDayOfWeek = "";
            let departureDateTime = "";
            let departureDayOfWeek = "";
            if (dateTimeDescriptions && dateTimeDescriptions.length == 1) {
              arrivalDateTime = dateTimeDescriptions[0].dateTimeDetails;
              arrivalDayOfWeek = dateTimeDescriptions[0].dayOfWeek;
            } else if (
              dateTimeDescriptions &&
              dateTimeDescriptions.length == 2
            ) {
              arrivalDateTime = dateTimeDescriptions[0].dateTimeDetails;
              arrivalDayOfWeek = dateTimeDescriptions[0].dayOfWeek;
              departureDateTime = dateTimeDescriptions[1].dateTimeDetails;
              departureDayOfWeek = dateTimeDescriptions[1].dayOfWeek;
            }

            let itineraryDescViewItem = new cruiseTools.ItineraryDescViewItem(
              portCode,
              portName,
              arrivalDateTime,
              departureDateTime,
              arrivalDayOfWeek,
              departureDayOfWeek,
              information
            );
            itineraryDescriptions.push(itineraryDescViewItem);
          }
        });
        let itineraryViewItem = itineraryDescriptions;

        commit(mutationName.UPDATE_ITINERARY_VIEW_ITEM, {
          itineraryViewItem: itineraryViewItem,
        });
      }
      //关于category
      let categoryLocationFareRS = null;
      let categoryFaresItems = [];
      for (let i = 1; i < res.length; i++) {
        categoryLocationFareRS = res[i];
        if (categoryLocationFareRS) {
          const fareOptions = categoryLocationFareRS.fareOptions;
          const sailingInfo = categoryLocationFareRS.sailingInfo;
          if (sailingInfo) {
            const selectedSailing = sailingInfo.selectedSailing;
            const cruiseLine = selectedSailing.cruiseLine;
            const startDate = selectedSailing.start;
            const shipCode = cruiseLine.shipCode;
            const categoryOptions =
              fareOptions && fareOptions.length > 0
                ? fareOptions[0].categoryOptions
                : [];

            if (categoryOptions && categoryOptions.length > 0) {
              categoryOptions.forEach((categoryOption) => {
                const categoryLocation = categoryOption.categoryLocation;
                const priceInfos = categoryOption.priceInfos;
                let categoryLocationFareViewItem = null;
                if (priceInfos && priceInfos.length > 0) {
                  priceInfos.forEach((priceInfo) => {
                    if (priceInfo.breakdownType === "0101C") {
                      const amount = priceInfo.amount;
                      const currencyCode = priceInfo.currencyCode;
                      //currencyCode 未知
                      categoryLocationFareViewItem =
                        new cruiseTools.CategoryLocationFareViewItem(
                          categoryLocation,
                          startDate,
                          amount,
                          currencyCode,
                          shipCode
                        );
                      categoryFaresItems.push(categoryLocationFareViewItem);
                    }
                  });
                }
              });
            }
          }
        }
      }
      let categoryFaresItem = categoryFaresItems;

      commit(mutationName.UPDATE_CATEGORY_FARES_ITEM, {
        categoryFaresItem: categoryFaresItem,
      });
    });
  },
  itineraryDetail({ commit }, payload) {
    const itineraryDescRQ = cruiseTools.getItineraryDescRQ(
      payload.shipCode,
      payload.startDateTime,
      payload.cruisePackageCode
    );

    return cruiseAPI
      .itineraryDesc(itineraryDescRQ)
      .then((res) => {
        const itineraryDescRS = res.data;
        const cruiseItinInfos = itineraryDescRS.cruiseItinInfos
          ? itineraryDescRS.cruiseItinInfos
          : [];
        let itineraryDescriptions = [];
        if (cruiseItinInfos && cruiseItinInfos.length > 0) {
          cruiseItinInfos.forEach((cruiseItinInfoItem) => {
            if (cruiseItinInfoItem.portCode) {
              const portCode = cruiseItinInfoItem.portCode;
              const portName = cruiseItinInfoItem.portName;
              const information = cruiseItinInfoItem.information
                ? cruiseItinInfoItem.information.text
                : "";
              const dateTimeDescriptions =
                cruiseItinInfoItem.dateTimeDescriptions;
              let arrivalDateTime = "";
              let arrivalDayOfWeek = "";
              let departureDateTime = "";
              let departureDayOfWeek = "";
              if (dateTimeDescriptions && dateTimeDescriptions.length > 0) {
                arrivalDateTime = dateTimeDescriptions[0].dateTimeDetails;
                arrivalDayOfWeek = dateTimeDescriptions[0].dayOfWeek;
              } else if (
                dateTimeDescriptions &&
                dateTimeDescriptions.length === 2
              ) {
                arrivalDateTime = dateTimeDescriptions[0].dateTimeDetails;
                arrivalDayOfWeek = dateTimeDescriptions[0].dayOfWeek;
                departureDateTime = dateTimeDescriptions[1].dateTimeDetails;
                departureDayOfWeek = dateTimeDescriptions[1].dayOfWeek;
              }

              let itineraryDescViewItem = new cruiseTools.ItineraryDescViewItem(
                portCode,
                portName,
                arrivalDateTime,
                departureDateTime,
                arrivalDayOfWeek,
                departureDayOfWeek,
                information
              );
              itineraryDescriptions.push(itineraryDescViewItem);
            }
          });
          const selectedSailing = itineraryDescRS.selectedSailing;
          const shipCode = selectedSailing.shipCode;
          const shipName = selectedSailing.shipName;
          const start = selectedSailing.start;
          // let cruiseBookModel = {
          //   shipCode: shipCode,
          //   shipName: shipName,
          //   start: start,
          // };
          let itineraryViewItem = itineraryDescriptions;

          commit(mutationName.UPDATE_ITINERARY_VIEW_ITEM, {
            itineraryViewItem: itineraryViewItem,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        // router.push({ name: "error-500" });
      });
  },
  categoryFaresList({ commit }, payload) {
    const categoryListRQ = cruiseTools.getCategoryListRQ(
      payload.shipCode,
      payload.startDate,
      payload.guestNum,
      payload.roomRum
    );
    return cruiseAPI
      .categoryAvail(categoryListRQ)
      .then((res) => {
        const categoryLocationFareRS = res.data;
        const fareOptions = categoryLocationFareRS.fareOptions;
        const categoryOptions =
          fareOptions && fareOptions.length > 0
            ? fareOptions[0].categoryOptions
            : [];
        const categoryFareList =
          cruiseTools.getCategoryLocationViewItems(categoryOptions);
        let categoryFaresItem = categoryFareList;

        commit(mutationName.UPDATE_CATEGORY_FARES_ITEM, {
          categoryFaresItem: categoryFaresItem,
        });
      })
      .catch((err) => {
        console.error(err);
        // router.push({ name: "error-500" });
      });
  },
  availCabinList({ commit }, payload) {
    const cabinListRQ = cruiseTools.getCabinAvailRQ(
      payload.shipCode,
      payload.startDate,
      payload.guestNum,
      payload.categoryCode,
      payload.fareCode,
      payload.roomNum
    );
    return cruiseAPI
      .cabinAvail(cabinListRQ)
      .then((res) => {
        const cabinListRS = res.data;
        if (cabinListRS) {
          const cabinOptions = cabinListRS.cabinOptions;
          let cabinInfoList = cruiseTools.getRoomInfoList(cabinOptions);

          commit(mutationName.UPDATE_CABIN_INFO_LIST, {
            cabinInfoList: cabinInfoList,
          });
          return cabinInfoList;
        }
      })
      .catch((err) => {
        console.error(err);
        // router.push({ name: "error-500" });
      });
  },
  availCabinDetailInfo({ commit }, payload) {
    const cabinDetailInfoRQ = cruiseTools.getCabinDetailRQ(
      payload.shipCode,
      payload.startDate,
      payload.cabinNumber
    );
    return cruiseAPI
      .cabinDetail(cabinDetailInfoRQ)
      .then((res) => {
        const cabinDetailRS = res.data;
        if (cabinDetailRS) {
          let cabinOption =
            cabinDetailRS.cabinOptions && cabinDetailRS.cabinOptions.length
              ? cabinDetailRS.cabinOptions[0]
              : {};

          let cabinDetailsInfo = cabinOption;

          commit(mutationName.UPDATE_CABIN_DETAILS_INFO, {
            cabinDetailsInfo: cabinDetailsInfo,
          });
        } else {
          commit(mutationName.UPDATE_CABIN_DETAILS_INFO, {
            cabinDetailsInfo: null,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        // router.push({ name: "error-500" });
      });
  },
  updateCruiseBookModel({ commit, state }, cruiseBookModelUpdate) {
    if (state.cruiseBookModel) {
      let cruiseBookModelCopy = {
        ...state.cruiseBookModel,
      };
      Object.keys(cruiseBookModelUpdate).forEach((key) => {
        cruiseBookModelCopy[key] = cruiseBookModelUpdate[key];
      });

      commit(mutationName.UPDATE_CRUISE_BOOK_MODEL, {
        cruiseBookModel: cruiseBookModelCopy,
      });
    } else {
      commit(mutationName.UPDATE_CRUISE_BOOK_MODEL, {
        cruiseBookModel: cruiseBookModelUpdate,
      });
    }
  },
  getOffers({ commit }, payload) {
    let diningAvailRQ = cruiseTools.getDiningAvailRQ(
      payload.shipCode,
      payload.startDate,
      payload.guestNum,
      payload.roomRum
    );
    return cruiseAPI
      .diningAvail(diningAvailRQ)
      .then((res) => {
        const dinningRS = res.data;
        if (dinningRS) {
          let diningOptions = dinningRS.diningOptions;
          commit(mutationName.UPDATE_CABIN_DINING_OPTIONS, {
            diningOptions: diningOptions,
          });
        } else {
          commit(mutationName.UPDATE_CABIN_DINING_OPTIONS, {
            diningOptions: null,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        // router.push({ name: "error-500" });
      });
  },
  holdCabin({ commit }, payload) {
    let cabinHoldRQ = cruiseTools.getCabinHoldRQ(
      payload.shipCode,
      payload.startDate,
      payload.guestNum,
      payload.categoryCode,
      payload.fareCode,
      payload.cruisePackageCode,
      payload.cabinNumbers
    );
    return cruiseAPI.cabinHold(cabinHoldRQ).then((res) => {
      //console.info(res.data);
    });
  },
  priceBooking({ commit }, payload) {
    let priceBookingRQ = cruiseTools.getPriceBookingRQ(
      payload.shipCode,
      payload.startDate,
      payload.categoryCode,
      payload.fareCode,
      payload.cruisePackageCode,
      payload.cabinNumber,
      payload.roomGuestInfos
    );
    return cruiseAPI.priceBooking(priceBookingRQ).then((res) => {
      const priceBookRS = res.data;
      const bookingPayment = priceBookRS.bookingPayment;
      if (bookingPayment && bookingPayment.paymentSchedule) {
        const bookingPrices = bookingPayment.bookingPrices;
        const guestPrices = bookingPayment.guestPrices;
        const paymentSchedule = bookingPayment.paymentSchedule;
        let priceBookingDetails = new cruiseTools.PriceBookingDetailsViewItem(
          bookingPrices,
          paymentSchedule.payments,
          guestPrices,
          priceBookRS.reservationIDs
        );
        commit(mutationName.UPDATE_PRICE_BOOKING_DETAILS, {
          priceBookingDetails: priceBookingDetails,
        });
        return { success: true };
      } else {
        let message = "";
        if (priceBookRS.warnings && priceBookRS.warnings.warnings) {
          let codeSet = {};
          priceBookRS.warnings.warnings.forEach((warning) => {
            if (!codeSet[warning.type]) {
              message += warning.stringValue + "<br/>";
              codeSet[warning.type] = "1";
            }
          });
        }
        commit(mutationName.UPDATE_PRICE_BOOKING_DETAILS, {
          priceBookingDetails: null,
        });
        return {
          success: false,
          type: "warning",
          message: message,
        };
      }
    });
  },
  confirmBooking({ commit }, payload) {
    let bookingRQ = cruiseTools.getBookRQ(
      payload.shipCode,
      payload.startDate,
      payload.categoryCode,
      payload.fareCode,
      payload.cruisePackageCode,
      payload.cabinNumber,
      payload.roomGuestInfos
    );
    return cruiseAPI.booking(bookingRQ).then((res) => {
      //console.info(res);
    });
  },
};

export default actions;
