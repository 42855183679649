const messages = {
  en: {
    "(a) Acident while in a common carrier":
      "(a) Acident while in a common carrier",
    "(b) Other accidents": "(b) Other accidents",
    "1-room-fits": "1 room fits",
    "Avg-person": "Avg / person ",
    "Death or permanent disablement arising within 12 months of an accident.":
      "Death or permanent disablement arising within 12 months of an accident.",
    PNR: "PNR",
    "ROOM-uppercase": "ROOM",
    "Section 1 - Accidental Death and Disablement *":
      "Section 1 - Accidental Death and Disablement *",
    Visa: "visa",
    aboard: "Aboard",
    "about-hotel-description": "About Hotel Description",
    "about-this-activity": "About this activity",
    "accept-special-service-applications":
      "In order to facilitate the travel of more passengers, this flight can accept some special service applications. You can complete your application in a few simple steps",
    "accessorial-service": "Accessorial Service",
    account: "Account",
    "account-code": "Account Code",
    action: "Action",
    activities: "Activities",
    "activities-home": "Activities Home",
    "activities-list": "Activities List",
    "activity-duration": "Activity duration: ",
    "activity-reservation": "Activity  Reservation",
    add: "Add",
    "add-a-flight": "Add A Flight",
    "add-account": "Add account",
    "add-addition": "Add additional services",
    "add-ancillary-service": "Add Ancillary Service",
    "add-another": "Add another",
    "add-another-flight": "Add another flight",
    "add-another-person": "Add another person",
    "add-another-room": "Add another room",
    "add-baggage": "Add baggage",
    "add-bags": "Add bags",
    "add-check-in-baggage": "Add check-in baggage",
    "add-email-address": "Add Email Address",
    "add-entertainment": "Add Entertainment",
    "add-meal": "Add meal",
    "add-new-flight": "Add new flight",
    "add-ons-pick": "add-ons pick",
    "add-passenger-type": "Add passenger type",
    "add-passengers": "Add passengers",
    "add-phone-number": "Add Phone Number",
    "add-seat": "Add seat",
    "add-seats": "Add seats",
    "add-special-assistance": "Add special assistance",
    "additional-service-charge": "Additional service charge",
    "additional-service-selected": "Additional services have been selected",
    "additional-services-menu": "Additional services menu",
    address: "Address",
    admin: "Admin",
    adult: "Adult",
    "adult(s)": "Adult(s)",
    "adult(s)-to": "adult(s) to",
    "adult-child-infant":
      "{adultNum}  adult(s), {childNum} child(s) , {infantNum}  infant(s)",
    "adult-fare": "Adult Fare",
    "adult-price": "Adult Price",
    adults: "Adult(s)",
    "adults-uppercase": "ADULTS",
    "advance-question": "Advance question",
    "advance-search": "Advance Search",
    "affected-reservations": "Affected reservations",
    "after-change-amount": "After change amount",
    "after-issued": "After issued",
    "after-pm": "Afternoon (12:00pm - 5:59pm)",
    "afternoon-filter-time": "Afternoon (12:00-17:59)",
    age: "Age",
    "age-from-to": "{fromAge} to {toAge} years old",
    "age-range": "Age range : {range}",
    "agency-portal": "Agency Portal",
    "agree-to-terms-conditions": "I agree to Terms &amp; Conditions",
    "agreement-between-customer-and-sesamedoortravel":
      "Agreement between customer and sesamedoortravel.com",
    air: "Air",
    "air-equip-type": "Air Equip Type",
    "air-item": "Air Item",
    "air-reservation": "Air Reservation",
    "air-shopping": "Air Shopping",
    "air-shopping-uppercase": "AIR SHOPPING",
    "air-tickets": "Air Tickets",
    "airfield-radius": "Airfield Radius",
    airline: "Airline",
    "airline-RLOC": "Airline RLOC",
    "airline-record-locator": "Airline Record Locator",
    airlines: "Airlines",
    "airport-shuttle-included": "Airport shuttle included",
    all: "All",
    "all-inclusive": "All Inclusive",
    "all-languages": "All Languages",
    "all-photos": "All Photos",
    "all-reservations": "All Reservations",
    "all-trips": "All Trips",
    "already-have-an-account": "Already have an account?",
    "alteration-cost": "Alteration Cost",
    amenities: "Amenities",
    "amenities-of-hotel": "AMMENTIES OF HOTEL",
    amenity: "Amenities",
    "amenity-of-hotel": "AMMENTIES OF HOTEL",
    amount: "Amount",
    ancillary: "Ancillary",
    "ancillary-service-selected": "Ancillary service selected",
    "ancillary-services": "Ancillary services",
    any: "Any",
    "any-uppercase": "Any",
    "apart-hotel": "Apart-hotel",
    applicant: "applicant",
    "applicant-personal-details": "Applicant #{ index} Personal Details",
    application: "Application",
    "apply-contacts-change": "Apply Contacts Change",
    "apply-doc-change": "Apply Docs Change",
    "apply-ffp-change": "Apply FFP Change",
    "apply-insurance": "Apply",
    "apply-name-change": "Apply Name Change",
    "apply-now": "Apply Now",
    "apply-now-for-document": "Apply Now for {toCountryName} Travel Documents",
    "applying-for": "Applying for",
    approve: "Approve",
    "are-you-sure": "Are you sure?",
    "are-you-sure-ticketing": "Are you sure to ticketing ?",
    "area-code": "Area Code",
    "arr-time": "Arr. Time",
    arrival: "Arrival",
    "arrival-airport": "Arrival Airport",
    "arrival-date": "Arrival Date",
    "arrival-time": "Arrival Time",
    "arrival-time-in-destination": "Arrival Time in Destination",
    "arrive-at": "Arrive at",
    "arriving-station": "Arriving Station",
    ascending: "Ascending",
    assistance: "Assistance",
    "assistance-option": "Assistance option",
    "at-sea": "AT SEA",
    attributes: "Attributes",
    available: "Available",
    "available-credit": "Available Credit",
    "available-dates": "Available dates",
    "available-extras": "Available extras",
    "available-flight": "Available flights",
    "average-user-rating": "Average user rating",
    avg: "Avg",
    "awesome-amsterdam": "Awesome Amsterdam",
    back: "Back",
    "back-to-dinning": "BACK TO DINNING",
    "back-to-home": "Back to home",
    "back-to-list": "Back to list",
    "back-to-reservation": "Back to reservation",
    "back-to-results": "Back To Results",
    "back-to-room": "BACK TO ROOM",
    "back-to-submit-order": "Back to submit order",
    "bag-allow": "Bag Allow",
    baggage: "Baggage",
    "baggage-allowance": "Baggage Allowance",
    "baggage-option": "Baggage option",
    "baggage-option-in-the-flight": "Baggage option in the flight",
    "baggage-price": "Baggage price",
    bar: "Bar",
    "base-amount": "Base Amount",
    "base-fare": "Base Fare",
    basic: "BASIC",
    "basic-description": "Basic Description",
    "basic-rate": "Basic Rate",
    "basic-search": "Basic Search",
    "beach-goa": "Beach Goa",
    "become-a-partner": "Become A Partner",
    "bed-type": "Bed Type",
    before: "Before",
    "before-change-amount": "Before change amount",
    "best-price": "Best Price",
    "best-pricing-code": "Best pricing code",
    "birth-date": "Birth Date",
    "birth-date-correction": "Birthday Correction",
    blog: "BLOG",
    book: "Book",
    "book-car-transfer": "Book Car Transfer",
    "book-class-code": "Book Class Code",
    "book-cruise": "Book Cruise",
    "book-cruise-tickets": "Book Cruise Tickets",
    "book-flight-tickets": "Book Flight Tickets",
    "book-holiday-package": "Book Holiday Package",
    "book-hotel-rooms": "Book Hotel Rooms",
    "book-now": "BOOK NOW",
    "book-tour-activity": "Book Tour Activity",
    "book-travel-document": "Book Visa",
    "booking-amount": "Booking amount",
    "booking-canceled": "Booking Canceled",
    "booking-class": "Booking Class",
    "booking-closed": "Booking Closed",
    "booking-completed": "Booking  Completed",
    "booking-confirm": "Booking Confirm",
    "booking-confirmed": "Your reservation #{ bookingId } is confirmed",
    "booking-create-date": "Booking Create Date",
    "booking-date": "Booking Date",
    "booking-date-range": "Booking Date Range",
    "booking-date-start-to-end": "Booking date start to Booking date  end",
    "booking-details": "Booking details",
    "booking-engine": "Sesame Door Travel",
    "booking-id": "Booking ID",
    "booking-in-process": "Booking In Process",
    "booking-info": "Booking Info",
    "booking-number": "Booking Number",
    "booking-overview": "Booking Overview",
    "booking-paid": "Booking  Paid",
    "booking-preview": "Booking Preview",
    "booking-progress": "Booking Progress",
    "booking-received": "Booking  Received",
    "booking-status": "Booking Status",
    "booking-success": "Booking success!",
    "booking-summary": "Booking Summary",
    "booking-total": "Booking Total",
    bookings: "Bookings",
    brand: "Brand",
    breakfast: "Breakfast",
    "breakfast-included": "Breakfast Included",
    business: "Business",
    "business-cabin": "Business",
    "business-class": "Business",
    "buy-now-and-save-at-least": "Buy now and SAVE at least 30%!",
    "by-credit-card": "By Credit Card",
    cabin: "Cabin",
    "cabin-class": "Cabin Class",
    "cabin-number": "Cabin Number",
    "cabin-price": "Cabin Price",
    "cabin-type": "Cabin type",
    "call-us": "Call us for any help",
    cancel: "Cancel",
    childs: "Child(s)",
    "cancel-at": "Cancel at: ",
    "cancel-booking": "Cancel booking",
    "cancel-error": "Cancel Error!",
    "cancel-policies": "Cancel Policies",
    "cancel-policy": "Cancel Policy",
    "cancel-reservation": "Cancel Reservation",
    "cancel-successful": "Cancel successfully!",
    "cancel-ticket": "Cancel Ticket",
    cancellable: "Cancellable",
    "cancellation-policies": "CANCELLATION POLICIES",
    "cancellation-policy": "Cancellation Policy",
    "cancellation-policy-nights-caution":
      'Cancel your reservation after <span class="h6 text-danger">{beginDate } </span> you\'ll be charged for the <span class="text-warning">{nmbrOfUnit} night(s)</span>  of your stay plus taxes and fees. Times are based on the property’s local time.',
    "cancellation-policy-nonrefundable-caution":
      'Cancel your reservation before <span class="h6 text-danger">{beginDate } </span> that  you\'ll be charged all. Times are based on the property’s local time.',
    "cancellation-policy-refundable-caution":
      'Cancel your reservation after <span class="h6 text-danger">{beginDate } </span> that  you\'ll be charged <span class="text-warning">{currency} {amount}</span>. Times are based on the property’s local time.',
    "cancellation-quote": "Cancellation Quote",
    "cancellations-after": "Cancellations after",
    "cancellations-hotel-with-penalty":
      "Cancellations after {date} will incur a {currencyCode} {amount} penalty. ",
    cancelled: "Cancelled",
    "car-brand": "Car Brand",
    "car-details": "Car Details",
    "car-features": "Car Features",
    "car-info": "Car Info",
    "car-name": "Car name",
    "car-rental": "Car Rental",
    "car-rental-home": "Car Rental Home",
    "car-rental-info": "Car Rental Info",
    "car-rental-list": "Car Rental List",
    "car-rental-price": "Car Rental Price",
    "car-rental-reservation": "Car Rental Reservation",
    "car-rental-search": "Car Rental Search",
    "car-transfer": "Car Transfer",
    "car-transfer-home": "Car Transfer Home",
    "car-transfer-info-first-trip": "Car Transfer Info - First Trip",
    "car-transfer-info-second-trip": "Car Transfer Info - Second Trip",
    "car-transfer-reservation": "Car Transfer Reservation",
    "car-transfer-shuttles": "Car Transfer and Shuttles",
    "car-transfer-total-price": "Car Transfer Total Price",
    carRental: "Car Rental",
    "card-code": "Card Code",
    "card-number": "Card Number",
    career: "Career",
    cars: "CARS",
    carts: "Carts",
    cash: "Cash",
    "casino-los-vages": "Casino Los Vages",
    "category-uppercase": "CATEGORY",
    change: "Change",
    "change-flight": "Change Flights",
    "change-flight-confirm":
      "This will be charged. Please note - future changes to this order will require support assistance.",
    "change-itinerary": "Change Itinerary",
    "change-order": "Change order {orderName}",
    "change-password": "Change Password",
    "change-reservation": "Change reservation {orderName}",
    "changes-detected": "Changes detected",
    "changes-summary": "Changes summary",
    channel: "Channel",
    "charge-type": "Charge Type",
    "check---in": "Check - In",
    "check--out": "Check -Out",
    "check-availability": "Check availability",
    "check-date": "Check Date",
    "check-email-info":
      "You can check your email for further details. We have sent you a mail with details.",
    "check-in": "Check-in",
    "check-in-and-important-information": "Check in and important information",
    "check-in-date": "Check In Date",
    "check-in-to-check-out": "Check-in to Check-out",
    "check-out": "Check Out",
    "check-out-date": "Check Out Date",
    "check-out-your-choices": "Check out your choices",
    "check-your-email-for-details":
      "You can check your email for further details. We have sent you a mail with details.",
    checkin: "Check In",
    checkout: "Check Out",
    child: "Child",
    "child(ren)": "Child(ren)",
    "child-age": "Child Age",
    "child-fare": "Child Fare",
    "child-index-age": "child { childIndex} age ",
    "child-less-than": "2 - Less than 12 yrs",
    "child-price": "Child Price",
    children: "Children",
    "children-ages": "Children Ages 0 to 17",
    "children-uppercase": "CHILDREN",
    chinese: "Chinese",
    "chinese-food": "Chinese",
    choose: "Choose",
    "choose-a-save-one": "Choose a save one..",
    "choose-flight-to": "Choose flight to {locationName}",
    "choose-frequent-flyer-airline": "Choose Frequent Flyer Airline",
    "choose-frequent-flyer-airline-option":
      "Choose Frequent Flyer Airline(option)",
    "choose-to-travel": "Choose to travel",
    "choose-your-category-location-room": "Choose Your {categoryLocation} Room",
    "choose-your-room": "Choose your room",
    "city-name": "City Name",
    class: "Class",
    close: "Close",
    "code-input": "Code input",
    "comfort-category": "Comfort Category",
    comments: "Comments",
    "common-area": "Common Area",
    company: "Company",
    condo: "Condo",
    configuration: "Configuration",
    configurations: "Configurations",
    confirm: "Confirm",
    "confirm-and-pay-with-balance": "Confirm and pay with balance",
    "confirm-booking": "Confirm Booking",
    "confirm-cancel-of":
      "If you cancel the flight now, We will return {currency} {refundPrice} to you. Do you confirm the cancellation ?",
    "confirm-delete-flight": "Are you sure to delete this flight ?",
    "confirm-modify": "Confirm Modify",
    "confirm-password": "Confirm Password",
    "confirm-payment-of": "Confirm payment of {currency} {amount}",
    "confirm-ticketing-now": "Confirm ticketing now ?",
    "confirm-to-change-to-your-current-name":
      "Do you confirm to change to your current name",
    "confirm-to-change-to-your-frequent-flyer-number":
      "Do you confirm to change to your Frequent Flyer number",
    "confirm-to-change-to-your-passport":
      "Do you confirm to change to your Passport",
    "confirm-your-choices": "Confirm Your Choices",
    "confirmation-number": "Confirmation Number:",
    "confirmation-number-with-supplier": "Confirmation Number({supplierName})",
    confirmed: "Confirmed",
    "confirmed-uppercase": "CONFIRMED",
    connection: "connection",
    contact: "Contact",
    "contact-address":
      "FLAT/RM 1411, 14/F, EASTERN HARBOUR CENTRE, 28 HOI CHAK STREET, QUARRY BAY, HK",
    "contact-agent": "Contact Agent",
    "contact-hotel": "Contact Hotel",
    "contact-info": "Contact Info",
    "contact-information": "Contact Information",
    "contact-number": "Contact Number",
    "contact-person": "Contact Person",
    "contact-us": "Contact Us",
    "contact-with-us": "Contact with us",
    "content-options": "Content options",
    continue: "CONTINUE",
    "continue-modify": "Do you want to continue modifying ?",
    "continue-pay-services":
      "Do you want to continue pay {currencyCode} {amount} now?",
    "continue-to-confirm": "Continue to Confirm",
    "continue-to-passengers": "Continue to Passengers",
    "continue-to-payment": "Continue To Payment",
    "contract-id": "Contract ID",
    "contract-remarks": "Contract Remarks",
    "copy-right": "Copyright © 2023 All Rights Reserved",
    "copyright-info": " Copyright © {year} LimpidThemes. All Rights Reserved.",
    "cost-of-change": "Cost of change",
    country: "Country",
    "country-code": "Country Code",
    "country-of-residence": "Country of residence*",
    "country-to-visit": "Country To Visit",
    coverage: "Coverage",
    "coverage-info": "Coverage Info",
    create: "Create",
    "create-account": "Create account",
    "create-by": "Create By",
    "create-date": "Create Date",
    "create-new": "Create New",
    "create-new-employee": "Create New Employee",
    "create-time": "Create time",
    "create-uppercase": "CREATE",
    "created-date": "Created Date",
    "created-date-range": "Created Date Range",
    "credit-card": "Credit card",
    "credit-card-info": "Credit Card Info",
    cruise: "Cruise",
    "cruise-dates": "Cruise Dates",
    "cruise-dinning":
      "In February 2019, Celebrity Summit took part in Celebrity’s fleet-wide Revolution. Restaurants and lounges became more than just dining rooms and pass time areas. Staterooms became home away from home and guests’ personal sanctuaries. Celebrity Summit stopped being just a ship and became a better way to experience the world.",
    "cruise-holidays": "Cruise Holidays",
    "cruise-home": "Cruise Home",
    "cruise-itinerary": "Cruise Itinerary",
    "cruise-package-code": "Cruise package code",
    "cruise-reservation": "Cruise Reservation",
    "cruise-search-results": "Cruise Search Results",
    "cruise-shopping": "Cruise Shopping",
    currency: "Currency",
    "currency-tag": "Currency Tag",
    currencytag: "CurrencyTag",
    "current-address": "Current Address",
    "current-language": "Current Language",
    "current-passenger": "Current Passenger",
    "current-segment": "Current Segment",
    "current-selection-details": "Current selection details",
    "customer-max-waiting-time": "Customer Max Waiting Time",
    "customize-your-trip": "Customize your trip",
    "daily-prices": "Daily Prices",
    "daily-rates": "Daily Rates",
    dashboard: "Dashboard",
    date: "Date",
    "date-of-birth": "Date Of Birth",
    "date-range": "Date Range",
    day: "Day",
    "day(s)": "Day(s)",
    days: "Days",
    "days-after-departure": "Days after departure",
    "days-before-departure": "Days before departure",
    "deadline-for-reservations": "The deadline for reservations is {dateTime}",
    "dedicated-support": "Dedicated Support",
    "deferred-payment": "Deferred payment",
    delete: "Delete",
    "delivered-at": "Delivered at",
    "departing-from": "Departing From",
    "departing-on": "Departing On",
    "departing-station": "Departing Station",
    department: "Department",
    departments: "Departments",
    departs: "Departs",
    departure: "Departure",
    "departure-airport": "Departure Airport",
    "departure-date": "Departure date",
    "departure-from": "Departure from",
    "departure-level": "Departure Level",
    "departure-port": "Departure Port",
    "departure-time": "Departure Time",
    "departure-time-in-origin": "Departure Time in Origin",
    "departure-to-return": "Departure to Return",
    deposit: "deposit",
    "deposit-in": "Deposit {index} in {key}",
    "dept-time": "Dept. Time",
    descending: "Descending",
    description: "Description",
    "description-uppercase": "DESCRIPTION",
    "desired-pick-up-time": "Desired pick-up time",
    "desk-name": "desk name",
    destination: "Destination",
    "destination-port": "Destination Port",
    details: "DETAILS",
    developers: "Developers",
    dinner: "Dinner",
    "dinner-included": "Dinner Included",
    direct: "Direct",
    "direct-ticketing": "Direct Ticketing",
    "directly-to-airline": "Directly to Airline",
    discount: "discount",
    discounts: "Discounts",
    "discover-how-they-work,-what-tools-they-offer-and-how-to-choose-the-right-one-for-your-business":
      "Discover how they work, what tools they offer and how to choose the right one for your business.",
    "discover-your-ideal": "Discover your ideal experience with us",
    docked: "Docked",
    documents: "documents",
    "domestic-flights": "Domestic Flights",
    "domestic-hotels": "Domestic Hotels",
    done: "Done",
    "dont-have-a-account": "Dont have an account",
    door: "Door",
    doors: "doors",
    "double-room": "Double Room",
    dr: "Dr.",
    "driver-details": "Driver Details",
    "driver-info": "Driver Info",
    "driver-nation": "Driver Nation",
    "driver-nationality": "Driver Nationality",
    "drivers-can-wait": "Drivers can wait up to",
    "drop-off-date-time": "Drop Off Date Time",
    "drop-off-location": "Drop Off Location",
    duration: "Duration",
    "duration-far-to-near": "Duration:far to near",
    "duration-near-to-far": "Duration:near to far",
    "each-passenger-can-select": "Each passenger can select one option.",
    "early-morning-am": "Early Morning (12:00am - 4:59am)",
    economy: "Economy",
    edit: "Edit",
    "edit-baggage": "Edit Baggage",
    "edit-frequent-flyer-number": "Edit Frequent Flyer number",
    "edit-meal": "Edit Meal",
    "edit-name": "Edit Name",
    "edit-passport-number": "Edit Passport Number",
    "edit-profile": "Edit Profile",
    "edit-seat": "Edit Seat",
    "edit-special-service": "Edit special service",
    email: "Email",
    "email-address": "Email address",
    "email-correction": "Email Correction",
    "email-id": "Email-ID",
    "employee-position": "Employee Position",
    employees: "Employees",
    "employment-positions": "Employment Positions",
    enabled: "Enabled",
    "end-date": "End Date",
    english: "English",
    "enter-a-correct-email": "Please enter a correct email",
    "enter-a-email-address": "Enter a email address",
    "enter-known-traveler-number-option":
      "Enter known traveler number (optional)",
    "enter-redress-number-option": "Enter redress number (optional)",
    "enter-the-email": "Please enter the email combined with your account",
    "enter-valid-credential": "Please enter a valid credential",
    "enter-your-answer": "Enter your answer",
    "enter-your-passport": "Enter your passport",
    "enter-your-passport-optional": "Enter your passport (optional)",
    "enter-your-phone-number": "Enter your phone number",
    entertainment: "Entertainment",
    "entertainment-option": "Entertainment Option",
    "eps-terms-and-conditions": "EPS's Terms and Conditions",
    "equip-type": "Equip type",
    "equiv-fare": "Equiv Fare",
    error: "Error",
    "error-400-message":
      "You have issued an incorrect request, or it is corrupt and the server cannot understand it",
    "error-401-message":
      "We are sorry but you are not authorized to access this page",
    "error-403-message":
      "We are sorry but you do not have permission to access this page",
    "error-404-message":
      "We are sorry but the page you are looking for was not found",
    "error-500-message":
      "We are sorry but our server encountered an internal error",
    "error-503-message":
      "We are sorry but our service is currently not available",
    "essential-insurance": "Essential",
    evening: "Evening",
    "evening-filter-time": "Evening (18:00-23:59)",
    "evening-pm": "Evening (6:00pm - 11:59pm)",
    excellent: "Excellent",
    exclude: "Exclude",
    excluding: "Excluding",
    "expect-to-wait": "Expect to wait",
    "expire-date": "Expire date",
    "expire-date-optional": "Expire date (optional)",
    "expire-time-min-s": "Expire in { minutes } min {second}s",
    "expiry-date": "Expiry Date",
    "expiry-time": "Expiry Time",
    explore: "EXPLORE",
    "explore-our-hot-deals": "Explore our hot deals",
    "explore-this-itinerary": "EXPLORE THIS ITINERARY",
    "export-to-sabre": "Export To Sabre",
    exterior: "Exterior",
    extra: "Extra",
    "extra-insurance": "Extra",
    "extra-price": "Extra Price",
    "extra-service": "Extra Service",
    "extra-service-total": "Extra Service Total",
    extras: "Extras",
    facilities: "Facilities",
    "facility-name": "Facility Name",
    "family-name": "Family Name",
    faq: "FAQ",
    fare: "Fare",
    "fare-basic": "Fare Basic",
    "fare-basis": "Fare Basis",
    "fare-basis-code": "Fare Basis Code",
    "fare-basis-codes": "Fare Basis Codes",
    "fare-before-commission": "Fare before commission",
    "fare-details": "FARE DETAILS",
    "fare-family": "Fare family",
    "fare-options": "Fare Options",
    "fare-quotes": "Fare Quotes",
    "fare-restrictions": "Fare Restrictions",
    "fare-rule-description": "Fare Rule Description",
    "fare-rules": "Fare Rules",
    "fare-type": "Fare Type",
    "fare-type-code": "Fare type code",
    "fare-types": "Fare Types",
    "fares-and-fees": "Fares And Fees",
    "fast-links": "Fast Links",
    fees: "Fees",
    "fees-and-taxes": "Fees And Taxes",
    "fees-info": "Fees Info",
    "fees-total": "Fees Total",
    female: "Female",
    ferries: "Ferries",
    "ferries-and-buses": "Ferries And Buses",
    "fill-following-details":
      "Please fill the following details to create a new account.",
    "filter-by": "Filter by",
    "filter-by-price": "Filter by price",
    "filter-condition": "Filter condition",
    "find-available-cruises": "Find Available Cruises",
    "finding-driver": "Finding your driver",
    first: "First",
    "first-cabin": "First",
    "first-class": "First",
    "first-name": "First name",
    "first-travel-date": "First travel date",
    "first-trip": "First Trip",
    "first-trip-description": "First Trip Description",
    fit: "Fit",
    "fit-description": "Fit Description",
    "flexibility-flight": "Flexibility",
    "flexibility-your-own-flying-experience":
      "We will bring you a new booking experience that lets you have it all Flexibility to customize your own flying experience",
    "flexible-search": "Flexible search",
    flight: "Flight",
    "flight-alteration-price": "Alteration Total Cost",
    "flight-arrival-time": "Flight Arrival Time",
    "flight-booking-preview-reminder":
      "Reminder: Selected fare is not support held booking with ancillary\n          services. Selected ancillary serivces will not be added to the\n          booking. Please purchase ancillary services after ticketing.",
    "flight-cancellation-failure": "Flight cancellation failure",
    "flight-cancellation-successful": "Flight cancellation successful",
    "flight-class": "Flight class",
    "flight-date": "Flight Date",
    "flight-departure-time": "FLight Departure Time",
    "flight-detail": "Flight Detail",
    "flight-details": "Flight Details",
    "flight-duration": "Flight duration",
    "flight-fare-price": "Fare Total Price",
    "flight-home": "Flight Home",
    "flight-itinerary": "Flight Itinerary",
    "flight-list": "Flight List",
    "flight-number": "Flight Number",
    "flight-passenger": "Flight Passenger",
    "flight-preference": "Flight Preference",
    "flight-price": "Flight Price",
    "flight-search": "Flight Search",
    "flight-tickets": "Tickets",
    "flight-to": "Flight to {locationCode}",
    "flight-to-location": "Flight to {location}",
    "flight-total-payable": "Flight Total Payable",
    "flight-total-price": "Flight Total Price",
    "flight-version-price": "Revision {index} fee ",
    flights: "Flights",
    "flying-time": "Flying Time",
    "follow-us": "Follow us on",
    "food-preference": "Food Preference",
    "for-all-applicants": "For all applicants",
    "for-room": "for {roomNum} room(s) , {nightNum} night(s)",
    "forget-password": "Forget Password",
    "forgot-password": "Forgot Password",
    "found-matching-your-search": "Found Matching Your Search",
    "free-cancel": "Free Cancel",
    "free-cancel-before": "Free Cancel before",
    "free-cancel-date": "Free Cancel Date",
    "free-cancel-date(before)": "Free cancel date(before)",
    "frequent-answer-and-question": "Frequent answer and question",
    "frequent-flyer-airline": "Frequent Flyer Airline",
    "frequent-flyer-airline-correction": "Frequent Flyer Airline Correction",
    "frequent-flyer-airline-number": "Frequent Flyer Airline Number",
    "frequent-flyer-airline-number-option":
      "Frequent Flyer Airline Number(option)",
    "frequent-flyer-number": "Frequent flyer number",
    "frequent-flyer-number-correction": "Frequent flyer number correction",
    from: "From",
    "from-to": "From {from} to {to}",
    front: "Front",
    games: "Games",
    gender: "Gender",
    "gender-correction": "Gender Correction",
    "general-question": "General question",
    "generate-quotation": "Generate quotation",
    "get-a-quate": "Get a quote",
    "get-comfy-during-journey":
      " Get comfy during your journey! Whether you want a quiet flight or just wish to add a little legroom, the choice is entirely yours!",
    "get-free-quote": "Get free quote",
    "get-latest-news-offers": "Get the latest news and offers",
    "get-latest-status": "Get latest status",
    "get-more-fare-options": "Get more fare options",
    "get-order-prices": "Get Order Prices",
    "get-price": "Get Price",
    "given-name": "Given Name",
    "go-back": "Go Back",
    "go-back-to-dashboard": "Go Back to Dashboard",
    "go-beyond-your-imagination": "Go beyond your imagination",
    "go-to-home": "Go to home",
    "going-to": "Going to",
    "good-3.5+": "Good 3.5+",
    "grand-total-payable": "Grand Total Payable",
    group: "Group",
    "guest-info": "Guest Info",
    "guest-info-uppercase": "GUEST INFO",
    "guest-name": "Guest Name",
    "guest-per-room": "Guest (Per Room)",
    "guest-rating": "Guest Rating",
    "guest-rating-our-picks": "Guest rating + our picks",
    "guest-total": "Guest { cabinPriceIndex } Total",
    guests: "guests",
    "guests-uppercase": "GUESTS",
    "handpicked-tour": "Handpicked Tour",
    "held-orders": "Held Orders",
    "held-reservations": "Held reservations",
    hi: "Hi",
    "high-to-low": "High To Low",
    "holder-details": "Holder Details",
    "holder-info": "Holder Info",
    holiday: "Holiday",
    "holiday-customise": "Holiday Customise",
    "holiday-home": "Holiday Home",
    home: "home",
    "hong-kong-fun": "Hong Kong Fun",
    "hostel-backpacker": "Hostel/Backpacker",
    "hot-deals": "HOT DEALS",
    hotel: "Hotel",
    "hotel+flights": "HOTEL+FLIGHTS",
    "hotel-amenities": "Hotel amenities",
    "hotel-booking-overview": "Hotel Booking Overview",
    "hotel-detail": "Hotel Detail",
    "hotel-details": "Hotel Details",
    "hotel-home": "Hotel Home",
    "hotel-info": "Hotel Info",
    "hotel-item": "Hotel Item",
    "hotel-list": "Hotel List",
    "hotel-location": "Hotel location",
    "hotel-name": "Hotel Name",
    "hotel-num": "{hotelNum} hotel(s) found",
    "hotel-preference": "Hotel Preference",
    "hotel-price": "Hotel Price",
    "hotel-remarks": "Limit 250 characters",
    "hotel-reservation": "Hotel Reservation",
    "hotel-result": "Hotel Result",
    "hotel-search": "Hotel Search",
    "hotel-shopping": "Hotel Shopping",
    hotels: "Hotels",
    "how-it-working-content":
      "Working with an online travel agency (ota) like us not only helps you reach more travelers globally,<br />it allows you to engage them when they;re researching, planning and booking their next trip. <br />Discover how they work, what tools they offer and how to choose the right one for your business.",
    "how-it-works?": "HOW IT WORKS?",
    "how-many-guests-each-room": "How many guests will be in each room?",
    "how-many-rooms-need": "How many rooms do you need?",
    "how-many-tickets?": "How many tickets?",
    "i-agree": "I agree",
    "i-live-in": "I live in",
    "i-want-to-go": "I Want To Go",
    "important-info": "Important Info",
    "important-information": "Important information",
    in: "in",
    "in-process": "IN PROCESS",
    "in-room": "in room",
    inbound: "INBOUND",
    "inbox-menu": "Inbox Menu",
    include: "Include",
    "include-in-total-amount": "INCLUDE IN TOTAL AMOUNT",
    included: "Included",
    "includes-taxes-fees": "Includes taxes & fees",
    including: "Including",
    "including-taxes": "Including taxes",
    index: "Index",
    "indian-food": "Indian",
    infant: "Infant",
    "infant(s)": "Infant(s)",
    "infant-less-than": "Less than 2 yrs",
    "infant-price": "Infant Price",
    infants: "Infant(s)",
    info: "Info",
    "info-source": "Info Source: {supplierCode}",
    "info-source-id": "Info Source Id",
    "info-uppercase": "INFO",
    "instant-confirmation": "Instant confirmation",
    insurance: "Insurance",
    "insurance-home": "Insurance Home",
    "insurance-special-features-one": "Medical expenses up to HK$1,000,000",
    "insurance-special-features-three":
      "Covers accidental loss or damage to mobile phone",
    "insurance-special-features-two":
      "Covers trip cancellation with immediate effect",
    "insurance-total": "Insurance Total",
    "international-flights": "International Flights",
    "international-hotels": "International Hotels",
    internet: "Internet",
    "invalid-input": "Invalid input!",
    "invoice-number": "Invoice number:",
    "is-direct": "Is Direct",
    "issue-id": "Issue Id",
    "issue-ticket": "Issue Ticket",
    "issue-type-id": "Issue Type Id",
    "issued-uppercase": "ISSUED",
    "issuing-date": "Issuing date",
    "it-allows-you-to-engage-them-when-they;re-researching,-planning-and-booking-their-next-trip.":
      "it allows you to engage them when they;re researching, planning and booking their next trip.",
    itinerary: "Itinerary",
    "itinerary-info": "Itinerary Info",
    "itinerary-review": "Itinerary Review",
    job: "Job",
    "jobs-panel": "Jobs Panel",
    journey: "Journey",
    "journey-date": "Journey Date",
    "know-more": "Know More",
    "known-traveler-number": "Known Traveler Number",
    language: "Language",
    "language-code": "Language Code",
    "language-switch": "Language switch",
    "last-name": "Last name",
    "layover-in-airport": "layover in airport",
    "learn-html5": "Learn HTML5 in 10 simple and easy to follow steps",
    "leave-us-a-message": "Leave us a message",
    "leaving-from": "Leaving From",
    "leaving-to": "Leaving To",
    "less-than-two": "Less than 2 yrs",
    "lessons-hours": "10 lessons · 3 hours",
    "licenses-fees": "Licenses & Fees",
    "living-country": "Living Country",
    "load-more": "Load more",
    location: "Location",
    locations: "Locations",
    "log-out": "Log Out",
    "logged-in-to-stay-in-touch": "Logged in to stay in touch",
    login: "LOGIN",
    "login-failed": "Login failed. Please check your username/password again.",
    "login-success": "Login success. Welcome {userId} . ",
    logout: "Logout",
    "lorem-ipsum-is-simply-dummy-text":
      "Lorem Ipsum is simply dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    "lorem-ipsum-is-simply-dummy-text-of-the-printing-and-typesetting-industry.lorem-ipsum-has-been-the-industrys-standard-dummy-text-ever-since-the-1500s.":
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industrys standard dummy text ever since the 1500s.",
    "low-to-high": "Low to high",
    "lower-available-rate": "Lower available rate",
    "lowest-price": "Lowest Price",
    loyalty: "loyalty",
    "loyalty-accounts": "Loyalty accounts",
    "loyalty-info": "Loyalty Info",
    "loyalty-num": "Loyalty Number",
    "loyalty-passengers-summary":
      "Loyalty Passengers : {adult} Adult(s) , {child} Child(s) , {infant} Infant(s)",
    luggage: "luggage",
    lunch: "Lunch",
    "lunch-included": "Lunch Included",
    "mail-us": "Mail to our support team",
    make: "Make",
    male: "Male",
    "mall-road,-shimla,-himachal-pradesh,-176077":
      "Mall Road, Shimla, Himachal Pradesh, 176077",
    "manage-ancillary-service": "Manage Ancillary Service",
    "manage-seat-selection": "Manage Seat Selection",
    "manage-this-order": "Manage this order",
    "manage-this-reservation": "Manage this reservation",
    "manage-your-account": "Manage Your Account",
    marketing: "marketing",
    "marketing-carrier": "Marketing carrier",
    "max-num": "A maximum of {num} people can be selected",
    "max-price": "Max Price",
    "max-stay": "MAX STAY",
    "maximum-price": "Input maximum price",
    meal: "Meal",
    "meal-plans-available": "Meal plans available",
    meals: "Meals",
    "measurement-infos": "Measurement Infos",
    "meet-your-driver":
      " Step-by-step instructions to meet your driver will be sent after your booking is confirmed.",
    "metro-code": "Metro Code",
    "min-price": "Min Price",
    "minimum-driving-age-years":
      "Minimum driving age : {minimumDriverAge}years",
    "minimum-order": "Minimum Order",
    "minimum-price": "Input minimum price",
    minor: "Minor",
    minutes: "minutes",
    miss: "Miss.",
    mister: "Mister.",
    mode: "Mode",
    modify: "Modify",
    "modify-flight": "Modify Flight",
    "modify-search": "MODIFY SEARCH",
    "modify-success": " Modify success !",
    "more-details": "More details",
    "more-info": "More Info",
    "more-options": "More options",
    "more-photos": "More Photos",
    "morning-am": "Morning (5:00am - 11:59am)",
    "morning-filter-time": "Morning (00:00-11:59)",
    mr: "Mr.",
    mrs: "Mrs.",
    ms: "Ms.",
    "multi-city": "Multi city",
    "multi-trip": "Multi Trip",
    "multi-way": "Multi way",
    "must-agree-service-terms": "You must agree to the service terms!",
    "must-check-pick-up-time": "Must check pick-up time",
    "must-check-pick-up-time.": "Must check pick-up time.",
    mx: "Mx.",
    "my-choice": "My choice",
    "my-profile": "My Profile",
    "my-reservations": "My Reservations",
    "my-trips": "My Trips",
    name: "Name",
    "name-appear-as-passport": "Your name must appear as on your passport",
    "name-change": "Name change",
    "name-correction": "Name correction",
    "name-submitted-successfully":
      "{surname}/{givenName}, your order was submitted successfully!",
    "name-title": "Name Title",
    nationality: "Nationality",
    "necessary-info": "Necessary info",
    "need-help": "Need any help?",
    "need-help-content":
      "Need Help? Call us or drop a message. Our agents will be in touch shortly.",
    "need-help?": "Need Help?",
    "need-help?-call-us-or-drop-a-message.-our-agents-will-be-in-touch-shortly.":
      "Need Help? Call us or drop a message. Our agents will be in touch shortly.",
    "needs-review": "Needs review",
    "needs-review-reservations": "Needs review Reservations",
    neighborhood: "Neighborhood",
    "new-details": "New details",
    "new-fare-quote": "New Fare Quote",
    "new-flight-details": "New Flight Details",
    "new-password": "New Password",
    "new-ticket-fee": "New ticket fee",
    next: "Next",
    "next-flight": "Next Flight",
    "next-passenger": "Next Passenger",
    "next-step": "Next Step",
    night: "Night",
    "night-number": "{number} NIGHT",
    "night-number-high-to-low": "Number of nights high to low",
    "night-number-low-to-high": "Number of nights low to high",
    "night-stay": "night(s) stay",
    nights: "nights",
    no: "No",
    "no-additional-assistance": "No additional assistance",
    "no-additional-baggage": "No additional baggage",
    "no-additional-meal": "No additional meal",
    "no-assistance-option-in-the-segment":
      "No assistance option in the segment",
    "no-available-car-transfer!": "No available car transfer!",
    "no-available-cruise": "No available cruise",
    "no-available-flights": "No available flights",
    "no-available-room-please-select-other-available-hotels":
      "No available room. Please select other available hotels.",
    "no-baggage-option-in-the-segment": "No baggage option in the segment",
    "no-data": "No data",
    "no-direct": "No Direct",
    "no-matched-booking": "No matched booking",
    "no-matched-location": "! No matched options {location}",
    "no-room-type-name": "No Room Type Name",
    "no-seat-segment": "No seat could be selected in the segment",
    "no-seats-available": "No seats available",
    "no-selection": "No selection",
    "no-services": "No services",
    "no-similar-hotel-": "No similar hotel ",
    "no-transit": "0 Transit/Direct",
    non: "Non",
    "non-baggage": "Non-baggage",
    "non-cancellable": "Non-cancellable",
    "non-refundable": "Non-refundable",
    "non-stop": "Non-stop",
    none: "None",
    "not-issued": "NOT_ISSUED",
    "not-need-to-check-pick-up-time": "Not need to check pick-up time",
    "not-need-to-check-pick-up-time.": "Not need to check pick-up time.",
    notes: "Notes",
    null: "Null",
    "num-flight": "  {num} flights found",
    "num-hotel": "  {length} hotel(s) found",
    "num-night-stay": "{num}-night stay",
    "num-passenger": "{passengersNum} Passenger",
    "num-trip-advisor": "{num}/5 Trip Advisor",
    "number-of-connections": "Number of connections",
    "number-of-entries": "NUMBER OF ENTRIES",
    "number-of-nights": "Number of nights",
    "number-of-stops": "Number of stops",
    "number-stops": "{stopNumber} Stop(s)",
    "of-guests": "OF GUESTS",
    "of-state-rooms": "OF STATEROOMS",
    "offer-bg": "Baggage",
    "offer-ie": "Entertainment",
    "offer-md": "Medical Service -- wheel chair / oxygen etc",
    "offer-ml": "Meal",
    "offer-sa": "Seat",
    ok: "OK",
    "old-password": "Old Password",
    "one-way": "One Way",
    only: "Only",
    oops: "Oops...",
    "operated-by": "Operated by",
    operating: "Operating",
    "operating-airline": "Operating Airline",
    operation: "Operation",
    "operation-uppercase": "OPERATION",
    optional: "Optional",
    "optional-additional-services": "Optional additional services",
    "optional-assistance": "Optional assistance",
    "optional-baggage-service": "Optional baggage service",
    "optional-seat-service": "Optional seat service",
    or: "or",
    order: "Order",
    "order-change-departure-date":
      "Departure Date(The local time shall prevail)",
    "order-change-departure-time":
      "Departure time(The local time shall prevail)",
    "order-details": "Order Details",
    "order-id": "Order Id",
    "order-list": "Order List",
    "order-reprice": "Order Reprice",
    "order-status": "Order Status",
    "order-type": "Order Type",
    origin: "Origin",
    other: "Other",
    "other-requests": "Other requests",
    others: "Others",
    "our-partners": "Our partners",
    "our-services": "Our Services",
    outbound: "OUTBOUND",
    overview: "Overview",
    owner: "Owner",
    package: "Package",
    "package-flight-hotel": "Package(Flight + Hotel)",
    "package-home": "Package Home",
    "package-search": "Package Search",
    "package-total-payable": "Package Total Payable",
    partners: "Partners",
    "party-from": "Party From",
    "party-relationship-type": "Party Relationship Type",
    "party-to": "Party To",
    pass: "Pass",
    "pass-option": "Pass Option",
    "pass-review": "Pass Review",
    passenger: "Passenger",
    "passenger-choice": "Passenger choice",
    "passenger-class": "Passenger, Class",
    "passenger-fare": "Passenger Fare",
    "passenger-fares": "Passenger Fares",
    "passenger-frequent-flyer-modify-notice":
      "Fill in the passenger Frequent Flyer number to be modified and click Confirm",
    "passenger-info": "Passenger Info",
    "passenger-information": "Passenger Information",
    "passenger-name": "Passenger Name",
    "passenger-name-modify-notice":
      "Fill in the passenger name to be modified and click Confirm",
    "passenger-passport-modify-notice":
      "Fill in the passenger Passport number to be modified and click Confirm",
    "passenger-room-class": "Passenger,Room,Class",
    "passenger-taxes": "Passenger Taxes",
    "passenger-type": "Passenger Type",
    "passenger-type-codes": "Passenger Type Codes(PTCs)",
    "passenger-types": "Passenger types",
    "passenger-will-departure-from-to":
      "Passengers will depart from {from} {later} later , and arrive at {to} {later} later",
    passengers: "Passengers",
    "passengers-information": "Passengers Information",
    "passengers-room-summary":
      "{passengersNum} passengers ,{roomCount} room(s) ,{cabinName}",
    "passengers-summary":
      "{adult} Adult(s) , {child} Child(s) , {infant} Infant(s) ",
    "passengers-summary-and-cabin":
      " {passengersNum} Passenger(s),{cabinName} ",
    "passport-correction": "Passport Correction",
    "passport-country": "Passport Country",
    "passport-expire-date": "Passport Expire Date",
    "passport-expiry-date":
      "Passport expiry date is within 6 months of departure or return",
    "passport-expiry-time": "Passport Expiry Time",
    "passport-information": "Passport Information",
    "passport-information-correction": "Passport Information Correction",
    "passport-number": "Passport Number",
    "passport-requirements": "PASSPORT REQUIREMENTS",
    password: "Password",
    "password-reminder": "Password reminder",
    "password-valid-content":
      "Password should contain at lease a uppercase, a lowercase,must contain '{'@'}$%='    a special characters and no less than 8 characters.",
    "pax-num": "Pax Num",
    "pax-type": "Pax Type",
    "pay-now": "Pay Now",
    payment: "Payment",
    "payment-details": "Payment details",
    "payment-method": "Payment Method",
    "payment-now": "Payment Now",
    "payment-uppercase": "PAYMENT",
    penalty: "penalty",
    "penalty-amount-notification":
      "If you cancel the flight now, You will be charged  {currency} {amount}，Do you confirm the cancellation ?",
    people: "people",
    per: "Per",
    "per-adult": "Per Adult",
    "per-application": "Per Application",
    "per-day": "PER DAY",
    "per-entry": "Per Entry",
    "per-night": "PER NIGHT",
    "per-pax": "PER PAX",
    "per-trip": "Per Trip",
    "per-unit": "per unit",
    person: "Person",
    "personal-details": "Personal details",
    "personal-info": "Personal Information",
    "personal-information": "Personal Information",
    "phone-number": "Phone number",
    "phone-number-correction": "Phone Number Correction",
    "pick-date-time": "Pick Date Time",
    "pick-return-location": "Pick up & Return location",
    "pick-some": "Pick Some",
    "pick-up": "Pick up",
    "pick-up-date": "Pick Up Date",
    "pick-up-date-time": "Pick Up Date Time",
    "pick-up-location": "Pick Up Location",
    "pick-up-remarks": "Pick Up Remarks",
    "pick-up-time": "Pick up time",
    "pick-your-add-ons": "Pick your add-ons",
    pickup: "Pickup",
    "pickup-and-return": "Pick up & Return",
    "pickup-and-return-time": "Pick up location & Time",
    "pickup-location": "Pickup Location",
    "picnic-hotel-bangkok": "Picnic Hotel Bangkok",
    "please-check-first": "Please check first!",
    "please-confirm the password": "Please confirm the password",
    "please-confirm-your-password": "Please confirm your  password",
    "please-contact-email": "Please contact email",
    "please-enter": "Please Enter",
    "please-enter-a-username": "Please enter a username",
    "please-enter-a-valid-email": "Please enter a valid email address",
    "please-enter-a-valid-firstname": "Please enter a valid firstname",
    "please-enter-a-valid-lastname": "Please enter a valid lastname",
    "please-enter-a-valid-password": "Please enter a valid password",
    "please-enter-a-valid-phone-number": "Please enter a valid phone number",
    "please-enter-a-valid-username": "Please enter a valid username",
    "please-enter-contact-number": "Please enter Contact Number.",
    "please-enter-email-address": "Please Enter Email Address",
    "please-enter-first-name": "Please Enter First Name",
    "please-enter-hotel-location": "Please enter hotel location",
    "please-enter-last-name": "Please Enter Last Name",
    "please-enter-phone-number": "Please enter phone number",
    "please-enter-price-range": "Please enter valid price range",
    "please-enter-three-uppercase-code":
      "Please enter three different uppercase code",
    "please-enter-your-arrival-point": "Please enter your arrival point",
    "please-enter-your-departure-point": "Please enter your departure point",
    "please-enter-your-new-password": "Please enter your new password",
    "please-enter-your-password": "Please enter your password",
    "please-enter-your-username": "Please enter your username",
    "please-input-airlines": "Please input airlines",
    "please-input-approvers": "Please input approvers",
    "please-input-codes": "Please Input codes",
    "please-provide-a-password": "Please provide a password",
    "please-select": "Please select",
    "please-select-air-equip-type": "Please select air equip type",
    "please-select-area-code": "Please select area code",
    "please-select-cabin-class": "Please select cabin class",
    "please-select-channel": "Please select channel",
    "please-select-different-time": "Please select a different time",
    "please-select-duration": "Please select duration",
    "please-select-required-attributes": "Please select required attributes",
    "please-select-sailing-dates": "Please select sailing dates",
    "please-select-vendor": "Please select vendor",
    "please-select-your-payment-method": "Please select your payment method",
    "please-select-your-trip":
      "Please select your <span class='text-primary'>{tripType}</span> trip",
    policies: "Policies",
    policy: "policy",
    pool: "Pool",
    "popular-things-to-do": "Popular Things to do",
    ports: "Port(s)",
    "ports-of-call": "Ports of call",
    position: "position",
    "postal-code": "Postal code",
    power: "Power",
    preferred: "Preferred",
    "premium-economy": "Premium Economy",
    prev: "Prev",
    "prev-passenger": "Prev Passenger",
    previous: "Previous",
    "previous-details": "Previous details",
    "previous-flight-details": "Previous Flight Details",
    price: "Price",
    "price-breakdown": "Price Breakdown",
    "price-detail": "PRICE DETAIL",
    "price-details": "Price Details",
    "price-expiration-date-time": "Price Expiration Date Time",
    "price-high-to-low": "Price:high to low",
    "price-info": "Price Info",
    "price-is-out-of-date": "Your price is out of date and needs to be reprice",
    "price-low-to-high": "Price:low to high",
    "price-per-applicant": "Price per applicant",
    "price-per-night": "Price Per Night",
    "price-per-room": "Price Per Room",
    "price-policy": "Price Policy",
    "price-quote-number": "Price Quote Number",
    "price-range": "Price Range",
    "price-starts-from": "{currency} {amount}Price starts from",
    "price-summary": "Price Summary",
    "price-total": "{ currency } {price} total",
    "price-uppercase": "PRICE",
    "price/night": "Price/Night",
    "print--voucher": "PRINT  VOUCHER",
    "print-e-tickets": "Print E-tickets",
    "print-invoice": "Print Invoice",
    "print-voucher": "Print Voucher",
    "privacy-policy": "Privacy Policy",
    "processing-speed": "Processing Speed (not selected yet)",
    "processing-time-fee": "Processing Time & Fee",
    "product-name": "Product Name",
    "product-type": "Product Type",
    prof: "Prof.",
    profile: "Profile",
    "promotion-code": "Promotion Code",
    properties: "properties",
    "properties-num": "{num} properties",
    "property-type": "Property Type",
    "protection-plans": "Protection plans",
    protections: "Protections",
    "protections-extras": "Protections & Extras",
    "provide-your-account":
      "Please provide your account’s email or username and we will send you your password.",
    provider: "Provider",
    "purchase-additional-service": "Purchase Additional Service",
    "purchase-ancillary-service": "Purchase ancillary service",
    "please-enter-passport": "Please enter passport",
    "please-enter-birth-date": "Please enter birth date",
    "please-enter-expire-date": "Please enter expire date",

    "question-description": "Question description",
    questions: "Questions",
    "quote-number": "Quote Number",
    "quote-type": "Quote Type",
    "quoted-fares": "Quoted  Fares",
    radius: "Radius",
    "radius-search": "Radius Search",
    rail: "Rail",
    "rail-home": "Rail Home",
    "rail-pass-review": "Rail Pass Review",
    "rail-passenger": "Rail Passenger",
    "rail-reservation": "Rail  Reservation",
    "rail-review": "Rail Review",
    "rail-search": "Rail Search",
    "rail-search-list": "Rail Search List",
    "rail-ticket-review": "Rail Ticket Review",
    "rate-comments": "Rate Comments",
    "rate-description": "Rate Description",
    rating: "Rating",
    "re-price": "Reprice",
    "reactive-account": "Reactive Account",
    "reactive-your-account": "Reactive your account",
    "read-and-accept-conditions": "I read and accept all Terms and conditions",
    "read-more": "READ MORE",
    "reasons-to-trust-us": "REASONS TO TRUST US",
    "receipt-status": "Receipt Status",
    received: "Received",
    "recent-booking": "Recent Booking",
    recommended: "Recommended",
    "record-locator": "Record Locator",
    "redress-number": "Redress Number",
    "redress-number-correction": "Redress Number Correction",
    refundable: "Refundable",
    region: "Region",
    register: "REGISTER",
    "register-account": "Register account",
    "register-me": "REGISTER ME",
    "register-now": "Register now",
    "register-your-account": "Register your account",
    "regular-question": "Regular question",
    reject: "Reject",
    remark: "Remark",
    remarks: "Remarks",
    "remember-me": "Remember Me",
    remove: "Remove",
    "remove-last-flight": "Remove Last Flight",
    "remove-person": "Remove Person",
    "remove-room": "Remove room",
    "rental-days": "Rental Days",
    "report-it": "Report it",
    reports: "Reports",
    "request-cancellation": "Request cancellation",
    "request-change": "Request change",
    "requested-ticketing-date": "Requested Ticketing Date",
    requester: "Requester",
    "requester-name": "Requester Name",
    required: "Required",
    "required-attributes": "Required Attributes",
    "required-for-travel": "REQUIRED FOR TRAVEL",
    reselect: "Reselect",
    reservation: "Reservation",
    "reservation-have-been-changed":
      "  Some of your reservations have been changed by the airline and require  your action. Now you can",
    "reservation-id": "Reservation Id",
    "reservation-list": "Reservation List",
    "reservation-overview": "Reservation Overview",
    "reservation-result": "Reservation Result",
    "reservation-status": "Reservation status",
    "reservation-summary": "Reservation Summary",
    "reservation-total": "Reservation Total",
    "reservation-total-price": "Reservation Total Price",
    reservations: "Reservations",
    "reservations-found": "reservations found",
    "reservations-needing-review": "Reservations needing review",
    "reservations-notice": "Reservations Notice",
    reserve: "Reserve",
    "reserve-now": "Reserve Now",
    reset: "Reset",
    "reset-password": "Reset Password",
    "reset-password-success":
      "We sent a reset-password link to your email. You could change the password by the link.",
    "reset-your-password": "Reset your password",
    "reshop-uppercase": "RESHOP",
    "residency-information": "Residency Information",
    "resort-fee": "Resort Fee",
    "resort-fee-notice": "Exclusive in total, customer has to pay at hotel",
    restaurant: "Restaurant",
    result: "Result(s)",
    "result(s)-found-matching-your-search":
      "Result(s) Found Matching Your Search",
    "results-num": "{num} results found",
    return: "return",
    "return-date": "Return date",
    "return-date-time": "Return Date Time",
    "return-location": "Return Location",
    "return-on": "Return On",
    "return-time": "Return time",
    review: "review",
    "review-and-pay": "Review and pay",
    "review-pay": "REVIEW & PAY",
    "review-room": "Review room",
    "review-things-to-do": "Review Things To Do",
    "review-uppercase": "REVIEW",
    "role-from": "Role From",
    "role-to": "Role To",
    "role-type": "Role Type",
    "romantic-france": "Romantic France",
    "romantic-paris": "Romantic Paris",
    room: "Room",
    "room(s)": "Room(s)",
    "room-amenities": "Room amenities",
    "room-amount": "Room Amount",
    "room-index": "Room - Index",
    "room-info": "Room Info",
    "room-name": "Room Name",
    "room-name-index": "Room {index} { roomTypeName }",
    "room-price": "Room Price",
    "room-price-total": "Room Price Total",
    "room-prices-details": "Room Prices Details",
    "room-quantity": "Room quantity",
    "room-type": "Room Type",
    "room-type-uppercase": "ROOM TYPE",
    "room-types": "Room Types",
    "room-types-rate-comments": "Rate Comments - { roomTypes } -  {roomIndex}",
    "room-uppercase": "ROOM",
    rooms: "Rooms",
    "rooms-can-accommodate": "The rooms can accommodate up to 4 guests",
    round: "Round",
    "round-trip": "Round Trip",
    "sailing-dates": "Sailing Dates",
    "sailings-uppercase": "SAILINGS",
    sales: "Sales",
    "same-return-location": "Same return location",
    save: "Save",
    "save-changes": "Save Changes",
    "save-more": "SAVE MORE",
    "sea-food": "Sea food",
    search: "Search",
    "search-activities": "Search Activities",
    "search-available-flight": "Search available flights",
    "search-booking": "Search Booking",
    "search-by-destination": "Search by destination",
    "search-car-transfer": "Search Car Transfer",
    "search-cruise": "Search Cruise",
    "search-flights": "Search Flights",
    "search-for-an-activities": "Search for an activity",
    "search-for-the-best-rooms-available":
      "We’ll search for the best rooms available within the selected category. The rooms can accommodate up to 4 guests",
    "search-hotels": "Search Hotels",
    "search-order": "Search Order",
    "search-orders": "Search Orders",
    "search-package": "Search Package",
    "search-perfect-vehicle": "Search Perfect Vehicle",
    "search-result": "Search Result",
    "search-tour": "Search Tour",
    "search-vehicles": "Search Vehicles",
    "searching-result": "Searching Result",
    seat: "Seat",
    "seat-details": "Seat Details",
    "seat-only-uppercase": "SEAT ONLY",
    "seat-price": "Seat price",
    "seat-selected": "seat selected",
    "seat-selection": "Seat selection",
    "seat-unavailable": "Seat Unavailable",
    seats: "Seats",
    "second-trip": "Second Trip",
    "second-trip-description": "Second Trip Description",
    "see-all-properties": "See all properties",
    "see-descriptions": "See descriptions",
    "see-details": "See details",
    "see-fare-basis": "See Fare basis",
    "see-fare-details": " See fare details",
    "see-more-description": "See more description",
    "see-rate-comments": "See Rate  Comments",
    "see-remark": "See Remark",
    segment: "Segment",
    "segment-info": "Segment Info",
    "segmentation-group": "Segmentation Group",
    segments: "Segments",
    select: "Select",
    "select-a-room": "Select a room",
    "select-a-validity": "Please select a validity !",
    "select-check-in-baggage": "Select check in baggage",
    "select-continue": "SELECT & CONTINUE",
    "select-date": "Select Date",
    "select-details": "Select Details",
    "select-dining-preference": "Select your Dining Preference",
    "select-fares": "Select Fares",
    "select-flight-from-to": "Select flight from {from} to {to} on {date}",
    "select-flight-you-want-change":
      "Please select the flight you want to modify !",
    "select-meal": "Select meal",
    "select-one": "Please select one",
    "select-options": "Select Options",
    "select-passengers": "Select Passengers",
    "select-segments": "Select Segments",
    "select-status": "Select Status",
    "select-the-child-age": "Please select the child's age!",
    "select-the-special-help-you-need": "Select the special help you need",
    "select-to-book": "Select to book",
    "select-trip": "Select Trip",
    "select-weight-of-baggage": "Select the weight of your checked baggage",
    "select-your-extras": "Select your extras",
    "select-your-plan": "Select your plan",
    "select-your-room": "Select your room",
    "select-your-room-category": "Select your room category",
    "select-your-seat": "Select Your Seat",
    "select-your-seats": " Select your seats",
    "select-your-transmitting-system": "Select your transmitting system",
    "select-your-type-of-inside": "Select your type of {type} Inside",
    selected: "Selected",
    "selected-departure-trip": "SELECTED DEPARTURE TRIP",
    "selected-equips": "Selected Equips",
    "selected-insurances": "Selected Insurances",
    selection: "Selection",
    "selling-price": "Selling price",
    send: "Send",
    "send-email": "Send Email",
    "send-links": "Send links",
    "send-mail": "Send Mail",
    "send-message": "Send message",
    senior: "Senior",
    "series-code": "Series Code",
    "service-name": "Service Name",
    "service-type": "Service name",
    services: "Services",
    "services-summary": "Services Summary",
    "services-total": "Services Total",
    session: "Session",
    ship: "Ship",
    "ship-name": "Ship Name",
    "show-details": "Show details",
    "show-less": "Show less",
    "show-more": "Show More",
    "show-more-options": "Show more options",
    "show-more-room-rate": "Show More Room Rate",
    "shuttle-info": "Shuttle Info",
    "side-overlay-content": "Side Overlay content..",
    "sign-in": "Sign in",
    "sign-in-now": "Sign in now",
    "sign-in-your-account": "Sign In your account",
    "sign-out": "sign out",
    "sign-up": "SIGN UP",
    "similar-hotel": "Similar Hotel",
    "single-charge": "Single charge",
    "single-trip-travel-plan": "Single Trip Travel Plan",
    "sky-high-dubai": "Sky High Dubai",
    "sleep-num": "Up to {num} person",
    "sleep-num-range": " {min} - {max} person",
    sleeps: "Sleeps",
    "some-fare-options-for-you": "Here are some  fare options for you!",
    "sort-by": "Sort by",
    source: "Source",
    "source-type": "Source Type : {sourceType}",
    sources: "Source(s)",
    spa: "Spa",
    "special-features": "Special Features",
    "special-request": "Special Request",
    "special-request-content": "You Can Write You Special Request In Here",
    "special-requests": "Special Requests",
    "special-requests-optional": "Special requests (Optional)",
    "standard-days": "Standard - {num} days",
    "standard-search": "Standard search",
    star: "Star",
    "star-rating": "Star Rating",
    start: "Start",
    "start-at": "Start at",
    "start-date": "Start Date",
    "start-now": "Start Now",
    "start-to-end": "Start to End",
    "starting-from": "STARTING FROM",
    "stateroom-amenities": "Stateroom Amenities",
    status: "Status",
    "status-uppercase": "STATUS",
    "stay-in-touch": "Stay in touch",
    stock: "Stock",
    stop: "Stop",
    stopover: "Stopover",
    "stopover-airport": "Stopover Airport",
    "stopover-in": "Stopover #{ arrivalDateTime } in  #{ arrivalAirportName }",
    stops: "Stops",
    "store-fare": "Store Fare",
    "stored-fare": "Stored Fare",
    "stored-fares": "Stored Fares",
    "street-#156-burbank,-studio-city-hollywood,-california-usa":
      "Street #156 Burbank, Studio City Hollywood, California USA",
    student: "Student",
    "student-price": "Student Price",
    "sub-total": "Sub Total",
    submit: "Submit",
    "submit-order": "Submit  Order",
    "submit-ticket-order": "Submit Ticket Order",
    subscribe: "SUBSCRIBE",
    "subscribe-our-newsletter": "Subscribe to our newsletter",
    "subscribe-to-our-newsletter": "SUBSCRIBE TO OUR NEWSLETTER",
    success: "success",
    "successful-cancel": "Successful cancel",
    "successful-ticketing":
      "Ticketing is processing, status will update in few minutes.",
    "successfully-cancelled": "Successfully cancelled!",
    "suggestions-for-you": "Suggestions For You",
    suitcase: "Suitcase",
    "sum-insured": "Sum Insured",
    summary: "Summary",
    "summary-uppercase": "SUMMARY",
    supplier: "Supplier",
    "supplier-fare-option": "Supplier fare options",
    "supplier-fare-option-num": "{num} supplier fare options",
    "supplier-max-waiting-time": "Supplier Max Waiting Time",
    "supplier-terms-and-conditions": "Supplier Terms and Conditions",
    "supplier-terms-and-conditions-content":
      'These General Terms and Conditions for Booking (the "General Terms and Conditions"), along with the informatin set out in your booking confirmation email (the "Booking Confimation") set out the terms on which the company agrees to make available the Services to you through the Website (see defined terms below)',
    "supplier-terms-conditions": "Supplier Terms and Conditions",
    suppliers: "Supplier(s)",
    support: "Support",
    "supreme-insurance": "Supreme",
    "sure-about-the-modification": "Are you sure about the modification ?",
    "sure-cancel-booking": "Are you sure to cancel reservation #{ bookingId }",
    "sure-export-booking": "Are you sure to export booking #{ bookingId }",
    surname: "Surname",
    "synchronize-command": "Synchronize Command",
    "synchronized-success": "Synchronized Success",
    system: "System",
    tax: "Tax",
    "tax-and-fees-description":
      "“This charge includes estimated amounts the travel service provider (i.e. hotel, car rental company) pays for their taxes, and/or taxes that we pay, to taxing authorities on your booking (including but not limited to sales, occupancy, and value added tax). This amount may also include any amounts charged to us for resort fees, cleaning fees, and other fees and/or a fee we, the hotel supplier and/or the website you booked on, retain as part of the compensation for our and/or their services, which varies based on factors such as location, the amount, and how you booked. For more details, please see the Terms and Conditions.” ",
    taxes: "Taxes",
    "taxes-amount": "Taxes Amount",
    "taxes-and-fees": "Taxes And Fees",
    "taxes-fees": "Taxes & Fees",
    "taxes-fees-and-port-expenses": "Taxes, Fees, and Port Expenses",
    "taxes-fees-included": "Taxes fees included",
    "taxes-fees-port-expenses": "Taxes, Fees, and Port Expenses",
    "taxes-include-total": "Taxes(Include in total amount)",
    "taxes-name": "Taxes Name",
    "taxes-total": "Taxes Total",
    "taxes-uppercase": "TAXES",
    "telephone-number": "Telephone Number",
    television: "Television",
    terminal: "Terminal",
    "terms-and-conditions": "Terms and conditions",
    "terms-of-use": "Terms Of Use",
    "thank-you": "THANK YOU",
    the: "The",
    "things-to-do": "Things to do",
    "things-to-do-date": "Things to do Date",
    "third-party-liability": "Third Party Liability",
    "ticket(s)": "Ticket(s)",
    "ticket(s)-and-price": "Ticket(s) And Price",
    "ticket-conditions": "Ticket Conditions",
    "ticket-details": "Ticket details",
    "ticket-info": "Ticket Info",
    "ticket-issue-failed": "Ticket issue failed!",
    "ticket-not-down": "Ticket Not Down",
    "ticket-number": "Ticket Number",
    "ticket-numbers": "Ticket Numbers",
    "ticket-of-adult(s)": "Ticket Of Adult(s)",
    "ticket-of-child(ren)": "Ticket Of Child(ren)",
    "ticket-orders": "Ticket Orders",
    "ticket-review": "Ticket Review",
    "ticket-segments": "Ticket segments",
    "ticket-service": "Ticket service",
    "ticket-status": "Ticket Status",
    "ticket-time-limit": "Ticket Time Limit",
    "ticketed-uppercase": "TICKETED",
    ticketing: "Ticketing",
    "ticketing-confirmed": "Your ticketing #{ ticketId } is confirmed",
    "ticketing-date": "Ticketing date",
    "ticketing-success": "Ticketing Success",
    "ticketing-time-limit": "Ticketing Time Limit",
    tickets: "Tickets",
    time: "Time",
    "time-after-departure": "Time after departure(h)",
    "time-before-departure": "Time before departure(h)",
    timeErr: "The arrival time cannot be earlier than the departure time",
    title: "Title",
    to: "to",
    "today-price": "Today's Price",
    "top-destination": "TOP DESTINATION",
    total: "Total",
    "total-after-commission": "Total after commission",
    "total-amount": "Total Amount",
    "total-due": "Total Due",
    "total-fare": "Total Fare",
    "total-include-amount": "Total {currency} {amount}",
    "total-lower-case": "total",
    "total-pay": "Total Pay",
    "total-payment-to-be-made": "Total Payment to be made",
    "total-price-range": "Total Price Range",
    "total-taxes": "Total Taxes",
    "total-uppercase": "TOTAL",
    "tour-activity": "Activities",
    "tour-code": "Tour Code",
    "tour-group-name": "Tour Group Name",
    "tour-info": "Tour Info",
    "tour-name": "Tour Name",
    tourism: "Tourism",
    tours: "Tours",
    "tours-and-activities": "Tours And Activities",
    "train-arrival-time": "Train Arrival Time",
    "train-departure-time": "Train Departure Time",
    "train-number": "Train Number",
    "transit(s)": "Transit(s)",
    "transit-num": "{num} Transit(s)",
    transmission: "Transmission",
    "travel-alerts": "Travel Alerts",
    "travel-and-baggage": "Travel And Baggage",
    "travel-approval": "Travel Approval",
    "travel-document": "Visa",
    "travel-document-apply": "Travel Document Apply",
    "travel-document-book": "Travel Document Book",
    "travel-document-home": "Travel Document Home",
    "travel-document-name": "Travel Document Name",
    "travel-document-option": "Travel Document Option",
    "travel-document-reservation": "Travel Document Reservation",
    "travel-policies": "Travel Policies",
    "travel-room-num": "{travelersNumber} traveler(s) , {roomCount}  room(s)",
    "travel-rules": "Travel Rules",
    traveler: "Traveler",
    "traveler-day-within":
      "Travel days can be anytime within { within } of the first date of travel, as written on your pass.",
    "traveler-info": "Traveler Info",
    "traveler-name": "Traveler Name",
    "traveler-number-correction": "Traveler Number Correction",
    travelers: "Travelers",
    "traveling-for": "Traveling For",
    "travle-hassel-with-a-companies-recognized-for-professional-services":
      "Travle hassel with a companies recognized for professional services",
    trip: "Trip",
    "trip-advisor": "Trip Advisor",
    "trip-details": "TRIP DETAILS",
    "trip-from": "Trip from {from} to {to}",
    "trip-list": "Trip List",
    "trip-status": "Trip Status",
    "trip-total": "Trip Total",
    "trip-total-in": "Trip total in {key}",
    "trip-total-price": "Trip total price",
    "trip-way": "Trip Way",
    trips: "Trips",
    "trips-awaiting-approval": "Trips Awating Approval",
    "trips-found": "Trips found",
    "trips-list": "Trips List",
    "two-less-than": "2 - Less than 12 yrs",
    type: "Type",
    "type-of-vehicle": "What type of vehicle?",
    unacceptable: "Unacceptable",
    unavailable: "Unavailable",
    unconfirmed: "UNCONFIRMED",
    "under-17": "Under 17 years",
    "unknown-seat": "Unknown seat",
    unspecified: "Unspecified",
    unticketed: "UNTICKETED",
    "upcoming-trips": "Upcoming Trips",
    update: "Update",
    "update-time": "Update time",
    "useful-links": "Useful Links",
    "user-rating": "User Rating",
    username: "Username",
    "username-email": "Username/Email",
    "valid-for": "Valid for",
    "valid-from-date": "Valid from {date}",
    validity: "Validity",
    "vans-trucks": "Vans & Trucks",
    vehicle: "vehicle",
    "vehicle-name": "Vehicle Name",
    "vehicle-type": "Vehicle Type",
    vendor: "Vendor",
    version: "Version",
    "very-good-4.0+": "Very good 4.0+",
    view: "View",
    "view-affected-reservations": "View affected reservations",
    "view-all": "View all",
    "view-all-orders": "View all Orders",
    "view-all-reservations": "View all reservations",
    "view-dates": "VIEW {number} DATES",
    "view-details": "View Details",
    "view-fare-rules": "View Fare Rules",
    "view-fares": "View Fares",
    "view-price-breakdown": "View Price Breakdown",
    "view-price-summary": "View Price Summary",
    "view-segments": "View Segments",
    "view-terms": "View Terms",
    "view-your-reservation": "View your reservation",
    visiting: "Visiting",
    "visiting-uppercase": "VISITING",
    "void-ticket": "Void Ticket",
    warning: "Warning",
    "we-accept": "We Accept",
    "we-choose": "WE CHOOSE",
    welcome: "Welcome",
    "welcome-are-you-going": "Where are you going",
    "welcome-please-login": "Welcome, please login.",
    "when-is-your-arrival-date": "When is your arrival date?",
    "when-is-your-departure-date": "When is your departure date?",
    "where-am-i-from": "Where am I from?",
    "where-am-i-going": "Where am I Going",
    "where-are-you-from": "Where are you from?",
    "who-checking": "Who's checking in?",
    "who-is-checking-in": "Who is checking in?",
    "who-is-traveling": "Who Is Traveling?",
    "who-we": "Who we are",
    "why-choose-us?": "WHY CHOOSE US?",
    "wild-africa": "Wild Africa",
    "will-full-refund":
      "If you cancel the flight now, We will full refund to you. Do you confirm the cancellation ?",
    "will-incur-a": "will incur a",
    "will-not-be-refundable":
      "If you cancel the flight now, Will not be refundable to you. Do you confirm the cancellation ?",
    with: "With",
    "with-advanced-technology-and-superior-customer-services,":
      "With advanced technology and superior customer services,",
    "with-air-conditioning": "With Air Conditioning",
    "with-starr-travelead":
      "With Starr TraveLead, we can rest assured you a care free trip! Hassle-free purchase with one click!",
    without: "Without",
    "wonderful-4.5+": "Wonderful 4.5+",
    "wonderful-lodnon": "Wonderful Lodnon",
    "would-you-like-to-us-know": "Would you like to let us know about it?",
    "years-old": "Years Old",
    "years-old-over": "{age} years old and over",
    yes: "Yes",
    "you-have-not-selected-fare":
      "You have not selected fare! Please select at least one.",
    "you-have-not-selected-ticket":
      "You have not selected ticket! Please select at least one.",
    "you-need-to-pay": "You Need To Pay",
    "you-need-visa-travel":
      "You need a Visa to travel to {toCountryName} if you have a passport from {fromCountryName}",
    "you-pay": "You Pay",
    "you-will-cancel-reservation": "You will cancel reservation",
    "you-will-cancel-this-additional-service":
      "You will cancel this additional service",
    "you-will-cancel-this-ticket": "You will cancel this ticket",
    "you-will-confirm-the-trip-now":
      "You will confirm the trip now and pay later",
    "you-will-modify-name-now": "You will modify name now",
    "you-will-pay-for-services": "You will pay for services",
    "you-will-pay-for-trip":
      'You will pay <span class="h5 text-danger"\n                                  >{currency }\n                                  { totalAmount}</span\n                                >\n                                by credit card for the trip',
    "you-will-pay-now": "You will pay and confirm the trip now",
    "your-application": "Your Application",
    "your-booking-detail":
      "Your booking details has been sent to:{ emailAddress }",
    "your-booking-is-confirmed": "Your booking is confirmed",
    "your-choice": "Your choice",
    "your-choices": "Your choices",
    "your-info": "YOUR INFO",
    "your-journey": "Your journey",
    "your-passport-information": "Your Passport Information",
    "your-personal-details": "Your Personal Details",
    "your-residency-information": "Your Residency Information",
    "your-seat": "Your seat",
    "your-selection": "Your Selection",
    "your-trip-details": "Your Trip Details",
    "your-vehicle": "Your Vehicle",
    youth: "youth",
    "youth-child": "Youth/Child",
  },
  zh_CN: {
    childs: "儿童",
    "please-enter-passport": "请输入护照",
    "please-enter-birth-date": "请填写生日",
    "please-enter-expire-date": "请填写护照过期时间",
    "customize-your-trip": "定制你的旅行",
    "review-and-pay": "审查和支付",
    "select-a-room": "选择房间",

    "cancellations-hotel-with-penalty":
      " {date} 之后取消将会收到{currencyCode} {amount} 的罚款 ",
    "cancel-error": "取消失败!",
    "end-date": "截止日期",
    "please-select": "请选择",
    "currency-tag": "货币标记",
    "language-switch": "语言切换",
    "romantic-paris": "浪漫巴黎",
    "hong-kong-fun": "香港游玩",
    "sky-high-dubai": "迪拜之游",
    "check-your-email-for-details":
      "你可以查看你的电子邮件了解更多的细节。我们已经给你发了一封详细的邮件。",
    "please-enter-your-new-password": "请输入您的新密码",
    "please-confirm-your-password": "请输入您的密码",
    "indian-food": "印度餐",
    "chinese-food": "中餐",
    "sea-food": "海鲜餐",
    bar: "酒吧",
    restaurant: "餐厅",
    "booking-closed": "订单已关闭",
    "booking-canceled": "订单已取消",
    "booking-completed": "订单已完成",
    "booking-paid": "订单已支付",
    "booking-received": "订单已生成",
    null: "空",
    "enter-your-answer": "填写您的回答",
    "review-things-to-do": "门票预览",
    senior: "老年人",
    "in-room": "在房间",
    "see-rate-comments": "查看费率解释",
    payment: "支付",
    "review-room": "房间信息",
    "booking-info": "预定信息",
    "special-request-content": "你可以在这里写下你的特殊要求",
    "please-enter-email-address": "请输入邮箱",
    "need-help-content":
      "需要帮忙吗?给我们打电话或留言。我们的管理员很快就会联系你。",
    "your-residency-information": "您的居住信息",
    "please-enter-last-name": "请输入姓",
    "please-enter-first-name": "请输入名",
    "when-is-your-arrival-date": "到达时间?",
    "when-is-your-departure-date": "出发时间?",
    "of-state-rooms": "房间",
    "of-guests": "乘客",
    "category-uppercase": "舱型",
    "room-type-uppercase": "房型",
    "ROOM-uppercase": "房间",
    "guest-info-uppercase": "乘客信息",
    "review-uppercase": "预览",
    "payment-uppercase": "支付",
    "your-info": "您的信息",
    "trip-details": "旅行详情",
    "per-application": "每次申请",
    docked: "停靠港口",
    ports: "港口",
    "at-sea": "在",
    "no-available-cruise": "无邮轮结果",
    "book-travel-document": "预定签证",
    "sailing-dates": "航行时长",
    "book-cruise": "预定游轮",
    terminal: "站台",
    "operated-by": "运营商",
    "tour-activity": "门票",
    "please-select-area-code": "请选择区号",
    "password-valid-content":
      "密码至少包含一个大写字母、一个小写字母，必须包含  '{'@'}$%='  特殊字符且不少于8个字符。",
    "name-change": "姓名改变",
    "apply-name-change": "姓名改变",
    "apply-doc-change": "护照信息改变",
    "apply-contacts-change": "联系信息改变",
    "apply-ffp-change": "常飞旅客号码改变",
    "check-in-and-important-information": "到达以及重要信息",
    "travel-document-name": "旅行证件名称",
    "layover-in-airport": "在机场停留",
    edit: "编辑",
    "generate-quotation": "生成报价",
    "flight-booking-preview-reminder":
      "提醒:所选票价不支持带辅助的预订服务。选择的辅助服务将不会被添加到预订。购票后请购买辅助服务。",
    "enter-your-passport": "输入您的护照",
    "flight-detail": "机票详情",
    change: "修改",
    "morning-filter-time": "早上 (00:00-11:59)",
    "afternoon-filter-time": "下午 (12:00-17:59)",
    "evening-filter-time": "晚上 (18:00-23:59)",
    "transit-num": "{num} 站(s)",
    "no-transit": "0 站/直达",
    "reset-password-success":
      "我们给你的邮箱发了重置密码的链接。您可以通过链接更改密码。",
    "grand-total-payable": "应付总额",
    "select-flight-from-to": "选择从 {from} 到 {to} {date}的机票",
    "seat-only-uppercase": "提供座位",
    "more-options": "更多选项",
    "no-available-flights": "暂无合适的航班",
    "premium-economy": "高级经济舱",
    first: "头等舱",
    "enter-a-correct-email": "请输入一个正确的邮箱",
    send: "发送",
    "reactive-account": "激活账号",
    "forget-password": "忘记密码",
    "reset-password": "重设密码",
    "fast-links": "快速链接",
    "username-email": "用户名/邮箱",
    "how-it-working-content":
      "与像我们这样的在线旅行社(ota)合作不仅可以帮助您接触到全球更多的旅行者，<br />还可以让您在他们正在研究、计划和预订下一次旅行时与他们互动。<br />了解他们是如何工作的，他们提供什么工具，以及如何为您的企业选择正确的工具",
    "no-data": "暂无数据",
    "1-room-fits": "一个房间适合",
    "about-hotel-description": "酒店描述",
    ascending: "升序",
    "add-a-flight": "添加航程",
    address: "地址",
    adult: "成人",
    "adult(s)": "成人",
    "adult(s)-to": "成人到",
    "adult-fare": "成人价格",
    adults: "成人",
    age: "岁数",
    "air-reservation": "机票订单",
    "air-tickets": "机票",
    airline: "航空公司",
    "airline-record-locator": "航空公司记录编号",
    all: "全部",
    amenities: "便利设施",
    amenity: "便利设施",
    "amenity-of-hotel": "酒店设施",
    "amenities-of-hotel": "酒店设施",
    "ancillary-services": "附加服务",
    "area-code": "地区代码",
    arrival: "到达",
    "arrival-airport": "到达机场",
    "arrival-time": "到达时间",
    "arrival-time-in-destination": "目的地到达时间",
    "awesome-amsterdam": "Awesome Amsterdam",
    avg: "平均",
    "basic-description": "基本描述",
    "become-a-partner": "成为伙伴",
    "best-price": "最低价格",
    "beach-goa": "Beach Goa",
    "bed-type": "床型",
    before: "之前",
    blog: "BLOG",
    book: "预定",
    "book-now": "現在预定",
    "book-car-transfer": "预定车接",
    "book-flight-tickets": "预定机票",
    "book-holiday-package": "预定假期套票",
    "book-hotel-rooms": "预订房间",
    "book-tour-activity": "预订门票",
    "booking-create-date": "预定创建日期",
    "booking-engine": "Sesame Door Travel",
    "booking-date": "订单时间",
    "booking-id": "订单ID",
    "booking-number": "订单单号",
    "booking-overview": "订单预览",
    "booking-status": "订单状态",
    "booking-total": "订单总价",
    bookings: "订单",
    breakfast: "早餐",
    brand: "品牌",
    "cabin-class": "舱型",
    cancellable: "可取消",
    cancel: "取消",
    "cancellations-after": "之后取消",
    cancelled: "已取消",
    "cancel-policy": "取消策略",
    "car-brand": "汽车品牌",
    "car-details": "汽车详情",
    "car-rental-price": "租赁费用",
    "car-features": "特性",
    "car-info": "汽车信息",
    "change-password": "修改密码",
    "confirm-password": "确认密码",
    "contact-hotel": "联系酒店",
    "contact-number": "联系号码",
    "contract-remarks": "备注",
    "current-address": "当前地址",
    "car-name": "汽车名",
    "car-rental": "租车",
    "car-rental-info": "租赁信息",
    "car-transfer": "车接",
    "car-transfer-info-first-trip": "车接 - 第一程",
    "car-transfer-info-second-trip": "车接 - 第二程",
    "car-transfer-total-price": "总费用",
    cars: "车",
    "casino-los-vages": "Casino Los Vages",
    "check-date": "日期",
    "check-in": "入住",
    "check-in-date": "入住日期",
    "check-out": "确认",
    "check-out-date": "退房时间",
    child: "儿童",
    "child-age": "儿童岁数",
    "child(ren)": "儿童",
    "child-fare": "儿童价",
    "city-name": "城市名",
    class: "类型",
    "departure-date": "离开时间",
    "departure-time": "起飞时间",
    "departure-time-in-origin": "始飞地起飞时间",
    home: "首页",
    package: "机+酒",
    "package-total-payable": "机+酒总价",
    "return-date": "回程时间",
    "contact-agent": "联系代理商",
    "contact-person": "联系人",
    "contact-us": "联系我们",
    contact: "联系人",
    continue: "继续",
    country: "国家",
    "country-code": "国家代码",
    "coverage-info": "附加信息",
    cruise: "游轮",
    currencytag: "货币",
    "customer-max-waiting-time": "顾客最长等待时间",
    "date-of-birth": "出生日期",
    "date-range": "日期范围",
    day: "天",
    "day(s)": "天",
    days: "天数",
    "dedicated-support": "费用支持",
    descending: "降序",
    description: "描述",
    developers: "开发者",
    dinner: "晚餐",
    door: "门",
    doors: "门",
    "departing-on": "出发日期",
    departure: "离开",
    "departure-airport": "出发机场",
    "desired-pick-up-time": "需要接机时间",
    destination: "目的地",
    details: "详情",
    "domestic-flights": "国内航班",
    "domestic-hotels": "国内酒店",
    "driver-details": "司机详情",
    "driver-info": "司机信息",
    "driver-nation": "司机国籍",
    "driver-nationality": "司机国籍",
    "drop-off-date-time": "落车时间",
    "drop-off-location": "落车地点",
    duration: "时长",
    "edit-profile": "修改信息",
    "email-id": "邮件",
    email: "邮件",
    "email-address": "邮件地址",
    "extra-price": "额外费用",
    explore: "探索",
    extra: "附加",
    "extra-service": "附加服务",
    facilities: "设施",
    faq: "快速问答",
    fare: "費用",
    "fare-details": "費用詳情",
    "flight-arrival-time": "飞机到达时间",
    "flight-departure-time": "飞机起飞时间",
    "flight-preference": "航班偏好",
    "flight-details": "飞机详情",
    "flight-number": "飞机号",
    "flight-tickets": "机票",
    "flight-total-payable": "机票总价",
    flights: "飞机",
    "flying-time": "飞行时间",
    "food-preference": "食物偏好",
    "found-matching-your-search": "匹配您的搜索條件",
    "free-cancel": "免費取消",
    fees: "費用",
    "fees-and-taxes": "费用及税费",
    "fees-info": "費用信息",
    "fees-total": "费用总价",
    "first-name": "名",
    "first-trip": "第一行程",
    "first-trip-description": "第一行程描述",
    fit: "适合",
    "fit-description": "适合描述",
    "free-cancel-before": "免費取消",
    "free-cancel-date": "免費取消日期",
    "free-cancel-date(before)": "免費取消日期（前）",
    from: "从",
    "get-latest-status": "获取最新状态",
    "go-back": "返回",
    "guest-per-room": "客人/房间",
    "handpicked-tour": "精选之旅",
    hi: "你好",
    "high-to-low": "由高到低",
    "holder-details": "持有人详细信息",
    "holder-info": "持有人信息",
    hotel: "酒店",
    "hotel-booking-overview": "酒店订单机票",
    "hotel-info": "酒店信息",
    "hotel-name": "酒店名",
    hotels: "酒店",
    "hot-deals": "热门订单",
    "hotel+flights": "机+酒",
    "hotel-preference": "酒店偏好",
    "how-it-works?": "如何工作？",
    "how-many-tickets?": "多少张票？",
    "i-want-to-go": "我想要去",
    "important-info": "重要信息",
    infant: "婴儿",
    "infant(s)": "婴儿",
    "international-flights": "国际航班",
    "international-hotels": "国际酒店",
    "know-more": "了解更多",
    language: "语言",
    "last-name": "姓",
    "leaving-from": "离开",
    "leaving-to": "去往",
    included: "包含",
    "instant-confirmation": "及时确认",
    "insurance-total": "保险总费用",
    itinerary: "行程",
    location: "位置",
    login: "登入",
    "low-to-high": "由低到高",
    "lowest-price": "最便宜价格",
    lunch: "午餐",
    make: "制造",
    meals: "餐饮",
    mode: "模式",
    "more-info": "更多信息",
    "multi-trip": "多程",
    "must-check-pick-up-time": "必须确认接送时间",
    name: "名字",
    nights: "晚上",
    "manage-your-account": "管理账号",
    meal: "餐饮",
    "minimum-order": "最小订单数",
    modify: "修改",
    "modify-search": "重新搜索",
    "must-check-pick-up-time.": "必须确认接送时间",
    "need-help?": "需要帮忙？",
    none: "无",
    "non-cancellable": "不可取消",
    "non-refundable": "不可退款",
    "not-need-to-check-pick-up-time": "不需要确认接送时间",
    neighborhood: "邻居",
    "next-step": "下一步",
    "new-password": "新密码",
    night: "晚",
    "no-available-car-transfer!": "无可用车接服务！",
    "no-available-room-please-select-other-available-hotels":
      "无空余房间，请搜素其他酒店",
    "no-matched-booking": "无匹配订单",
    "no-similar-hotel-": "无类似酒店",
    "offer-ml": "餐饮",
    "offer-bg": "行李",
    "offer-sa": "座位",
    "offer-ie": "娱乐",
    "offer-md": "医疗服务 -- 轮椅 / 氧气等",
    "one-way": "单程",
    "our-services": "我们的服务",
    "old-password": "旧密码",
    others: "其他",
    overview: "预览",
    partners: "伙伴",
    password: "密码",
    penalty: "罚款",
    person: "人员",
    "personal-information": "个人信息",
    pickup: "接",
    "pickup-location": "接送地点",
    "please-enter-contact-number": "请输入电话号码",
    "price-range": "价格区间",
    "print-voucher": "打印机票",
    "privacy-policy": "隐私及政策",
    profile: "简介",
    passenger: "旅客",
    "passenger-information": "旅客信息",
    "passenger-name": "旅客名",
    passengers: "旅客",
    "passengers-information": "旅客信息",
    "passport-country": "护照国家",
    "passport-expire-date": "护照过期时间",
    "passport-number": "护照号码",
    "per-day": "每天",
    "per-night": "每晚",
    "per-pax": "每人",
    "per-trip": "每行程",
    "phone-number": "电话号码",
    "pick-date-time": "接送时间",
    "pick-up-date": "接送日期",
    "pick-up-date-time": "接送日期时间",
    "pick-up-location": "接送地点",
    "pick-up-remarks": "接送备注",
    "picnic-hotel-bangkok": "曼谷野餐酒店",
    "please-enter-phone-number": "请输入电话号码",
    policy: "策略",
    "popular-things-to-do": "热门门票",
    price: "价格",
    "price-detail": "价格详情",
    "price-details": "价格详情",
    "price-per-night": "每晚价格",
    "price-per-room": "房间单价",
    "price-policy": "費用策略",
    "price/night": "价格/晚",
    "print-e-tickets": "打印电子机票",
    "product-name": "产品名",
    "product-type": "产品类型",
    questions: "问题",
    "rate-comments": "费率解释",
    rating: "评分",
    "reasons-to-trust-us": "相信我们的原因",
    "recent-booking": "最近订单",
    "record-locator": "记录编号",
    register: "注册",
    "register-me": "注册",
    "reservation-status": "订单状态",
    "reservation-total": "订单总价",
    "reserve-now": "現在预定",
    reset: "重置",
    result: "结果",
    "result(s)-found-matching-your-search": "个結果匹配您的搜索条件",
    "read-more": "阅读更多",
    region: "区域",
    remark: "备注",
    "remove-last-flight": "移除最后的航程",
    "rental-days": "租赁天数",
    required: "需要",
    "reservation-total-price": "订单总价",
    return: "返回",
    "return-date-time": "返回日期时间",
    "return-location": "返回地点",
    "return-on": "返回时间",
    "romantic-france": "浪漫的法国",
    room: "房间",
    "room(s)": "房间",
    "room-types": "房型",
    "room-info": "房间信息",
    "room-quantity": "房间数量",
    "room-type": "房型",
    rooms: "房间",
    round: "去回",
    "round-trip": "往返",
    save: "保存",
    "save-changes": "保存修改",
    "save-more": "保存更多",
    "search-booking": "搜索订单",
    "segmentation-group": "团详情",
    "select-date": "选择日期",
    "select-trip": "选择行程",
    "selected-departure-trip": "已选择离开行程",
    search: "搜索",
    "search-car-transfer": "搜索车接",
    "search-flights": "搜索机票",
    "search-hotels": "搜索酒店",
    "search-package": "搜索机+酒",
    "search-perfect-vehicle": "搜索更符合的车辆",
    "search-tour": "搜索门票",
    "search-vehicles": "搜索车辆",
    "second-trip": "第二行程",
    "second-trip-description": "第二行程描述",
    select: "选择",
    "send-email": "发送邮件",
    session: "时间段",
    "ship-name": "船名",
    "shuttle-info": "航班信息",
    "sign-in": "登入",
    "sign-out": "退出",
    "sign-up": "注册",
    "similar-hotel": "类似酒店",
    star: "星級",
    "star-rating": "星級",
    suitcase: "手提箱",
    "special-request": "特殊请求",
    "start-date": "开始日期",
    subscribe: "订阅",
    "subscribe-to-our-newsletter": "订阅我们的新闻稿",
    "suggestions-for-you": "建议",
    "supplier-max-waiting-time": "供应商最大等待时间",
    taxes: "税费",
    "taxes-and-fees": "费用及税费",
    "taxes-total": "总费用",
    "telephone-number": "电话号码",
    "terms-of-use": "使用条款",
    "thank-you": "谢谢",
    the: "第",
    "things-to-do": "门票",
    "things-to-do-date": "门票日期",
    "ticket-of-adult(s)": "成人票",
    "ticket-of-child(ren)": "儿童票",
    "ticket-number": "票号",
    "ticket(s)-and-price": "门票及费用",
    tickets: "票价",
    title: "称谓",
    "ticket(s)": "票券",
    to: "到",
    "top-destination": "热门地点",
    total: "总共",
    "transit(s)": "中转",
    transmission: "运输",
    "trip-way": "行程",
    "total-pay": "总共支付",
    "total-payment-to-be-made": "需要支付的总额",
    "tour-group-name": "旅游团名",
    "tour-info": "旅游信息",
    "tour-name": "旅游名",
    tours: "旅游",
    "train-arrival-time": "火车到达时间",
    "train-departure-time": "火车离开时间",
    "train-number": "火车号",
    update: "更新",
    "useful-links": "有用的链接",
    username: "用户名",
    "user-rating": "用户评分",
    "vehicle-name": "车名",
    "vehicle-type": "车类型",
    "view-details": "查看详情",
    "we-accept": "我们接受",
    welcome: "欢迎",
    "why-choose-us?": "为什么选择我们",
    "will-incur-a": "将会导致",
    with: "有",
    "with-air-conditioning": "空调",
    without: "没有",
    "years-old": "岁",
    "you-need-to-pay": "你需要支付",
    "you-pay": "支付",
    youth: "年轻人",
    "your-selection": "您的选择",
    "your-booking-is-confirmed": "您的订单已经确认",

    "available-credit": "可用信用额度",
    "under-17": "17岁以下",
    "please-enter-a-valid-firstname": "请输入一个有效的名字",
    "select-continue": "选择并继续",
    "how-many-rooms-need": "您需要多少个房间？",
    "order-type": "订单类型",
    "service-name": "服务名称",
    checkin: "到达",
    "hotel-price": "酒店价格",
    "child-less-than": "12 岁以下",
    "show-more-room-rate": "显示更多房价",
    "back-to-dinning": "返回",
    "who-is-traveling": "谁在旅行？",
    "applicant-personal-details": "申请人#{index}个人详细信息",
    "cancellation-policy-nights-caution":
      "在 {beginDate} 之前取消您的预订，您将获得全额退款。之后，我们将向您收取 {nmbrOfUnit} 晚的住宿费用以及税费。时间以酒店当地时间为准。",
    "special-requests-optional": "特殊要求（可选）",
    excellent: "极好的",
    "number-of-stops": "停靠站数量",
    "confirm-your-choices": "确认您的订单",
    pool: "水池",
    "how-many-guests-each-room": "每间客房将容纳多少位客人？",
    condo: "公寓",
    "passport-information": "护照信息",
    economy: "经济舱",
    "our-partners": "我们的伙伴",

    "available-dates": "可用日期",
    "search-by-destination": "按目的地搜索",
    nationality: "国籍",
    "error-500-message": "很抱歉，我们的服务器遇到了内部错误",
    unticketed: "无票",
    "flight-list": "航班列表",
    "meal-plans-available": "提供餐饮",
    "passenger-info": "乘客信息",
    aboard: "船上",

    "next-passenger": "下一位乘客",

    "bag-allow": "允许携带行李",

    "error-503-message": "很抱歉，我们的服务目前不可用",
    flight: "机票",
    "pick-return-location": "接送地点",
    Visa: "签证",
    "flight-price": "航班价格",
    "property-type": "财产种类",
    "info-source": "信息来源：{supplierCode}",
    "number-of-entries": "入境次数",

    "traveler-name": "旅客姓名",
    "hotel-location": "酒店位置",
    "type-of-vehicle": "什么类型的车辆？",

    "expect-to-wait": "乘客等待时间",
    "departure-to-return": "出发返回",

    close: "关闭",
    "price-summary": "价格汇总",

    "number-of-nights": "入住晚数",
    "confirm-booking": "确认预订",

    business: "商务舱",
    "price-per-applicant": "每位申请人的价格",
    "operation-uppercase": "手术",
    "select-weight-of-baggage": "选择您托运行李的重量",
    "view-terms": "查看条款",
    "apart-hotel": "公寓式酒店",
    "any-uppercase": "任何",
    "guest-rating": "宾客评价",
    "agency-portal": "机构门户",
    "your-trip-details": "您的旅行详情",
    "view-your-booking": "查看您的订单",
    "choose-your-category-location-room": "选择您的{categoryLocation}房间",
    "finding-driver": "寻找您的司机",
    chinese: "中文",
    "charge-type": "收费类型",
    "check-out-your-choices": "查看您的订单",
    "your-personal-details": "你个人详细资料",
    airlines: "航空公司",
    radius: "半径",
    info: "信息",
    "please-enter-your-username": "请输入您的用户名",
    "apply-now-for-document": "立即申请{toCountryName}旅行证件",
    journey: "旅程",
    "passenger-room-class": "旅客、房间、舱位",
    "room-price-total": "房价总计",
    "trip-total": "行程总计",
    "add-check-in-baggage": "添加托运行李",
    "room-index": "房间 - 索引",
    "max-stay": "最多停留时间",
    "fare-family": "票价系列",
    "agree-to-terms-conditions": "我同意条款和条件状况",
    "all-bookings": "所有预订",
    "infant-price": "婴儿价格",
    "booking-in-process": "预订中",
    "price-info": "价格信息",
    "please-enter-a-valid-username": "请输入有效的用户名",
    "night-number-high-to-low": "住宿晚数从高到低",
    "child-index-age": "孩子 {childIndex} 年龄",
    "num-hotel": "  找到 {length} 个酒店",
    "radius-search": "半径搜索",
    "required-for-travel": "旅行所需",
    unconfirmed: "未确认",
    "please-contact-email": "请联系邮箱",
    "please-enter-a-valid-password": "请输入有效密码",
    "rooms-can-accommodate": "客房最多可容纳 4 位客人",
    "rail-pass-review": "铁路通票审查",
    origin: "起源",
    back: "返回",
    "night-number": "{number} 晚",
    "continue-to-payment": "继续付款",
    "select-your-seat": "选择您的座位",
    "please-enter-a-valid-lastname": "请输入有效的姓氏",
    remove: "删除",

    "sign-in-now": "立即登录",
    "go-beyond-your-imagination": "超越你的想象",
    company: "公司",
    "hotel-item": "酒店项目",

    "by-credit-card": "用信用卡",
    "rail-ticket-review": "火车票评论",
    "call-us": "如需任何帮助请致电我们",
    "expire-date": "到期日期",

    "sign-in-your-account": "登录您的帐户",
    "lower-available-rate": "较低价格",
    "journey-date": "旅程日期",
    "see-details": "查看具体信息",
    "guests-uppercase": "嘉宾",

    "cruise-home": "游轮首页",
    "flight-class": "飞行等级",
    "more-photos": "更多照片",
    "advance-question": "提前提问",
    "i-agree": "我同意",
    "insurance-home": "保险首页",
    "num-night-stay": "{num} 晚住宿",
    "two-less-than": "2 - 12 岁以下",
    "flight-date": "航班日期",
    configurations: "配置",
    discount: "折扣",
    "log-out": "退出登录",
    "fare-rules": "票价规则",
    "total-amount": "总金额",
    "birth-date": "出生日期",
    "error-401-message": "很抱歉，您无权访问此页面",
    supplier: "供应商",
    "for-all-applicants": "对于所有申请人",
    "per-entry": "每个条目",
    "reactive-your-account": "回复您的帐户",
    order: "命令",
    "your-application": "你的申请",
    "baggage-option": "行李选项",
    "drivers-can-wait": "司机最多可以等待",
    "each-passenger-can-select": "每位乘客可以选择一个选项。",
    index: "指数",
    "residency-information": "居住信息",
    "frequent-answer-and-question": "常见的回答和问题",
    "ticketing-success": "票务成功",
    "cruise-itinerary": "邮轮行程",
    "search-orders": "搜索订单",
    "airport-shuttle-included": "包括机场班车",
    "room-name-index": "房间 {index} { roomTypeName }",
    status: "地位",
    "today-price": "今日价格",
    "get-latest-news-offers": "获取最新消息和优惠",
    "price-starts-from": "{currency} {amount}价格从",

    extras: "附加功能",
    "my-profile": "我的简历",
    "hostel-backpacker": "旅馆/背包客旅馆",
    holiday: "假期",
    "back-to-results": "返回结果",

    "night-number-low-to-high": "住宿晚数从低到高",
    "common-area": "公共区域",
    "please-enter-a-valid-phone-number": "请输入一个有效的电话号码",
    surname: "姓",
    "your-booking-detail": "您的预订详细信息已发送至：{ emailAddress }",
    "room-uppercase": "房间",
    "stopover-in": "在#{到达机场名称}中途停留#{arrivalDateTime}",

    "cancellation-policy-refundable-caution":
      "在 {beginDate} 之前取消您的预订，您将获得全额退款。之后，我们将向您收取 {currency} {amount}。时间以酒店当地时间为准。",
    "sub-total": "小计",
    "select-the-child-age": "请选择孩子的年龄！",
    "base-fare": "基本做事",
    "basic-rate": "基本费率",
    "guest-total": "乘客 { cabPriceIndex } 费用总计",
    "create-by": "创建者",
    "contact-with-us": "与我们联系",
    "num-passenger": "{passengersNum} 乘客",
    "no-seat-segment": "该航段无法选择座位",
    "copy-right": "版权所有 © 2023 保留所有权利",
    "processing-time-fee": "处理时间和费用",
    "travel-document-book": "旅行证件簿",
    "protections-extras": "附加功能",
    "subscribe-our-newsletter": "订阅我们的新闻",
    "total-uppercase": "全部价格",
    "enter-the-email": "请输入与您的帐户相结合的电子邮件",
    "enter-valid-credential": "请输入有效的凭据",
    "find-available-cruises": "查找可用的游轮",
    "travel-document-option": "旅行证件选项",
    "applying-for": "正在申请",

    "select-your-type-of-inside": "选择您的房间类型",
    traveler: "游客",
    "double-room": "双人间",

    "ports-of-call": "停靠港",
    "delivered-at": "接送时间",
    "cabin-price": "舱位价格",
    "special-requests": "特别要求",
    "pax-num": "和平号",
    "discover-your-ideal": "与我们一起探索您的理想体验",
    "stateroom-amenities": "客舱设施",
    "submit-ticket-order": "提交门票订单",
    stopover: "中途停留",
    "expiry-date": "到期日",
    "pax-type": "乘客类型",
    "after-issued": "办理后",
    stock: "库存",
    "my-bookings": "我的预订",
    "pickup-and-return": "往返地点",
    "room-name": "房间名称",
    "show-less": "显示较少",
    "price-low-to-high": "价格：从低到高",
    "meet-your-driver": " 您的预订确认后，我们将发送与司机会面的分步说明。",
    spa: "温泉",
    "select-your-room": "选择您的房间",
    "add-another-person": "添加另一个人",
    "fill-following-details": "请填写以下详细信息以创建新帐户。",
    "logged-in-to-stay-in-touch": "登录以保持联系",
    "itinerary-review": "行程预览",
    "children-ages": "0至17岁儿童",
    "living-country": "居住的国家",
    "sort-by": "排序方式",
    "activities-list": "活动清单",
    "already-have-an-account": "已经有帐户？",
    position: "位置",
    basic: "基本的",
    "breakfast-included": "包含早餐",
    "same-return-location": "回程地点相同",
    "cabin-type": "舱型",
    exterior: "外部的",
    policies: "政策",
    "booking-summary": "预订摘要",
    "register-your-account": "注册您的帐户",
    "search-for-an-activities": "搜索活动",
    "where-am-i-from": "我从哪里来？",
    "error-400-message":
      "您发出了错误的请求，或者请求已损坏并且服务器无法理解它",
    "guest-name": "客人姓名",
    "add-another-flight": "添加另一个航班",
    "are-you-sure-ticketing": "  您确定要出票#{ id } {idContext }吗？",
    reserve: "预订",
    "you-need-visa-travel":
      "如果您持有 {fromCountryName} 的护照，则需要签证才能前往 {toCountryName}",
    "check-in-to-check-out": "入住到退房",
    "remove-person": "删除人员",
    "stay-in-touch": "保持联系",
    "return-time": "返回时间",
    "i-live-in": "我住在",
    or: "或者",
    "price-uppercase": "价格",
    "children-uppercase": "孩子们",
    "booking-success": "预订成功！",
    "arrival-date": "到达日期",
    "searching-result": "搜索结果",
    "order-list": "订单",
    any: "任何",
    "contact-information": "联系信息",
    "edit-seat": "编辑座位",
    "booking-preview": "订单预览",
    application: "应用",
    "Avg-person": "平均/人",
    activities: "活动",
    "hotel-detail": "酒店详情",
    "confirmation-number": "确认号码：",
    properties: "条",
    "export-to-sabre": "导出至 Sabre",
    "info-uppercase": "信息",
    "name-title": "名称",
    prev: "上一篇",
    "sailings-uppercase": "航行",
    "all-inclusive": "全包",
    "remember-me": "记住账号",
    ship: "船",
    "rail-home": "铁路之家",
    "select-fares": "选择票价",
    "hotel-home": "酒店首页",
    "send-mail": "发送邮件",
    "very-good-4.0+": "非常好4.0+",
    "send-message": "发信息",
    "create-date": "创建日期",
    "country-of-residence": "居住国家*",
    PNR: "订座记录",
    english: "英语",
    "choose-your-room": "选择您的房间",
    "view-dates": "{number} 个日期查看 ",
    requester: "请求者",
    sleeps: "睡觉",

    "pick-your-add-ons": "选择您的附加组件",
    "please-enter-a-valid-email": "请输入有效的电子邮件地址",
    "booking-confirm": "预订确认",
    "rate-description": "费率说明",
    "accessorial-service": "配套服务",
    "welcome-are-you-going": "你要去哪里",
    "print-invoice": "打印发票",
    stops: "停靠点",
    "travel-document": "旅行证件",
    "guest-rating-our-picks": "宾客评分 + 我们的精选",
    "rail-search-list": "铁路搜索列表",
    "air-item": "航空项目",
    "includes-taxes-fees": "包括税费",
    cabin: "舱",
    "passenger-class": "乘客，舱位",
    "order-status": "订单状态",
    "pick-up": "出发地",
    "please-enter-a-username": "请填入一个用户名",
    "select-segments": "选择细分",
    "wonderful-4.5+": "精彩4.5+",
    "taxes-fees-and-port-expenses": "税费、费用和港口费用",
    "lessons-hours": "10 节课 · 3 小时",
    "total-include-amount": "总计 {currency} {amount}",
    "departure-port": "出发港",
    "quote-type": "报价类型",
    currency: "货币",
    "ticketed-uppercase": "已出票",

    departs: "出发",
    "cancellation-policy": "取消政策",
    protections: "保护措施",

    "get-order-prices": "获取订单价格",
    "order-id": "订单编号",
    "adult-price": "成人价",
    "holiday-customise": "假期定制",
    people: "人",
    "forgot-password": "忘记密码",
    "total-due": "应付总额",
    confirm: "确认",
    "explore-this-itinerary": "探索此行程",
    "traveler-info": "旅客信息",
    "learn-html5": "通过 10 个简单易懂的步骤学习 HTML5",
    marketing: "营销",
    "booking-date-range": "预订日期范围",
    "package-flight-hotel": "套票(机票+酒店)",
    "good-3.5+": "好3.5+",
    "given-name": "名",

    "ticketing-confirmed": "您的票务 #{ TicketId } 已确认",
    "visiting-uppercase": "参观",
    "desk-name": "办公桌名称",
    "explore-our-hot-deals": "探索我们的热门优惠",
    insurance: "保险",
    "expiry-time": "到期时间",
    "car-rental-home": "租车首页",
    "lunch-included": "含午餐",
    "register-now": "现在注册",

    "report-it": "举报",
    "less-than-two": "不到2年",
    "next-flight": "下一航班",
    "confirmed-uppercase": "确认的",
    luggage: "行李",
    "price-high-to-low": "价格：从高到低",

    "read-and-accept-conditions": "我已阅读并接受所有条款和条件",
    "total-price-range": "总价格范围",
    "general-question": "一般问题",
    "arr-time": "到达。时间",
    "back-to-home": "回到家",
    "see-remark": "查看备注",

    "send-links": "发送链接",

    "your-vehicle": "接送您的车辆",

    "room-prices-details": "房价详情",

    "welcome-please-login": "欢迎您，请登录。",
    "valid-for": "有效期为",
    "dept-time": "部门时间",
    "no-room-type-name": "无房型名称",
    support: "支持",
    "continue-to-confirm": "继续确认",
    next: "下一个",
    "car-rental-list": "租车列表",
    submit: "提交",
    "booking-confirmed": "您的订单 #{ bookingId } 已确认",

    "please-enter-your-password": "请输入您的密码",

    "num-flight": "  找到 {length} 个航班",
    "get-price": "获取价格",
    "multi-city": "多城市",
    "passenger-type": "旅客类型",
    "select-status": "选择状态",
    "measurement-infos": "测量信息",
    "cancellation-policy-nonrefundable-caution":
      "在 {beginDate} 之前取消您的预订，您将获得全额退款。之后，您将被收取全部费用。时间以酒店当地时间为准。",
    "get-comfy-during-journey":
      " 旅途中尽享舒适！无论您想要安静的飞行还是只是想增加一点腿部空间，选择完全取决于您！",
    "your-choices": "您的选择",
    refundable: "可退款",
    "check-email-info":
      "您可以查看您的电子邮件以获取更多详细信息。我们已向您发送了一封包含详细信息的邮件。",
    "including-taxes": "含税",
    checkout: "查看",
    "price-total": "{ currency } {price} 总计",
    "provide-your-account":
      "请提供您帐户的电子邮件或用户名，我们将向您发送您的密码。",

    "would-you-like-to-us-know": "您愿意让我们知道吗？",

    previous: "以前的",
    "traveling-for": "旅行的目的",
    "receipt-status": "收据状态",
    "view-fare-rules": "查看票价规则",
    start: "开始",
    "taxes-fees-port-expenses": "税费、费用和港口费用",
    "adults-uppercase": "成年人",
    "booking-amount": "预订金额",
    "hotel-search": "酒店搜索",
    "see-fare-details": " 查看票价详情",
    "family-name": "姓",
    "passport-expiry-date": "护照有效期为出发或返回后 6 个月内",
    "get-free-quote": "获取免费报价",

    "vans-trucks": "货车和卡车",
    "num-trip-advisor": "{num}/5 猫途鹰",

    "we-choose": "我们选择",

    type: "类型",
    "select-your-seats": " 选择您的座位",
    "select-passengers": "选择乘客",
    "dinner-included": "含晚餐",
    logout: "登出",

    children: "孩子们",
    "sure-export-booking": "您确定要导出预订#{ bookingId }",
    "standard-days": "标准 - {num} 天",

    connection: "中转站",
    "all-photos": "所有照片",
    dashboard: "仪表板",
    "taxes-fees": "税费",
    "error-404-message": "很抱歉，找不到您要查找的页面",
    "expire-time-min-s": "{ 分钟 } 分 {秒} 秒后过期",
    "created-date-range": "创建日期范围",
    "please-enter-hotel-location": "请输入酒店位置",
    "where-am-i-going": "我要去哪里",
    "more-details": "更多细节",
    "payment-method": "付款方式",
    "child-price": "儿童价",
    "confirmation-number-with-supplier": "确认号({supplierName})",
    "pickup-and-return-time": "接载地点及时间",
    "minimum-driving-age-years": "最低驾驶年龄：{minimumDriverAge}岁",
    "cruise-dates": "邮轮日期",

    "your-passport-information": "您的护照信息",
    "status-uppercase": "地位",
    "pick-up-time": "接载时间",
    "bookings-list": "预订清单",
    "go-to-home": "返回首页",

    "select-your-room-category": "选择您的舱型",

    "remove-room": "移除房间",
    "hotel-amenities": "酒店设施",
    "travel-document-home": "签证首页",
    "inbox-menu": "收件箱菜单",
    "leave-us-a-message": "给我们留言",
    "filter-by-price": "按价格过滤",
    tourism: "旅游",
    "room-types-rate-comments": "评价评论 - { roomTypes } - {roomIndex}",
    confirmed: "确认的",
    "airline-RLOC": "航空公司 RLOC",
    vehicle: "车辆",
    "ferries-and-buses": "渡轮和巴士",
    "bookings-found": "找到预订",
    "add-ons-pick": "附加组件选择",
    "package-home": "套餐首页",
    "fare-basic": "基本票价",
    "invoice-number": "发票号码：",
    "number-of-connections": "中转站数",
    "must-agree-service-terms": "您必须同意服务条款！",
    "fare-basis": "票价基础",
    "frequent-flyer-airline": "飞行常客航空公司",
    "see-all-properties": "返回搜索结果",
    "create-uppercase": "创造",
    "need-help": "需要帮助吗？",
    "cancel-booking": "取消预订",
    date: "日期",
    booking: "预订",
    "reset-your-password": "重置你的密码",
    "seat-unavailable": "没有座位",
    "extra-service-total": "额外服务总计",
    "departing-from": "出发地",
    "add-another-room": "添加另一个房间",
    "regular-question": "常规问题",
    "holiday-home": "渡假村",
    "night-stay": "过夜",
    "name-submitted-successfully":
      "{surname}/{givenName}，您的订单已成功提交！",
    "taxes-include-total": "税费（包含在总额中）",
    sales: "销售量",
    "baggage-allowance": "行李限额",
    "licenses-fees": "许可证和费用",
    "please-confirm the password": "请确认密码",
    "flexibility your own flying experience":
      "我们将为您带来全新的预订体验，让您拥有一切灵活定制属于您自己的飞行体验",
    "trip-advisor": "旅行顾问",
    discounts: "折扣",
    create: "创造",
    "follow-us": "跟着我们",
    "for-room": "适合 { roomNum } 房间",
    "infant-less-than": "不到2年",
    "edit-meal": "编辑膳食",
    "include-in-total-amount": "包含在总金额中",
    "multi-way": "多程",
    minutes: "分钟",
    rail: "轨",
    "search-for-the-best-rooms-available":
      "我们将搜索所选类别中可用的最佳房间。客房最多可容纳 4 位客人",
    tax: "税",
    done: "完成",
    travelers: "旅行者",
    "cabin-number": "舱位号码",
    "trip-total-price": "行程总价",
    "show-details": "显示详细资料",
    "who-we": "我们是谁",
    "apply-now": "现在申请",
    "cruise-search-results": "邮轮搜索结果",
    "dont-have-a-account": "没有账户",
    "register-account": "注册账户",
    error: "错误",
    "sure-cancel-booking": "您确定要取消预订吗#{ bookingId }",
    "please-provide-a-password": "请提供密码",
    "protection-plans": "保护计划",
    "please-enter-your-departure-point": "请输入您的出发地点",
    "load-more": "加载更多",
    "available-extras": "可用的附加功能",
    "edit-baggage": "编辑行李",
    "frequent-flyer-airline-number": "飞行常客航空公司号码",
    "see-fare-basis": "查看票价基础",
    amount: "数量",

    "go-back-to-dashboard": "返回仪表板",
    "room-price": "房价",
    "passport-requirements": "护照要求",

    "search-result": "搜索结果",
    "start-now": "现在开始",
    "error-403-message": "很抱歉，您没有访问此页面的权限",
    "from-to": "从{from}到{to}",
    "show-more": "展示更多",
    "created-date": "创建日期",
    recommended: "推荐",

    "mail-us": "邮寄给我们的支持团队",
    "please-enter-your-arrival-point": "请输入您的到达地点",
    "third-party-liability": "第三方责任",
    reselect: "重新选择",
    "flight-home": "机票首页",
    "going-to": "目的地",
    "processing-speed": "处理速度（尚未选择）",
    "prev-passenger": "上一位乘客",
    front: "正面",

    in: "在",
    "cruise-package-code": "游轮套餐代码",
    deposit: "押金",
    "deposit-in": "押金{index} ( {key} )",
    "trip-total-in": "总价格( {key} )",
    "guest-info": "访客信息",

    "side-overlay-content": "侧面叠加内容..",
    carts: "购物车",
    reports: "报表",

    "current-language": "当前语言",
    "all-languages": "所有语言",
    "filter-by": "过滤条件",
    "basic-search": "基础搜索",
    "passengers-summary": "{passengersNum} 乘客 ,{cabinName} ",
    "passengers-room-summary":
      "{passengersNum} 乘客 ,{roomCount} 房间 ,{cabinName} ",
    "hotel-shopping": "酒店预订",
    "travel-room-num": "{travelersNumer} 乘客 , {roomCount}  房间",
    "who-checking": "登记人员",
    "cancellation-policies": "退款政策",
    "daily-prices": "价格详情",
    "cruise-shopping": "游轮预定",
    "booking-progress": "预定",
    guests: "乘客",
    "select-dining-preference": "选择您的用餐偏好",
    "cruise-dinning":
      "2019年2月，名人峰会参加了名人船队革命。餐厅和休息室不仅仅是餐厅和消磨时间的地方。贵宾室变成了远离家乡的家和客人的私人避难所。名人峰会不再只是一艘船，而是一种更好的体验世界的方式。",

    carRental: "租车",
    "car-rental-search": "租车搜索",
    seats: "座",
    "car-transfer-home": "车接首页",
    infants: "婴儿",

    "adult-child-infant":
      "{adultNum}  成人, {childNum} 小孩 , {infantNum}  婴儿",
    "add-another": "增加",
    "your-journey": "您的旅程",
    "travel-document-apply": "旅游证件申请",
    validity: "有效期",
    review: "检查",
    documents: "证件",
    applicant: "申请人",
    gender: "性别",
    "personal-details": "个人信息",
    "package-search": "套餐搜索",
    "hotel-result": "酒店结果",
    "terms-and-condition": "条款及细则",

    expired: "过期",

    "num-flights-found": "{flightViewItemsShowLength} 条机票搜索结果",

    passport: "护照",
    phone: "手机",

    sort: "排序",
    "search-by-departure": "按出发地点搜索",

    "taxes-fees-included": "含税及费用",

    "your-choice": "您的选择",
    "view-on-map": "在地图上查看",
    "where-are-you-going": "您要去哪里?",
    "enter-a-email-address": "输入邮箱地址",
    "enter-your-username": "输入用户名",
    "enter-your-password": "输入密码",
    "enter-your-first-name": "输入名字",
    "enter-your-last-name": "输入姓氏",
    "enter-your-email-address": "输入邮箱地址",
    "enter-your-phone-number": "输入电话",
    "please-enter-price-range": "请输入正确的价格范围",
    "terms-and-conditions": "条款与条件",
    "cancel-successful": "成功取消!",

    "login-success": "登入成功，欢迎 {userId} . ",
    "login-failed": "登录失败，请再次检查账号/密码",
    "agreement-between-customer-and-sesamedoortravel":
      "客户与sesamedoortravel.com之间的协议",
    "supplier-terms-and-conditions": "供应商条款与条件",
    "supplier-terms-and-conditions-content":
      "一般预订条款和条件(“一般条款和条件”)以及您的预订确认电子邮件(“预订确认”)中列出的信息，规定了本公司同意通过网站向您提供服务的条款(见下文定义条款)。",
    "contact-address": "香港鲗鱼涌海泽街28号东区海港中心14楼1411室",
    "copyright-info": "版权所有©{year} LimpidThemes。版权所有。",
    "lorem-ipsum-is-simply-dummy-text":
      "Lorem Ipsum只是一个虚拟文本。Lorem Ipsum只是印刷和排版行业的虚拟文本",
  },
  zh_HK: {
    "please-enter-passport": "請輸入護照",
    "please-enter-birth-date": "請填寫生日",
    "please-enter-expire-date": "請填寫護照過期時間",
    "customize-your-trip": "製定您的旅行",
    "review-and-pay": "審查和支付",
    "select-a-room": "選擇房間",
    "cancellations-hotel-with-penalty":
      " {date} 之后取消将会收到 {currencyCode} {amount} 的罰款 ",
    "cancel-error": "取消失敗!",
    "please-select": "請選擇",
    "currency-tag": "貨幣單位",
    "language-switch": "語言切換",
    "romantic-paris": "浪漫巴黎",
    "hong-kong-fun": "香港遊玩",
    "sky-high-dubai": "迪拜之遊",
    "check-your-email-for-details":
      "你可以查看你的電子郵件以了解更多的細節。我們已經給你發了一封詳細的郵件。",
    "please-enter-your-new-password": "請輸入您的新密碼",
    "please-confirm-your-password": "請輸入您的密碼",
    "indian-food": "印度餐",
    "chinese-food": "中餐",
    "sea-food": "海鮮餐",
    bar: "酒吧",
    restaurant: "餐廳",
    "booking-closed": "訂單已關閉",
    "booking-canceled": "訂單已取消",
    "booking-completed": "訂單已完成",
    "booking-paid": "訂單已支付",
    "booking-received": "訂單已生成",
    "review-things-to-do": "門票預覽",
    "in-room": "在房間",
    "see-rate-comments": "查看費率解釋",
    payment: "支付",
    "review-room": "房間信息",
    "booking-info": "預訂信息",
    "special-request-content": "您可以在此處寫下特殊要求",
    "please-enter-email-address": "請輸入郵箱",
    "need-help-content":
      "需要幫忙嗎?打電話給我們或留言。我們的管理員很快就會聯絡你。",
    "your-residency-information": "您的居住信息",
    "please-enter-last-name": "请輸入姓",
    "please-enter-first-name": "請輸入名",

    "of-state-rooms": "房間",
    "of-guests": "乘客",
    "category-uppercase": "艙型",
    "room-type-uppercase": "房型",
    "ROOM-uppercase": "房間",
    "guest-info-uppercase": "乘客信息",
    "review-uppercase": "預覽",
    "payment-uppercase": "支付",
    "your-info": "您的信息",
    "trip-details": "旅行詳情",
    docked: "停靠港口",
    ports: "港口",
    "at-sea": "在",
    "no-available-cruise": "無遊輪結果",
    "book-travel-document": "預定簽證",
    "sailing-dates": "航行時長",
    "book-cruise": "預定遊輪",
    terminal: "站台",
    "operated-by": "運營商",
    "tour-activity": "門票",
    "please-select-area-code": "請選擇區號",
    "password-valid-content":
      "密碼至少包含一個大寫字母、一個小寫字母，必須包含 '{'@'}$%=' 特殊字元且不少於8個字符。",
    "name-change": "姓名改變",
    "apply-name-change": "姓名改變",
    "apply-doc-change": "護照信息改變",
    "apply-contacts-change": "聯係信息改變",
    "apply-ffp-change": "常飛旅客號碼改變",
    "check-in-and-important-information": "到達以及重要信息",
    "travel-document-name": "旅行證件名稱",
    "layover-in-airport": "在機場停留",
    "generate-quotation": "生成報價",
    "flight-booking-preview-reminder":
      "提醒:所選票價不支持有輔助的預訂服務。所選的輔助服務將不會被加入預訂。購票後請購買輔助服務。",
    "flight-detail": "機票詳情",
    "morning-filter-time": "早上 (00:00-11:59)",
    "afternoon-filter-time": "下午 (12:00-17:59)",
    "evening-filter-time": "晚上 (18:00-23:59)",
    "transit-num": "{num} 站(s)",
    "no-transit": "0 站/直達",
    "reset-password-success":
      "我們給你的郵箱發了重置密碼的連結。您可以透過連結更改密碼。",
    "grand-total-payable": "應付總額",
    "select-flight-from-to": "選擇從 {from} 到 {to} {date}的機票",
    "seat-only-uppercase": "提供座位",
    "more-options": "更多選項",
    "no-available-flights": "暫無合適的航班",
    first: "頭等艙",
    "enter-a-correct-email": "請輸入一個正確的郵箱.",
    send: "发送",
    "reactive-account": "激活賬號",
    "forget-password": "忘记密码",
    "reset-password": "重設密碼",
    "fast-links": "快速鏈接",
    "username-email": "用戶名/郵箱",
    "how-it-working-content":
      "與像我們這樣的在線旅行社(ota)合作不僅可以幫助您接觸到全球更多的旅行者，<br />還可以讓您在他們正在研究、計劃和預訂下一次旅行時與他們互動。<br />了解他們是如何工作的，他們提供什麽工具，以及如何為您的企業選擇正確的工具",
    "no-data": "暫無數據",
    "1-room-fits": "一個房間適合",
    "about-hotel-description": "酒店描述",
    ascending: "升序",
    "add-a-flight": "添加航程",
    address: "地址",
    adult: "成人",
    "adult(s)": "成人",
    "adult(s)-to": "成人到",
    "adult-fare": "成人價格",
    adults: "成人",
    age: "歲數",
    "air-reservation": "機票訂單",
    "air-tickets": "機票",
    airline: "航空公司",
    "airline-record-locator": "航空公司記錄編號",
    all: "全部",
    amenities: "便利設施",
    amenity: "便利設施",
    "amenity-of-hotel": "酒店設施",
    "amenities-of-hotel": "酒店設施",
    "ancillary-services": "附加服務",
    "area-code": "地區代碼",
    arrival: "到達",
    "arrival-airport": "到達機場",
    "arrival-time": "到達時間",
    "arrival-time-in-destination": "目的地到達時間",
    "awesome-amsterdam": "Awesome Amsterdam",
    avg: "平均",
    "basic-description": "基本描述",
    "become-a-partner": "成為夥伴",
    "best-price": "最低價格",
    "beach-goa": "Beach Goa",
    "bed-type": "床型",
    before: "之前",
    blog: "BLOG",
    book: "預定",
    "book-now": "現在預定",
    "book-car-transfer": "預定車接",
    "book-flight-tickets": "預定機票",
    "book-holiday-package": "Book Holiday Package",
    "book-hotel-rooms": "預定房間",
    "book-tour-activity": "預定門票",
    "booking-create-date": "訂單創建日期",
    "booking-engine": "Sesame Door Travel",
    "booking-date": "預定時間",
    "booking-id": "訂單標識",
    "booking-number": "訂單單號",
    "booking-overview": "訂單概覽",
    "booking-status": "訂單狀態",
    "booking-total": "訂單總價",
    bookings: "訂單",
    breakfast: "早餐",
    brand: "品牌",
    "cabin-class": "艙型",
    cancellable: "可取消",
    cancel: "取消",
    "cancellations-after": "之後取消",
    cancelled: "已取消",
    "cancel-policy": "取消策略",
    "car-brand": "汽車品牌",
    "car-details": "汽車詳情",
    "car-rental-price": "租賃費用",
    career: "Career",
    "car-features": "特性",
    "car-info": "汽車信息",
    "change-password": "修改密碼",
    "confirm-password": "確認密碼",
    "contact-hotel": "聯繫酒店",
    "contact-number": "聯繫號碼",
    "contract-remarks": "備註",
    "current-address": "當前地址",
    "car-name": "汽車名",
    "car-rental": "租車",
    "car-rental-info": "租賃信息",
    "car-transfer": "車接",
    "car-transfer-info-first-trip": "車接 - 第一程",
    "car-transfer-info-second-trip": "車接 - 第二程",
    "car-transfer-total-price": "總費用",
    cars: "車",
    "casino-los-vages": "Casino Los Vages",
    "check-date": "日期",
    "check-in": "入住",
    "check-in-date": "入住日期",
    "check-out": "确认",
    "check-out-date": "退房時間",
    child: "兒童",
    "child-age": "兒童歲數",
    "child(ren)": "兒童",
    "child-fare": "兒童價",
    "city-name": "城市名",
    class: "類型",
    "departure-date": "離開時間",
    "departure-time": "起飛時間",
    "departure-time-in-origin": "始發地起飛時間",
    home: "首頁",
    package: "機+酒",
    "package-total-payable": "機+酒總額",
    "return-date": "回程時間",
    "contact-agent": "聯繫代理商",
    "contact-person": "聯繫人",
    "contact-us": "聯係我們",
    contact: "聯繫人",
    continue: "繼續",
    country: "國家",
    "country-code": "國家代碼",
    "coverage-info": "附加信息",
    cruise: "遊輪",
    "cruise-holidays": "Cruise Holidays",
    currencytag: "貨幣",
    "customer-max-waiting-time": "顧客最大等待時間",
    "date-of-birth": "出生日期",
    "date-range": "日期範圍",
    day: "天",
    "day(s)": "天",
    days: "天",
    "dedicated-support": "專用支持",
    descending: "降序",
    description: "描述",
    developers: "開發者",
    dinner: "晚餐",
    "discover-how-they-work,-what-tools-they-offer-and-how-to-choose-the-right-one-for-your-business":
      "Discover how they work, what tools they offer and how to choose the right one for your business.",
    door: "門",
    doors: "門",
    "departing-on": "出發日期",
    departure: "離開",
    "departure-airport": "出發機場",
    "desired-pick-up-time": "需要接駁時間",
    destination: "目的地",
    details: "詳情",
    "domestic-flights": "國內航班",
    "domestic-hotels": "國內酒店",
    "driver-details": "司機詳情",
    "driver-info": "司機信息",
    "driver-nation": "司機國籍",
    "driver-nationality": "司機國籍",
    "drop-off-date-time": "落車時間",
    "drop-off-location": "落車地點",
    duration: "时长",
    "edit-profile": "修改信息",
    "email-id": "郵件",
    email: "郵件",
    "email-address": "郵件地址",
    "extra-price": "額外費用",
    explore: "探索",
    extra: "附加",
    "extra-service": "附加服務",
    facilities: "設施",
    faq: "快速問答",
    fare: "費用",
    "fare-details": "費用詳情",
    "flight-arrival-time": "飛機到達時間",
    "flight-departure-time": "飛機起飛時間",
    "flight-preference": "航班偏好",
    "flight-details": "飛機詳情",
    "flight-number": "飛機號",
    "flight-tickets": "機票",
    "flight-total-payable": "機票總額",
    flights: "飛機",
    "flying-time": "飛行時間",
    "food-preference": "食物偏好",
    "found-matching-your-search": "匹配您的搜索條件",
    "free-cancel": "免費取消",
    fees: "費用",
    "fees-and-taxes": "費用與稅費",
    "fees-info": "費用信息",
    "fees-total": "費用總額",
    "first-name": "名",
    "first-trip": "第一行程",
    "first-trip-description": "第一行程描述",
    fit: "適合",
    "fit-description": "適合描述",
    "free-cancel-before": "免費取消",
    "free-cancel-date": "免費取消日期",
    "free-cancel-date(before)": "免費取消日期（前）",
    from: "從",
    "get-latest-status": "獲取最新狀態",
    "go-back": "回退",
    "guest-per-room": "客人/房間",
    "handpicked-tour": "精選之旅",
    hi: "你好",
    "high-to-low": "由高到低",
    "holder-details": "持有人詳細信息",
    "holder-info": "持有人信息",
    hotel: "酒店",
    "hotel-booking-overview": "酒店訂單概覽",
    "hotel-info": "酒店信息",
    "hotel-name": "酒店名",
    hotels: "酒店",
    "hot-deals": "熱門訂單",
    "hotel+flights": "機+酒",
    "hotel-preference": "酒店偏好",
    "how-it-works?": "如何工作？",
    "how-many-tickets?": "多少張票？",
    "i-want-to-go": "我想要去",
    "important-info": "重要信息",
    infant: "嬰兒",
    "infant(s)": "嬰兒",
    "international-flights": "國際航班",
    "international-hotels": "國際酒店",
    "know-more": "了解更多",
    language: "語言",
    "last-name": "姓",
    "leaving-from": "離開",
    "leaving-to": "去往",
    included: "包含",
    "instant-confirmation": "及時確認",
    "insurance-total": "保险总费用",
    "it-allows-you-to-engage-them-when-they;re-researching,-planning-and-booking-their-next-trip.":
      "it allows you to engage them when they;re researching, planning and booking their next trip.",
    itinerary: "行程",
    location: "位置",
    login: "登入",
    "lorem-ipsum-is-simply-dummy-text":
      "Lorem Ipsum只是一個虛擬文本。Lorem Ipsum只是印刷和排版行業的虛擬文本",
    "low-to-high": "由低到高",
    "lowest-price": "最優惠價格",
    lunch: "午餐",
    "lorem-ipsum-is-simply-dummy-text-of-the-printing-and-typesetting-industry.lorem-ipsum-has-been-the-industrys-standard-dummy-text-ever-since-the-1500s.":
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum has been the industrys standard dummy text ever since the 1500s.",
    make: "製造",
    "mall-road,-shimla,-himachal-pradesh,-176077":
      "Mall Road, Shimla, Himachal Pradesh, 176077",
    meals: "餐飲",
    mode: "模式",
    "more-info": "更多信息",
    "multi-trip": "多程",
    "must-check-pick-up-time": "必須確認接駁時間",
    name: "名字",
    nights: "晚",
    "manage-your-account": "管理賬號",
    meal: "餐飲",
    "minimum-order": "最小訂單數",
    modify: "修改",
    "modify-search": "重新搜索",
    "must-check-pick-up-time.": "必須確認接駁時間",
    "need-help?": "需要幫忙？",
    "need-help?-call-us-or-drop-a-message.-our-agents-will-be-in-touch-shortly.":
      "Need Help? Call us or drop a message. Our agents will be in touch shortly.",
    none: "無",
    "non-cancellable": "不可取消",
    "non-refundable": "不可退款",
    "not-need-to-check-pick-up-time": "不需要確認接駁時間",
    neighborhood: "鄰居",
    "next-step": "下一步",
    "new-password": "新密碼",
    night: "晚",
    "no-available-car-transfer!": "無可用車接服務！",
    "no-available-room-please-select-other-available-hotels":
      "無空餘房間，請搜索其他酒店",
    "no-matched-booking": "無匹配訂單",
    "no-similar-hotel-": "無類似酒店",
    "offer-ml": "餐饮",
    "offer-bg": "行李",
    "offer-sa": "座位",
    "offer-ie": "娱乐",
    "offer-md": "医疗服务 -- 轮椅 / 氧气等",
    "one-way": "單程",
    "our-services": "我們的服務",
    "old-password": "舊密碼",
    others: "其他",
    overview: "概覽",
    partners: "夥伴",
    password: "密碼",
    penalty: "罰款",
    person: "人員",
    "personal-information": "個人信息",
    pickup: "接",
    "pickup-location": "接駁地點",
    "please-enter-contact-number": "請輸入聯繫號碼",
    "price-range": "價格區間",
    "print-voucher": "打印發票",
    "privacy-policy": "隱私權政策",
    profile: "簡介",
    passenger: "旅客",
    "passenger-information": "旅客信息",
    "passenger-name": "旅客名",
    passengers: "旅客",
    "passengers-information": "旅客信息",
    "passport-country": "護照國家",
    "passport-expire-date": "護照過期時間",
    "passport-number": "護照編號",
    "per-day": "每天",
    "per-night": "每晚",
    "per-pax": "每人",
    "per-trip": "每行程",
    "phone-number": "電話號碼",
    "pick-date-time": "接駁時間",
    "pick-up-date": "接駁日期",
    "pick-up-date-time": "接駁日期時間",
    "pick-up-location": "接駁地點",
    "pick-up-remarks": "接駁備註",
    "picnic-hotel-bangkok": "Picnic Hotel Bangkok",
    "please-enter-phone-number": "請輸入電話號碼",
    policy: "策略",
    "popular-things-to-do": "熱門門票",
    price: "價格",
    "price-detail": "價格詳情",
    "price-details": "價格詳情",
    "price-per-night": "每晚價格",
    "price-per-room": "房間單價",
    "price-policy": "費用策略",
    "price/night": "價格/晚",
    "print-e-tickets": "打印電子發票",
    "product-name": "產品名",
    "product-type": "產品類型",
    questions: "問題",
    "rate-comments": "费率解释",
    rating: "評分",
    "reasons-to-trust-us": "相信我們的原因",
    "recent-booking": "最近訂單",
    "record-locator": "記錄編號",
    register: "註冊",
    "register-me": "註冊",
    "reservation-status": "訂單狀態",
    "reservation-total": "訂單總價",
    "reserve-now": "現在預定",
    reset: "重置",
    result: "結果",
    "result(s)-found-matching-your-search": "個結果匹配您的搜索條件",
    "read-more": "閱讀更多",
    region: "區域",
    remark: "備註",
    "remove-last-flight": "移除最後的航程",
    "rental-days": "租賃天數",
    required: "需要",
    "reservation-total-price": "訂單總價",
    return: "返回",
    "return-date-time": "返回日期時間",
    "return-location": "返回地點",
    "return-on": "返回時間",
    "romantic-france": "Romantic France",
    room: "房間",
    "room(s)": "房間",
    "room-types": "房型",
    "room-info": "房間信息",
    "room-quantity": "房間數量",
    "room-type": "房型",
    rooms: "房間",
    round: "去回",
    "round-trip": "往返",
    save: "保存",
    "save-changes": "保存修改",
    "save-more": "保存更多",
    "search-booking": "搜素訂單",
    "segmentation-group": "團詳情",
    "select-date": "選擇日期",
    "select-trip": "選擇行程",
    "selected-departure-trip": "已選擇離開行程",
    search: "搜索",
    "search-car-transfer": "搜索車接",
    "search-flights": "搜索機票",
    "search-hotels": "搜索酒店",
    "search-package": "搜索機+酒",
    "search-perfect-vehicle": "搜索更符合的車輛",
    "search-tour": "搜索門票",
    "search-vehicles": "搜索車輛",
    "second-trip": "第二行程",
    "second-trip-description": "第二行程描述",
    select: "選擇",
    "send-email": "發送郵件",
    session: "時間段",
    "ship-name": "船名",
    "shuttle-info": "航班信息",
    "sign-in": "登入",
    "sign-out": "退出",
    "sign-up": "註冊",
    "similar-hotel": "類似酒店",
    star: "星級",
    "star-rating": "星級",
    suitcase: "手提箱",
    "special-request": "特殊請求",
    "start-date": "開始日期",
    "street-#156-burbank,-studio-city-hollywood,-california-usa":
      "Street #156 Burbank, Studio City Hollywood, California USA",
    subscribe: "訂閱",
    "subscribe-to-our-newsletter": "訂閱我們的新聞稿",
    "suggestions-for-you": "建議",
    "supplier-max-waiting-time": "供應商最大等待時間",
    taxes: "稅費",
    "taxes-and-fees": "稅費和費用",
    "taxes-total": "總稅費",
    "telephone-number": "電話號碼",
    "terms-of-use": "使用條款",
    "thank-you": "謝謝",
    the: "第",
    "things-to-do": "門票",
    "things-to-do-date": "門票日期",
    "ticket-of-adult(s)": "成人票",
    "ticket-of-child(ren)": "兒童票",
    "ticket-number": "票號",
    "ticket(s)-and-price": "門票與費用",
    tickets: "門票",
    title: "稱謂",
    "ticket(s)": "票券",
    to: "到",
    "top-destination": "熱門地點",
    total: "總共",
    "transit(s)": "中轉",
    transmission: "運輸",
    "travle-hassel-with-a-companies-recognized-for-professional-services":
      "Travle hassel with a companies recognized for professional services",
    "trip-way": "行程",
    "total-pay": "總共支付",
    "total-payment-to-be-made": "需要支付的總額",
    "tour-group-name": "旅遊團名",
    "tour-info": "旅遊信息",
    "tour-name": "旅遊名",
    tours: "旅遊",
    "train-arrival-time": "火車到達時間",
    "train-departure-time": "火車離開時間",
    "train-number": "火車號",
    update: "更新",
    "useful-links": "有用的鏈接",
    username: "用戶名",
    "user-rating": "用戶評分",
    "vehicle-name": "車名",
    "vehicle-type": "車類型",
    "view-details": "查看詳情",
    "we-accept": "我們接受",
    welcome: "歡迎",
    "why-choose-us?": "為什麼選擇我們",
    "will-incur-a": "將會導致",
    with: "有",
    "with-advanced-technology-and-superior-customer-services,":
      "With advanced technology and superior customer services,",
    "with-air-conditioning": "空調",
    without: "沒有",
    "wild-africa": "Wild Africa",
    "wonderful-lodnon": "Wonderful Lodnon",
    "years-old": "歲",
    "you-need-to-pay": "你需要支付",
    "you-pay": "支付",
    youth: "年輕人",
    "your-selection": "你選擇了",
    "your-booking-is-confirmed": "你的訂單已經確認",
    "card-code": "Card Code",
    "card-number": "Card Number",

    "available-credit": "剩餘金額",
    "under-17": "17歲以下",
    "please-enter-a-valid-firstname": "請輸入一個有效的名字",
    "select-continue": "選擇並繼續",
    "how-many-rooms-need": "您需要多少個房間？",
    "order-type": "order類型",
    "series-code": "Series Code",
    "service-name": "服務名稱",
    checkin: "報到",
    "hotel-price": "飯店價格",
    "child-less-than": "2 - 12 歲以下",
    "show-more-room-rate": "顯示更多 房價",
    "back-to-dinning": "返回",
    "who-is-traveling": "誰在旅行？",
    "applicant-personal-details": "申請人#{index}個人詳細信息",
    "cancellation-policy-nights-caution":
      "在 {beginDate} 之前取消您的預訂，您將獲得全額退款。之後，我們將向您收取 {nmbrOfUnit} 晚的住宿費用以及稅金。時間以飯店當地時間為準。",
    "special-requests-optional": "特殊要求（可選）",
    excellent: "出色的",
    "number-of-stops": "停靠站數量",
    "confirm-your-choices": "確認您的選擇",
    pool: "水池",
    "how-many-guests-each-room": "每間客房將容納多少位客人？",
    condo: "公寓",
    "passport-information": "護照資訊",
    economy: "經濟",
    "our-partners": "我們的夥伴",

    "available-dates": "可用日期",
    "search-by-destination": "按目的地搜尋",
    nationality: "國籍",
    "error-500-message": "很抱歉，我們的伺服器遇到了內部錯誤",
    unticketed: "無票",
    "flight-list": "航班清單",
    "meal-plans-available": "提供膳食計劃",
    "passenger-info": "乘客信息",
    aboard: "船上",

    "next-passenger": "下一位乘客",

    "bag-allow": "允許攜帶行李",

    "error-503-message": "很抱歉，我們的服務目前不可用",
    flight: "航班",
    "pick-return-location": "接送地點",
    Visa: "簽證",
    "flight-price": "機票價格",
    "property-type": "財產種類",
    "info-source": "供應商：{supplierCode}",
    "number-of-entries": "參賽作品數量",

    "traveler-name": "旅客姓名",
    "hotel-location": "飯店位置",
    "type-of-vehicle": "什麼類型的車輛？",

    "expect-to-wait": "期待等待",
    "departure-to-return": "出發返回",

    close: "關閉",
    "price-summary": "價格匯總",

    "number-of-nights": "入住晚數",
    "confirm-booking": "確認預訂",

    business: "商務艙",
    "price-per-applicant": "每位申請人的價格",
    "operation-uppercase": "操作",
    "select-weight-of-baggage": "選擇您托運行李的重量",
    "view-terms": "查看條款",
    "apart-hotel": "公寓式飯店",
    "any-uppercase": "任何",
    "guest-rating": "賓客評價",
    "agency-portal": "機構入口網站",
    "your-trip-details": "您的旅行詳情",
    "view-your-reservation": "查看您的reservation",

    "choose-your-category-location-room": "選擇您的{categoryLocation}房間",

    "finding-driver": "尋找您的司機",
    chinese: "中國人",
    "charge-type": "收費類型",
    "check-out-your-choices": "查看您的選擇",

    "your-personal-details": "你個人詳細資料",
    airlines: "航空",
    radius: "半徑",
    info: "資訊",
    "please-enter-your-username": "請輸入您的用戶名",
    "apply-now-for-document": "立即申請{toCountryName}旅行證件",
    journey: "旅行",
    "passenger-room-class": "旅客、房間、艙位",
    "room-price-total": "房價總計",
    "trip-total": "行程總計",

    "add-check-in-baggage": "添加托運行李",
    "room-index": "房間 - 索引",
    "max-stay": "最多停留時間",
    "fare-family": "票價系列",
    "agree-to-terms-conditions": "我同意條款和條件狀況",
    "all-reservations": "所有 reservations",
    "infant-price": "嬰兒價格",
    "booking-in-process": "預訂中",
    "price-info": "價格資訊",
    "please-enter-a-valid-username": "請輸入有效的使用者名稱",
    "night-number-high-to-low": "住宿晚數從高到低",
    reservations: "Reservations",
    "child-index-age": "孩子 {childIndex} 年齡",
    "num-hotel": "  找到 {length} 個酒店",
    "radius-search": "半徑搜尋",

    "required-for-travel": "旅行所需",
    unconfirmed: "未確認",
    "please-contact-email": "請聯絡信箱",
    "please-enter-a-valid-password": "請輸入有效密碼",
    "rooms-can-accommodate": "客房最多可容納 4 位客人",
    "rail-pass-review": "鐵路通票審查",
    origin: "起源",
    back: "後退",
    "night-number": "{number} 晚上",
    "continue-to-payment": "繼續付款",

    "select-your-seat": "選擇您的座位",
    "please-enter-a-valid-lastname": "請輸入有效的姓氏",
    remove: "消除",

    "sign-in-now": "立即登入",
    "go-beyond-your-imagination": "超越你的想像",
    company: "公司",
    "hotel-item": "飯店專案",

    "by-credit-card": "用信用卡",
    "rail-ticket-review": "火車票評論",
    "call-us": "如需任何協助請致電我們",
    "expire-date": "到期日期",

    "sign-in-your-account": "登入您的帳戶",
    "lower-available-rate": "較低的可用率",
    "journey-date": "旅程日期",
    "see-details": "查看具體資訊",
    "guests-uppercase": "嘉賓",

    "cruise-home": "遊輪之家",

    "flight-class": "座位艙型",
    "more-photos": "更多照片",
    "advance-question": "提前提問",
    "i-agree": "我同意",
    "insurance-home": "保險之家",
    "num-night-stay": "{num} 晚住宿",

    "two-less-than": "2 - 12 歲以下",
    "flight-date": "航班日期",

    configurations: "配置",
    discount: "折扣",
    "log-out": "登出",
    "fare-rules": "票價規則",
    "total-amount": "總金額",
    "birth-date": "出生日期",
    "error-401-message": "很抱歉，您無權訪問此頁面",
    supplier: "供應商",
    "for-all-applicants": "對於所有申請人",
    "per-entry": "每個條目",
    "reactive-your-account": "反應您的帳戶",

    "reservation-id": "reservation 編號",
    order: "Order",
    "your-application": "你的申請",

    "baggage-option": "行李選項",

    "drivers-can-wait": "司機最多可以等待",
    "each-passenger-can-select": "每位乘客可以選擇一個選項。",
    index: "指數",

    "residency-information": "居住資訊",
    "frequent-answer-and-question": "常見的回答和問題",
    "ticketing-success": "票務成功",
    "cruise-itinerary": "郵輪行程",

    "search-orders": "搜尋orders",
    "airport-shuttle-included": "包括機場接駁車",
    "room-name-index": "房間 {index} { roomTypeName }",
    status: "地位",
    "today-price": "今日價格",
    "get-latest-news-offers": "獲取最新消息和優惠",
    "price-starts-from": "{currency} {amount}價格從",

    extras: "附加功能",
    "my-profile": "我的履歷",
    "hostel-backpacker": "旅館/背包客旅館",
    holiday: "假期",
    "back-to-results": "回傳結果",

    "night-number-low-to-high": "住宿晚數從低到高",
    "common-area": "公共區域",
    "please-enter-a-valid-phone-number": "請輸入一個有效的電話號碼",
    surname: "姓",
    "your-booking-detail": "您的預訂詳細資料已發送至：{ emailAddress }",
    "room-uppercase": "房間",
    "stopover-in": "在#{arrivalAirportName}中途停留#{arrivalDateTime}",

    "cancellation-policy-refundable-caution":
      "在 {beginDate} 之前取消您的預訂，您將獲得全額退款。之後，我們將向您收取 {currency} {amount}。時間以飯店當地時間為準。",
    "sub-total": "小計",
    "select-the-child-age": "請選擇孩子的年齡！",
    "base-fare": "基本做事",
    "basic-rate": "基本費率",
    "guest-total": "乘客 { cabPriceIndex } 费用 總計",
    "create-by": "創建者",
    "contact-with-us": "與我們聯繫",
    "num-passenger": "{passengersNum} 乘客",
    "no-baggage-option-at-the-segment": "該航段無法選擇行李",
    "no-seat-segment": "該航段無法選擇座位",
    "copy-right": "版權所有 © 2023 保留所有權利",
    "processing-time-fee": "處理時間和費用",
    "travel-document-book": "旅行證件簿",
    "protections-extras": "保護和附加功能",
    "subscribe-our-newsletter": "訂閱我們的新聞",
    "total-uppercase": "全部的",
    "starting-from": "從...開始",
    "start-to-end": "開始日期 - 結束日期",
    "enter-the-email": "請輸入與您的帳戶結合的電子郵件",
    "enter-valid-credential": "請輸入有效的憑證",
    "find-available-cruises": "尋找可用的遊輪",
    "travel-document-option": "旅行證件選項",
    "applying-for": "正在申請",

    "select-your-type-of-inside": "選擇您的內部類型",
    traveler: "遊客",
    "double-room": "雙人間",

    "ports-of-call": "停靠港",
    "delivered-at": "交付於",
    "cabin-price": "艙位價格",
    "special-requests": "特別要求",
    "pay-now": "Pay Now",
    "pax-num": "和平號",
    "discover-your-ideal": "與我們一起探索您的理想體驗",
    "stateroom-amenities": "客艙設施",
    "submit-ticket-order": "提交orders",
    stopover: "中途停留",
    "expiry-date": "到期日",
    "pax-type": "乘客類型",
    "after-issued": "發出後",
    stock: "庫存",
    "my-reservations": "我的Reservations",

    "pickup-and-return": "取貨及歸還",
    "room-name": "房間名稱",
    "show-less": "顯示較少",
    "price-low-to-high": "價格：從低到高",

    "meet-your-driver": " 您的預訂確認後，我們將發送與司機會面的逐步說明。",
    spa: "溫泉",
    "select-your-room": "選擇您的房間",
    "add-another-person": "增加另一個人",
    "fill-following-details": "請填寫以下詳細資訊以建立新帳戶。",
    "logged-in-to-stay-in-touch": "已登入以保持聯繫",
    "itinerary-review": "行程回顧",
    "children-ages": "0至17歲兒童",
    "living-country": "居住的國家",
    "sort-by": "排序方式",
    "activities-list": "活動清單",
    "already-have-an-account": "已經有帳戶？",
    position: "位置",
    basic: "基本的",

    "breakfast-included": "含早餐",
    "same-return-location": "回程地點相同",
    "cabin-type": "艙型",
    exterior: "外部的",
    policies: "政策",

    "reservation-summary": "Reservation摘要",
    "register-your-account": "註冊您的帳戶",
    "search-for-an-activities": "搜尋活動",
    "where-am-i-from": "我從哪裡來？",

    "error-400-message":
      "您發出了錯誤的請求，或者請求已損壞並且伺服器無法理解它",
    "guest-name": "客人姓名",
    "add-another-flight": "增加另一個航班",
    "are-you-sure-ticketing": "您確定要出票嗎？",
    reserve: "預訂",
    "you-need-visa-travel":
      "如果您持有 {fromCountryName} 的護照，則需要簽證才能前往 {toCountryName}",
    "check-in-to-check-out": "入住到退房",
    "remove-person": "刪除人員",

    "stay-in-touch": "保持聯繫",
    "return-time": "返回時間",
    "i-live-in": "我住在",
    or: "或者",
    "price-uppercase": "價格",
    "children-uppercase": "孩子們",
    "booking-success": "預訂成功！",
    "arrival-date": "到達日期",

    "searching-result": "搜尋結果",
    "order-list": "orders列表",
    any: "任何",
    "contact-information": "聯絡資訊",
    "edit-seat": "選擇座位",
    "booking-preview": "預訂預覽",
    application: "應用",
    "Avg-person": "平均/人",
    activities: "活動",
    "hotel-detail": "飯店詳情",
    "confirmation-number": "確認號碼：",
    properties: "条",

    "export-to-sabre": "匯出至 Sabre",
    "info-uppercase": "資訊",
    "name-title": "名稱",
    prev: "上一篇",
    "sailings-uppercase": "航行",
    "all-inclusive": "全包",
    "remember-me": "記住帳號",
    ship: "船",
    "rail-home": "鐵路之家",
    "select-fares": "選擇票價",

    "hotel-home": "飯店之家",
    "send-mail": "傳送郵件",
    "very-good-4.0+": "非常好4.0+",
    "send-message": "傳訊息",
    "create-date": "建立日期",
    "country-of-residence": "居住國家*",
    PNR: "訂座記錄",
    english: "英語",
    "choose-your-room": "選擇您的房間",
    "view-dates": "查看 {number} 個日期",

    requester: "請求者",
    sleeps: "人數",

    "pick-your-add-ons": "選擇您的附加服務",
    "please-enter-a-valid-email": "請輸入有效的電子郵件地址",
    "booking-confirm": "預訂確認",
    "rate-description": "費率說明",
    "accessorial-service": "配套服務",
    "welcome-are-you-going": "你要去哪裡",
    "print-invoice": "列印發票",
    stops: "停靠站",
    "number-stops": "{stopNumber} 停靠站",
    "travel-document": "旅行證件",
    "guest-rating-our-picks": "賓客評分 + 我們的精選",
    "rail-search-list": "鐵路搜尋列表",
    "air-item": "航空項目",
    "includes-taxes-fees": "含稅費",
    cabin: "艙",
    "passenger-class": "乘客，艙位",
    "order-status": "order狀態",
    "pick-up": "撿起",
    "please-enter-a-username": "請填入一個用戶名",
    "select-segments": "選擇細分",
    "wonderful-4.5+": "精彩4.5+",
    "taxes-fees-and-port-expenses": "稅金、費用和港口費用",
    "lessons-hours": "10 堂課 · 3 小時",
    "total-include-amount": "總計 {currency} {amount}",
    "departure-port": "出發港",
    "quote-type": "報價類型",
    currency: "貨幣",
    "ticketed-uppercase": "已出票",
    departs: "出發",
    "cancellation-policy": "取消政策",
    protections: "保護措施",
    "get-order-prices": "取得order價格",
    "order-id": "order編號",
    "adult-price": "成人價",
    "holiday-customise": "假期客製",
    people: "人們",
    "forgot-password": "忘記密碼",
    "total-due": "應付總額",
    confirm: "確認",
    "explore-this-itinerary": "探索此行程",
    "traveler-info": "旅客資訊",
    "learn-html5": "透過 10 個簡單易懂的步驟學習 HTML5",
    marketing: "行銷",
    "booking-date-range": "預訂日期範圍",
    "package-flight-hotel": "套票(機票+飯店)",
    "good-3.5+": "好3.5+",
    "given-name": "給定的名稱",

    "ticketing-confirmed": "您的票務 #{ TicketId } 已確認",
    "visiting-uppercase": "參觀",
    "desk-name": "辦公桌名稱",
    "explore-our-hot-deals": "探索我們的熱門優惠",
    insurance: "保險",
    "expiry-time": "到期時間",
    "car-rental-home": "租車之家",
    "lunch-included": "含午餐",
    "register-now": "現在註冊",

    "report-it": "檢舉",
    "less-than-two": "不到2年",
    "next-flight": "下一班航班",
    "confirmed-uppercase": "確認的",
    luggage: "行李",
    "price-high-to-low": "價格：從高到低",

    "read-and-accept-conditions": "我已閱讀並接受所有條款和條件",
    "total-price-range": "總價格範圍",
    "general-question": "一般問題",
    "arr-time": "到達。時間",
    "back-to-home": "回到家",
    "see-remark": "見備註",

    "send-links": "發送連結",

    "your-vehicle": "您的車輛",
    "room-prices-details": "房價詳情",
    "welcome-please-login": "歡迎您，請登入！",
    "valid-for": "有效期限為",
    "dept-time": "部門時間",
    "no-room-type-name": "無房型名稱",
    support: "支援",
    "continue-to-confirm": "繼續確認",
    next: "下一個",
    "car-rental-list": "租車清單",
    submit: "提交",
    "booking-confirmed": "您的預訂 #{ bookingId } 已確認",
    "please-enter-your-password": "請輸入您的密碼",
    "num-flight": " 找到 {length} 個航班",
    "get-price": "取得價格",
    "multi-city": "多城市",
    "passenger-type": "旅客類型",
    "select-status": "選擇狀態",
    "measurement-infos": "測量資訊",
    "cancellation-policy-nonrefundable-caution":
      "在 {beginDate} 之前取消您的預訂，您將獲得全額退款。之後，您將被收取全部費用。時間以飯店當地時間為準。",
    "get-comfy-during-journey":
      " 旅途中享受舒適！無論您想要安靜的飛行還是只是想增加一點腿部空間，選擇完全取決於您！",
    "your-choices": "您的選擇",
    refundable: "可退款",
    "check-email-info":
      "您可以查看您的電子郵件以獲取更多詳細資訊。我們已向您發送了一封包含詳細資訊的郵件。",
    "including-taxes": "含稅",
    checkout: "查看",
    "price-total": "{ currency } {price} 總計",
    "provide-your-account":
      "請提供您帳戶的電子郵件或使用者名，我們將向您發送您的密碼。",
    "would-you-like-to-us-know": "您願意讓我們知道嗎？",
    previous: "以前的",
    "traveling-for": "旅行的目的",
    "receipt-status": "收據狀態",
    "view-fare-rules": "查看票價規則",
    start: "開始",
    "taxes-fees-port-expenses": "稅金、費用和港口費用",
    "adults-uppercase": "成年人",
    "booking-amount": "預訂金額",
    "hotel-search": "飯店搜尋",
    "see-fare-details": " 查看票價詳情",
    "family-name": "姓",
    "passport-expiry-date": "護照效期為出發或返回後 6 個月內",
    "get-free-quote": "取得免費報價",
    "vans-trucks": "貨車和卡車",
    "num-trip-advisor": "{num}/5 貓頭鷹",
    "we-choose": "我們選擇",
    type: "類型",
    "select-your-seats": " 選擇您的座位",
    "select-passengers": "選擇乘客",
    "dinner-included": "含晚餐",
    logout: "登出",
    children: "孩子們",
    "sure-export-booking": "您確定要匯出預訂#{ bookingId }",
    "standard-days": "標準 - {num} 天",
    connection: "聯繫",
    "all-photos": "所有照片",
    dashboard: "儀表板",
    "taxes-fees": "稅費",
    "error-404-message": "很抱歉，找不到您要找的頁面",
    "expire-time-min-s": "{ minutes } 分 {seconds} 秒後過期",
    "created-date-range": "建立日期範圍",
    "please-enter-hotel-location": "請輸入飯店位置",
    "where-am-i-going": "我要去哪裡",
    "more-details": "更多細節",
    "payment-method": "付款方式",
    "child-price": "兒童價",
    "confirmation-number-with-supplier": "確認號({supplierName})",
    "pickup-and-return-time": "接載地點及時間",
    "minimum-driving-age-years": "最低駕駛年齡：{minimumDriverAge}歲",
    "cruise-dates": "郵輪日期",
    "your-passport-information": "您的護照資料",
    "status-uppercase": "地位",
    "pick-up-time": "接載時間",
    "reservation-list": "reservation清單",
    "go-to-home": "回家",
    "select-your-room-category": "選擇您的房間類別",
    "remove-room": "移除房間",
    "hotel-amenities": "飯店設施",
    "travel-document-home": "旅行證件首頁",
    "inbox-menu": "收件匣選單",
    "leave-us-a-message": "給我們留言",
    "filter-by-price": "按價格過濾",
    tourism: "旅遊",
    "room-types-rate-comments": "評價評論 - { roomTypes } - {roomIndex}",
    confirmed: "確認的",
    "airline-RLOC": "航空公司 RLOC",
    vehicle: "車輛",
    "ferries-and-buses": "渡輪和巴士",
    "reservations-found": "條reservations",
    "add-ons-pick": "附加服務選擇",
    "package-home": "套餐首頁",
    "fare-basic": "基本票價",
    "invoice-number": "發票號碼：",
    "number-of-connections": "連接數",
    "must-agree-service-terms": "您必須同意服務條款！",
    "fare-basis": "票價基礎",
    "frequent-flyer-airline": "飛行常客航空公司",
    "see-all-properties": "查看所有屬性",
    "create-uppercase": "創造",
    "need-help": "需要幫助嗎？",
    "cancel-reservation": "取消預訂",
    date: "日期",
    booking: "預訂",
    "reset-your-password": "重置你的密碼",
    "seat-unavailable": "沒有座位",
    "extra-service-total": "額外服務總計",
    "departing-from": "出發地",
    "add-another-room": "增加另一個房間",
    "regular-question": "常規問題",
    "holiday-home": "渡假村",
    "night-stay": "過夜",
    "name-submitted-successfully":
      "{surname}/{givenName}，您的order已成功提交！",
    "taxes-include-total": "稅費（包含在總額中）",
    sales: "銷售量",
    "baggage-allowance": "行李限額",
    "licenses-fees": "許可證和費用",
    "please-confirm the password": "請確認密碼",
    "flexibility-your-own-flying-experience":
      "我們將為您帶來全新的預訂體驗，讓您擁有一切靈活訂製屬於您自己的飛行體驗",
    "trip-advisor": "旅行顧問",
    discounts: "折扣",
    create: "創造",
    "follow-us": "跟著我們",
    "for-room": "適合 { roomNum } 房間",
    "infant-less-than": "不到2年",
    "edit-meal": "編輯膳食",
    "include-in-total-amount": "包含在總金額中",
    "multi-way": "多路",
    minutes: "分分鐘",
    rail: "軌",
    "search-for-the-best-rooms-available":
      "我們將搜尋所選類別中可用的最佳房間。客房最多可容納 4 位客人",
    tax: "稅",
    done: "完畢",
    travelers: "旅行者",
    "cabin-number": "艙位號碼",
    "trip-total-price": "行程總價",
    "show-details": "顯示詳細資料",
    "who-we": "我們是誰",
    "apply-now": "現在申請",
    "cruise-search-results": "郵輪搜尋結果",
    "dont-have-a-account": "沒有帳戶",
    "register-account": "註冊帳戶",
    error: "錯誤",
    "sure-cancel-booking": "您確定要取消預訂嗎#{ bookingId }",
    "please-provide-a-password": "請提供密碼",
    "protection-plans": "保護計劃",
    "please-enter-your-departure-point": "請輸入您的出發地點",
    "load-more": "裝載更多",
    "available-extras": "可用的附加功能",
    "edit-baggage": "選擇行李",
    "frequent-flyer-airline-number": "飛行常客航空公司號碼",
    "see-fare-basis": "查看票價基礎",
    amount: "數量",
    "go-back-to-dashboard": "返回儀表板",
    "room-price": "房價",
    "passport-requirements": "護照要求",
    "search-result": "搜尋結果",
    "start-now": "現在開始",
    "error-403-message": "很抱歉，您沒有訪問此頁面的權限",
    "from-to": "從{from}到{to}",
    "show-more": "展示更多",
    "created-date": "建立日期",
    recommended: "受到推崇的",
    "mail-us": "郵寄給我們的支援團隊",
    "please-enter-your-arrival-point": "請輸入您的到達地點",
    "third-party-liability": "第三方責任",
    reselect: "重新選擇",
    "flight-home": "飛行回家",
    "going-to": "即將",
    "processing-speed": "處理速度（尚未選擇）",
    "prev-passenger": "上一位乘客",
    front: "正面",
    "passengers-summary": "{adult} 成人 , {child} 儿童 , {infant} 婴儿 ",
    "cruise-package-code": "遊輪套餐代碼",
    deposit: "押金",
    "deposit-in": "押金 {index} ( {key} )",
    "trip-total-in": "總價格( {key} )",
    "guest-info": "訪客信息",

    "side-overlay-content": "側面疊加內容..",
    carts: "購物車",
    reports: "報表",
    "current-language": "當前語言",
    "all-languages": "所有語言",
    "filter-by": "過濾條件",
    "basic-search": "基礎搜索",
    "passengers-room-summary":
      "{passengersNum} 乘客 ,{roomCount} 房間 ,{cabinName} ",
    "hotel-shopping": "酒店预订",
    "travel-room-num": "{travelersNumber} 乘客 , {roomCount}  房間",
    "who-checking": "登記人員",
    "cancellation-policies": "退款政策",
    "daily-prices": "價格詳情",
    "cruise-shopping": "遊輪預定",
    "booking-progress": "預定",
    guests: "乘客",
    "select-dining-preference": "選擇您的用餐偏好",
    "cruise-dinning":
      "2019年2月，名人峰會參加了名人船隊革命。餐廳和休息室不僅僅是餐廳和消磨時間的地方。貴賓室變成了遠離家鄉的家和客人的私人避難所。名人峰會不再只是一艘船，而是一種更好的體驗世界的方式。",
    carRental: "租车",
    "car-rental-search": "租車搜索",
    seats: "座",
    "car-transfer-home": "車接首頁",
    infants: "嬰兒",
    childs: "兒童",
    "adult-child-infant":
      "{adultNum}  成人, {childNum} 小孩 , {infantNum}  嬰兒",
    "add-another": "增加",
    "your-journey": "您的旅程",
    "travel-document-apply": "旅遊證件申請",
    validity: "有效期",
    review: "檢查",
    documents: "證件",
    applicant: "申請人",
    gender: "性別",
    "personal-details": "個人信息",
    "package-search": "套餐搜索",
    "hotel-result": "酒店結果",
    "minimum-price": "輸入最低價格",
    "maximum-price": "輸入最高價格",
    "contact-info": "聯係信息",
    "no-matched-location": "! 沒有匹配項 {location}",
    "see-more-description": "查看更多信息",
    "activities-home": "門票·活動首頁",
    "per-application": "每次申請",
    "personal-info": "個人信息",
    "end-date": "截止日期",
    "cancel-at": "取消時間: ",
    "activity-duration": "活動時長: ",
    "check-availability": "價格選擇",
    "about-this-activity": "活動相關",
    "youth-child": "嬰幼兒/兒童",
    senior: "老年人",
    per: "每人",
    "start-at": "開始於",
    "question-description": "問題描述",
    "necessary-info": "必要信息",
    group: "組",
    "please-select-your-trip":
      "請選擇你 <span class='text-primary'>{tripType}</span> 旅程",
    operating: "操作",
    "deadline-for-reservations": "預定的截止日期是{dateTime}",
    "terms-and-conditions": "條款和協議",
    "departing-station": "出發站點",
    "arriving-station": "到達站點",
    provider: "供應商",
    "ticket-details": "車票詳情",
    "continue-to-passengers": "填寫乘客信息",
    "pass-option": "月票選項",
    "select-options": "選擇",
    "valid-from-date": "額度從 {date} 開始啟用",
    "comfort-category": "舒適類型",
    "pass-review": "月票詳情預覽",
    "duration-near-to-far": "時長：短-長",
    "duration-far-to-near": "時長：長-短",
    "fare-before-commission": "計算傭金前票價",
    "total-after-commission": "計算傭金後合計",
    "rail-passenger": "火車乘客",
    "passenger-types": "乘客類型",
    "rail-review": "火車預覽",
    "selling-price": "銷售價格",
    inbound: "回程",
    outbound: "去程",
    "first-travel-date": "首次旅行日期",
    "age-range": "年齡范圍 : {range}",
    "name-appear-as-passport": "您的名字必須和護照上一樣",
    "choose-to-travel": "選擇您的旅行",
    "traveler-day-within":
      "旅行日可以在第一次旅行日期之內{within}的任何時間，如您的通行證上所寫。",
    pass: "月票",
    "years-old-over": "{age} 歲及以上",
    "age-from-to": "{fromAge} 到 {toAge} 歲",
    "select-a-validity": "請選擇有效期!",
    "ticket-review": "火車票預覽",
    "country-to-visit": "去往的城市",
    ancillary: "附加",
    "enter-a-email-address": "輸入您的郵箱",
    "enter-your-phone-number": "輸入您的電話",
    "enter-your-passport": "輸入您的護照",
    "frequent-flyer-airline-number-option": "常旅客航空公司編號（可選）",
    "are-you-sure": "您確定?",
    "you-will-confirm-the-trip-now": "您先確認行程，稍後付款",
    "you-will-pay-now": "您現在就付款並確認行程",
    warning: "警告",
    "booking-details": "booking詳情",
    ticketing: "出票",
    "where-are-you-from": "您來自哪裡?",
    "properties-num": "找到 {num} 條結果",
    "when-is-your-arrival-date": "到達時間?",
    "when-is-your-departure-date": "出發時間?",
    "back-to-room": "重選房間",
    "review-pay": "繼續",
    "tours-and-activities": "門票·活動",
    "search-activities": "搜索門票",
    "per-adult": "每位成人",
    visiting: "遊覽地點",
    "postal-code": "郵政編碼",
    "enter-your-answer": "填寫你的回答",
    timeErr: "到達時間不能比出發時間早",
    "you-will-cancel-reservation": "您將取消reservation #{id}",
    "reservation-result": "Reservation結果",
    "order-details": "order詳情",
    "password-reminder": "密碼提示",
    "create-account": "創建賬號",
    "select-your-extras": "選擇您的額外服務",
    "rail-search": "火車搜索",
    "who-is-checking-in": "誰在辦理?",
    "credit-card-info": "信用卡信息",
    "ticket-conditions": "車票條件",
    "taxes-fees-included": "含稅費用",
    "early-morning-am": "凌晨 (12:00am - 4:59am)",
    "morning-am": "早上 (5:00am - 11:59am)",
    "after-pm": "下午 (12:00pm - 5:59pm)",
    "evening-pm": "晚上 (6:00pm - 11:59pm)",
    non: "無",
    stop: "站",
    "non-stop": "無停靠站",
    "choose-frequent-flyer-airline-option": "選擇常旅客航空公司(選項)",
    mr: "先生.",
    ms: "女士.",
    mrs: "太太.",
    dr: "博士.",
    prof: "教授.",
    mister: "先生",
    miss: "小姐",
    "no-additional-baggage": "無額外行李",
    "no-additional-meal": "無額外餐饮",
    segment: "航段",
    "not-issued": "未出票",
    "in-process": "進行中",
    "issued-uppercase": "已出票",
    "successful-ticketing": "出票正在处理中，状态将会在几分钟后更新",
    "ticket-issue-failed": "出票失敗！",
    success: "成功",
    oops: "糟糕...",
    "select-your-transmitting-system": "選擇您的出票系統",
    "source-type": "系統類型 : {sourceType}",
    "get-a-quote": "獲取報價",
    "select-your-plan": "選擇您的保險類型",
    "special-features": "特色服务",
    "with-starr-travelead":
      "有了Starr TraveLead，我们可以放心地让您享受无忧的旅行!一键购买无忧!",
    "insurance-special-features-one": "医疗费用不超过港币1,000,000元",
    "insurance-special-features-two": "承保即时取消行程",
    "insurance-special-features-three": "包括意外遗失或损坏手机",
    "single-trip-travel-plan": "单程旅行保险类型",
    "essential-insurance": "标准计划",
    "extra-insurance": "非凡计划",
    "supreme-insurance": "尊贵计划",
    "apply-insurance": "投保",
    coverage: "承保範圍",
    "sum-insured": "最高賠償額",
    "room-amenities": "房間設施",
    "hotel-remarks": "250個字以内",
    "synchronize-command": "同步命令",
    "synchronized-success": "同步成功",
    "manage-this-order": "管理此訂單",
    "change-flight": "修改機票",
    "cancellation-quote": "取消機票",
    "add-seats": "添加座位",
    "add-bags": "添加行李",
    "name-correction": "姓名更正",
    "other-requests": "其他要求",
    "change-order": "修改 Order {orderName}",
    "premium-economy": "高級經濟艙",
    "first-cabin": "頭等艙",
    "business-cabin": "商務艙",
    "search-available-flight": "搜索可選航班",
    "available-flight": "可選航班",
    "changes-summary": "航班變動詳情",
    "new-flight-details": "原航班詳情",
    "payment-details": "支付詳情",
    "before-change-amount": "原航班金額",
    "after-change-amount": "修改後航班金額",
    "cost-of-change": "更改後金額",
    "confirm-and-pay-with-balance": "確認後支付",
    "change-flight-confirm": "這是收費的！請注意未來更改此訂單將需要支持協助。",
    "confirm-cancel-of":
      "現在取消航班將退給你{currency} {amount}，您是否確認取消 ?",
    "penalty-amount-notification":
      "現在取消訂單，將收取您{currency} {amount}的訂單，您是否確認取消?",
    "will-not-be-refundable": "現在取消航班將不可退款，您是否確認取消 ?",
    "confirm-payment-of": "確認並支付 {currency} {amount}",
    "departure-from": "出發：",
    "arrive-at": "到達：",
    "flight-cancellation-successful": "取消航班成功",
    "flight-cancellation-failure": "取消航班失敗",
    "order-change-departure-time": "出發時間(以當地時間為準)",
    version: "版本",
    "flight-version-price": "第 {index} 次修改費用 ",
    "alteration-cost": "修改費用",
    "flight-total-price": "機票總價",
    "flight-fare-price": "稅費總價",
    "flight-alteration-price": "修修改費用總價",
    other: "其它",
    "sleep-num": "{min} - {max} 人",
    "supplier-terms-conditions": "供應商條款和條件",
    "eps-terms-and-conditions": "EPS's 條款和條件",
    "please-check-first": "請先勾選！",
    "important-information": "重要信息",
    "add-addition": "添加附加服務",
    "additional-service-selected": "已選附加服務",
    baggage: "行李",
    "baggage-price": "托運價格",
    "service-type": "服務名",
    time: "時間",
    change: "修改",
    add: "添加",
    "select-check-in-baggage": "選擇托運行李",
    "select-meal": "選擇餐飲",
    seat: "座位",
    "you-will-cancel-this-additional-service": "您將取消該項附加服務",
    "successful-cancel": "取消成功",
    "optional-additional-services": "可選附加服務",
    "add-baggage": "添加行李",
    "add-seat": "添加座位",
    "add-meal": "添加餐飲",
    "optional-baggage-service": "可選擇的與行李相關的服務",
    "optional-seat-service": "可選擇的座位",
    "your-choice": "您的選擇",
    "buy-now-and-save-at-least": "現在購買，至少節省30%!",
    "segment-info": "航段信息",
    "manage-this-reservation": "管理此reservation",
    "change-reservation": "修改reservation {orderName}",
    "new-ticket-fee": "新機票價格",
    "sure-about-the-modification": "您確定修改嗎?",
    "baggage-option-in-the-flight": "航班上的行李選擇",
    "advance-search": "高級搜索",
    "is-direct": "是否直達",
    "departure-level": "首選",
    "first-class": "頭等艙",
    "business-class": "商務艙",
    include: "包含",
    exclude: "排除",
    "pick-some": "多選",
    "please-select-cabin-class": "請選擇艙位等級",
    "passengers-summary-and-cabin": " {passengersNum} 乘客,{cabinName} ",
    "promotion-code": "優惠碼",
    yes: "是",
    no: "否",
    "language-code": "語言代碼",
    "airfield-radius": "机场半径",
    "required-attributes": "必要屬性",
    "fare-basis-codes": "票價基准代碼",
    "metro-code": "城市代碼",
    "loyalty-num": "忠实乘客人数",
    "max-num": "最多可选择 {num} 人",
    "loyalty-passengers-summary":
      "{忠实乘客 : {adult} 成人 , {child} 儿童 , {infant} 婴儿 }",
    "tour-code": "行程代码",
    "account-code": "账户代码",
    "please-select-vendor": "请选择供应商",
    "air-equip-type": "飛機设备类型",
    "fare-type": "票价种类",
    account: "账户",
    "choose-frequent-flyer-airline": "選擇常旅客航空公司",
    preferred: "偏好",
    unacceptable: "排除",
    only: "仅包含",
    "tax-and-fees-description":
      "“此费用包括旅行服务提供商（即酒店、租车公司）和/或我们在您的预订中向税务部门所付税款的估计金额（包括但不限于销售稅、住房稅和增值税额）。此金额还可能包括被收取：的度假费、清洁费和其他费用，以及/或者我们、酒店供应商和/或您的预订网站所收取留存服务费作为为您提供服务的额外补偿,该费用因位置、金额和预订方式等因素而异）。如需了解详情，请查看条款和条件。“",
    "operating-airline": "运营航空公司",
    "fare-basis-code": "票价代码",
    "book-class-code": "Book类型码",
    "confirm-modify": "確認修改",
    "passenger-name-modify-notice": "在需要修改的乘客姓名重新填寫，再點擊確認",
    "non-baggage": "無行李",
    "resort-fee-notice": "不包含在總價，客人需到酒店付款",
    "resort-fee": "度假費用",
    "edit-name": "修改姓名",
    "payment-now": "現在支付",
    "you-will-pay-for-trip":
      '您將使用信用卡為此次旅行支付 <span class="h5"\n' +
      "                                  >{currency }\n" +
      "                                  { totalAmount}</span\n" +
      "                                >\n" +
      "                               ",
    "please-select-channel": "請選擇來源",
    "please-select-air-equip-type": "請選擇飛機設備類型",
    "please-select-required-attributes": "请选择附加服务",
    including: "包含",
    excluding: "排除",
    "select-flight-you-want-change": "請選擇您想修改的航班 !",
    "continue-modify": "是否繼續修改 ?",
    "supplier-fare-option": "供應商票價選擇",
    "supplier-fare-option-num": "{num} 種供應商票價選擇",
    "enter-your-passport-optional": "輸入您的護照號碼 (可選)",
    "expire-date-optional": "過期時間 (可選)",
    "choose-flight-to": "選擇前往 {locationName} 的航班",
    "additional-service-charge": "附加服務費用",
    "reservation-have-been-changed":
      "你的一些Reservations被航空公司更改了，需要你采取行動。現在你可以",
    "view-affected-reservations": "查看受影響的Reservations",
    "needs-review": "需要審查",
    "issuing-date": "出證日期",
    "my-trips": "我的行程",
    "new-details": "新票詳情",
    "previous-details": "原票詳情",
    summary: "概要",
    "changes-detected": "檢測到變化",
    "passenger-will-departure-from-to":
      "乘客將會在{later}之後從{from}出發 ,並且在{later}之後到達{to}",
    "flight-to-location": "航班去往 {location}",
    direct: "直達",
    "no-direct": "非直達",
    "flight-duration": "航班時長",
    "request-cancellation": "請求取消",
    "request-change": "請求更換",
    "issue-id": "發佈 Id",
    "issue-type-id": "發佈類型 Id",
    "info-source-id": "信息來源 Id",
    "create-time": "創建時間",
    "update-time": "更新時間",
    "direct-ticketing": "直接出票",
    "re-price": "重獲報價",
    "please-select-your-payment-method": "請選擇你的支付方式",
    cash: "信用額度",
    "credit-card": "信用卡",
    "directly-to-airline": "航空公司直收",
    "get-more-fare-options": "獲取更多報價",
    segments: "航段",
    "booking-date-start-to-end": "預定日期開始至預定日期結束",
    "unknown-seat": "未知座位",
    "fare-rule-description": "票價規則描述",
    "issue-ticket": "出票",
    "stored-fare": "存儲票價",
    "passenger-fare": "乘客票價",
    "total-fare": "總票價",
    "single-charge": "單人票價",
    "seat-details": "座位詳情",
    "accept-special-service-applications":
      "為方便更多旅客出行，本次航班可接受部分特殊服務申請。你只需簡單幾步即可完成申請",
    "edit-special-service": "添加特殊幫助",
    "add-special-assistance": "添加特殊幫助",
    "assistance-option": "可供幫助選項",
    "no-assistance-option-in-the-segment": "该航段没有提供特殊帮助",
    "select-the-special-help-you-need": "選擇您需要的特殊幫助",
    "no-additional-assistance": "沒有可選擇的附加服務",
    "optional-assistance": "可選擇的幫助",
    "frequent-flyer-number-correction": "常旅客號碼修正",
    "passenger-frequent-flyer-modify-notice":
      "在需要修改的乘客常飛計劃號碼重新填寫，再點擊確認",
    "frequent-flyer-number": "常飛旅客號碼",
    "edit-frequent-flyer-number": "添加常飛旅客號碼",
    mx: "跨性別",
    "my-choice": "我的選擇",
    "passenger-choice": "乘客選擇",
    "your-seat": "您選擇的座位",
    "filter-condition": "過濾條件",
    air: "機票",
    "car-transfer-shuttles": "車接",
    ferries: "渡輪",
    trip: "Trip",
    "trip-list": "Trip 列表",
    "trip-status": "Trip 狀態",
    trips: "Trips",
    "trips-list": "Trips 列表",
    "all-trips": "所有的 Trips",
    "trips-found": "條 Trips ",
    "create-new": "創建新的",
    "reservations-notice": "Reservations 通知",
    "total-taxes": "所有稅費",
    "taxes-name": "稅費名稱",
    "taxes-amount": "稅費價格",
    "add-entertainment": "增加娛樂",
    "passenger-taxes": "乘客稅費",
    "order-change-departure-date": "出發時間(以當地時間為準)",
    "flight-search": "機票搜索",
    "air-shopping": "機票搜索",
    "flight-itinerary": "航班行程",
    "flight-passenger": "航班乘客",
    "reservations-needing-review": "需要審查的 Reservations ",
    "held-reservations": "未支付的 reservations",
    "view-all-reservations": "查看所有的 reservations",
    "affected-reservations": "受影響的 reservations",
    "ticket-orders": "機票 Orders",
    female: "女",
    male: "男",
    unspecified: "未指明的",
    "enter-redress-number-option": "輸入補救號碼(可選)",
    "enter-known-traveler-number-option": "輸入已知的乘客號碼(可選)",
    "stored-fares": "已存儲的票價",
    "passenger-fares": "乘客票價",
    "view-fares": "查看票價",
    "equiv-fare": "Equiv Fare",
    "confirm-ticketing-now": "確定現在出票 ?",
    ok: "好的",
    "submit-order": "提交  Order",
    "search-order": "搜索 Order",
    "store-fare": "存儲票價",
    assistance: "帮助",
    minor: "未成年人",
    entertainment: "娱乐",
    television: "电视",
    games: "游戏",
    internet: "网络",
    power: "电",
    "entertainment-option": "娱乐选择",
    "passport-information-correction": "护照资料更正",
    "passenger-passport-modify-notice":
      "填写需要修改的旅客护照号码，单击“确认”",
    "edit-passport-number": "编辑护照号码",
    "confirm-to-change-to-your-passport": "您确认要换护照吗",
    "confirm-to-change-to-your-frequent-flyer-number":
      "您确认要更改您的常旅客号码吗",
    view: "查看",
    edit: "编辑",
    "passport-expiry-time": "护照有效期",
    null: "空",
    "redress-number": "旅客护照识别号码",
    "known-traveler-number": "已知旅客编号",
    "add-phone-number": "添加电话号码",
    "add-email-address": "添加电子邮件地址",
    "loyalty-info": "忠实客户信息",
    "you-will-modify-name-now": "现在您将修改名称",
    "gender-correction": "性别校正",
    "passport-correction": "护照校正",
    "birth-date-correction": "生日校正",
    "frequent-flyer-airline-correction": "常旅客航空公司修正",
    "redress-number-correction": "改正旅客护照识别号码",
    "traveler-number-correction": "旅客号码更正",
    "phone-number-correction": "电话号码更正",
    "email-correction": "电子邮件更正",
    "held-orders": "未支付订单",
    "view-all-orders": "查看所有订单",
    "selected-insurances": "选择保险",
    "selected-equips": "选择装备",
    "add-passengers": "添加乘客",
    "equip-type": "装备类型",
    "days-before-departure": "距离出发前日期",
    "days-after-departure": "距离出发后日期",
    "time-before-departure": "距离出发前时间(h)",
    "time-after-departure": "距离出发后时间(h)",
    "fares-and-fees": "费用及税费",
    "add-passenger-type": "添加乘客类型",
    "passenger-type-codes": "乘客类型代码(PTCs)",
    "add-account": "添加账户",
    "loyalty-accounts": "忠实账户",
    optional: "可选择的",
    attributes: "属性",
    "fare-types": "费用类型",
    "please-input-codes": "请输入代码",
    "code-input": "输入代码",
    "best-pricing-code": "最佳定价代码",
    configuration: "配置",
    "contract-id": "合同 ID",
    channel: "渠道",
    "content-options": "内容选项",
    "please-input-airlines": "请输入航空公司",
    "air-shopping-uppercase": "机票搜索",
    "modify-flight": "修改航班",
    "fare-options": "票价选项",
    "flight-to": "去往{locationCode}的航班",
    "some-fare-options-for-you": "这里有一些票价供你选择!",
    source: "来源",
    "booking-class": "订位舱等",
    loyalty: "忠诚客户",
    "fare-restrictions": "票价限制",
    services: "服务",
    vendor: "供应商",
    "view-segments": "查看航段",
    suppliers: "供应商(s)",
    sources: "来源(s)",
    "travel-and-baggage": "旅行和行李",
    "stopover-airport": "经停机场",
    "please-enter": "请输入",
    "add-ancillary-service": "添加附加服务",
    "additional-services-menu": "附加服务菜单",
    "current-segment": "当前航段",
    "current-passenger": "当前乘客",
    "select-details": "选择详情信息",
    "current-selection-details": "当前选择详情",
    "itinerary-info": "行程信息",
    "show-more-options": "展示更多选项",
    "price-breakdown": "价格明细",
    "view-price-breakdown": "查看价格明细",
    "view-price-summary": "查看价格摘要",
    "description-uppercase": "描述",
    "deferred-payment": "延期付款",
    "requester-name": "请求者姓名",
    "summary-uppercase": "概要",
    "quoted-fares": "報價",
    "price-quote-number": "報價號碼",
    "quote-number": "報價單號",
    delete: "刪除",
    "fare-quotes": "費用報價",
    "new-fare-quote": "新票價單機",
    system: "系統",
    "you-have-not-selected-fare": "您沒有選車票!請至少選擇一個",
    "marketing-carrier": "銷售航空公司",
    "please-enter-three-uppercase-code": "請輸入三個大寫代碼",
    "invalid-input": "無效輸入!",
    "ticketing-time-limit": "出票期限",
    "requested-ticketing-date": "請求出票日期",
    "change-itinerary": "修改行程",
    "add-new-flight": "添加新航班",
    "reshop-uppercase": "修改航班",
    "modify-success": "修改成功 !",
    "ticket-numbers": "機票號碼",
    "ticketing-date": "出票日期",
    "ticket-segments": "出票航段",
    "fare-type-code": "票價類型代號",
    "jobs-panel": "工作面板",
    job: "工作",
    "ancillary-service-selected": "已選擇附加服務",
    "see-descriptions": "查看描述",
    "purchase-additional-service": "購買附加服務",
    "per-unit": "單價",
    "no-services": "無服務",
    selection: "選擇",
    "no-selection": "無選擇",
    "services-summary": "服務概要",
    student: "学生",
    "purchase-ancillary-service": "購買附加服務",
    "seat-selection": "座位選擇",
    "student-price": "學生票價",
    available: "可用的",
    unavailable: "不可用的",
    selected: "已選擇",
    "seat-selected": "已選擇座位",
    "services-total": "服務總價",
    "ticket-service": "票價服務",
    "taxes-uppercase": "稅費",
    "continue-pay-services":
      "您現在需要繼續為附加服務支付{currencyCode} {amount}吗?",
    "you-will-pay-for-services": "您將為服務支付",
    "manage-ancillary-service": "管理輔助服務",
    "travel-approval": "履行批准",
    "manage-seat-selection": "管理座位選擇",
    "price-expiration-date-time": "價格到期日",
    "price-is-out-of-date": "您的價格過期了，需需要重新定價",
    "confirm-delete-flight": "您確定要刪除這個航班嗎 ?",
    "ticket-info": "票價信息",
    "back-to-reservation": "返回到reservation",
    "back-to-submit-order": "返回到提交訂單",
    action: "處理",
    choose: "選擇",
    "cancel-ticket": "取消機票",
    "you-will-cancel-this-ticket": "您將取消這張機票",
    "you-have-not-selected-ticket": "您沒有選票!請至少選擇一個",
    "void-ticket": "取消機票",
    "cancel-successful": "成功取消!",
    evening: "晚上",

    "login-success": "登入成功. 歡迎 {userId} . ",
    "login-failed": "登入失敗. 請再次檢查您的賬號/密碼.",
    "agreement-between-customer-and-sesamedoortravel":
      "客戶與sesamedoortravel.com之間的協議",
    "supplier-terms-and-conditions": "供應商條款與條件",
    "supplier-terms-and-conditions-content":
      "一般預訂條款和條件(“一般條款和條件”)以及您的預訂確認電子郵件(“預訂確認”)中列出的信息，規定了本公司同意透過網站向您提供服務的條款(見下文定義條款)。",
    "contact-address": "香港鰂魚湧海澤街28號東區海港中心14樓1411室",
    "copyright-info": "版權所有©{year} LimpidThemes。版權所有。",
  },
};

export default {
  messages,
};
