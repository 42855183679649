import * as mutationName from "@/store/modules/package/mutationTypes";
import PackageAPI from "@/apis/PackageAPI";
import DynamicPkgUtils from "@/utils/DynamicPkgUtils";
import { ElMessage } from "element-plus";
import HotelAPI from "@/apis/HotelAPI";
import FlightAPI from "@/apis/FlightAPI";
import router from "@/router";

const actions = {
  async availPkg({ commit }, payload) {
    await PackageAPI.avail(
      payload.originCode,
      payload.destCode,
      payload.cabinPrefs,
      payload.ttiCode,
      payload.bestOnlyIndicator,
      payload.checkDate[0],
      payload.checkDate[1],
      payload.roomNum,
      payload.adultNum,
      payload.childNum,
      payload.language,
      payload.childAges
    )
      .then((res) => {
        let packageCityAvailRS = res.data;
        commit(mutationName.UPDATE_CITY_HOTELS_AVAIL, {
          packageCityAvailRS: packageCityAvailRS,
          pageSize: payload.pageSize,
        });
      })
        .catch((error) => {
          //console.info(error);
          ElMessage({
            message: error.message,
            type: "error",
          });
        });
  },
  resetState({ commit }, payload) {
    commit(mutationName.RESET_STATE, {
      stateName: payload.stateName,
    });
  },
  paginationHotels({ commit }, payload) {
    commit(mutationName.UPDATE_HOTELS_SHOW, payload);
  },
  paginationFlights({ commit }, payload) {
    commit(mutationName.UPDATE_FLIGHTS_SHOW, payload);
  },
  searchHotelDetails({ commit }, payload) {
    PackageAPI.avail(
      payload.originCode,
      payload.destCode,
      payload.cabinPrefs,
      payload.ttiCode,
      payload.bestOnlyIndicator,
      payload.checkDate[0],
      payload.checkDate[1],
      payload.roomNum,
      payload.adultNum,
      payload.childNum,
      payload.language,
      payload.childAges
    )
      .then((res) => {
        let hotelDetailsAvailRS = res.data;
        // ////console.info(hotelDetailsAvailRS);
        commit(mutationName.UPDATE_HOTEL_DETAILS_AVAIL, {
          packageHotelDetailsAvailRS: hotelDetailsAvailRS,
        });

        HotelAPI.descriptive(payload.ttiCode, payload.language)
          .then((res) => {
            ////console.info(res.data);
            let hotelDescriptionRS = res.data;
            commit(mutationName.UPDATE_HOTEL_DESCRIPTION, {
              hotelDescriptionRS: hotelDescriptionRS,
            });
          })
          .catch((error) => {
            //console.info(error);
            ElMessage({
              message: error,
              type: "error",
            });
          });
      })
      .catch((error) => {
        //console.info(error);
        ElMessage({
          message: error,
          type: "error",
        });
      });
  },
  searchHotelDescription({ commit }, payload) {
    HotelAPI.descriptive(payload.ttiCode, payload.language)
      .then((res) => {
        ////console.info(res.data);
        let hotelDescriptionRS = res.data;
        commit(mutationName.UPDATE_HOTEL_DESCRIPTION, {
          hotelDescriptionRS: hotelDescriptionRS,
        });
      })
      .catch((error) => {
        //console.info(error);
        ElMessage({
          message: error,
          type: "error",
        });
      });
  },
  confirm({ commit }, payload) {
    PackageAPI.confirm(
      payload.checkInDate,
      payload.checkOutDate,
      payload.roomStayList,
      payload.serviceList,
      payload.resGuestList
    )
      .then((res) => {
        ////console.info(res.data);
        let confirmRS = res.data;
        if (confirmRS.success) {
          let preBookRQ = DynamicPkgUtils.getHotelResRQ(
            "Initiate",
            payload.checkInDate,
            payload.checkOutDate,
            payload.roomStayList,
            payload.serviceList,
            payload.resGuestList
          );
          commit(mutationName.UPDATE_CONFIRM, {
            confirmRS: confirmRS,
            preBookRQ: preBookRQ,
          });
        } else if (confirmRS.errors.errors) {
          //console.info(confirmRS.errors.errors);
          ElMessage({
            message:
              "This is an instant confirmation booking and you do not have the permission to book.",
            type: "error",
          });
        }
      })
      .catch((error) => {
        //console.info(error);
        ElMessage({
          message: error,
          type: "error",
        });
      });
  },
  preBook({ commit }, payload) {
    PackageAPI.preBook(payload.preBookRQ)
      .then((res) => {
        ////console.info(res.data);
        let preBookRS = res.data;
        if (preBookRS.success) {
          commit(mutationName.UPDATE_PREBOOK, {
            preBookRS: preBookRS,
          });
        } else {
          commit(mutationName.UPDATE_PREBOOK, {
            preBookRS: null,
          });
        }
      })
      .catch((error) => {
        //console.info(error);
        ElMessage({
          message: error,
          type: "error",
        });
      });
  },
  saveBook({ commit }, payload) {
    commit(mutationName.UPDATE_BOOK, {
      bookRS: payload.bookRS,
    });
  },
  /* 机票相关*/
  updateFlightInfos({ commit }, payload) {
    commit(mutationName.UPDATE_FLIGHTS_INFO, payload);
  },
  verifyFlightItineraryPrice({ commit }, payload) {
    FlightAPI.verifyPrice(
      payload.airItinerary,
      payload.passengerTypeQuantityList
    )
      .then((res) => {
        let data = res.data;
        if (data.success) {
          commit(mutationName.VERIFY_PRICE_FLIGHT_ITINERARY, {
            verifyPriceRS: data,
          });
        } else {
          commit(mutationName.VERIFY_PRICE_FLIGHT_ITINERARY, {
            verifyPriceRS: null,
          });
          ElMessage({
            message: data.errors.errors[0].stringValue,
            type: "error",
          });
        }
      })
      .catch((error) => {
        ElMessage({
          message: error,
          type: "error",
        });
      });
    commit(mutationName.VERIFY_PRICE_FLIGHT_ITINERARY, payload);
  },
  searchFareRules({ commit }, payload) {
    FlightAPI.fareRules(payload.rph)
      .then((res) => {
        let data = res.data;
        commit(mutationName.UPDATE_FLIGHT_RULES, {
          fareRulesRS: data,
        });
      })
      .catch((error) => {
        ElMessage({
          message: error,
          type: "error",
        });
      });
  },
  searchOffersAndSeatMap({ commit }, payload) {
    FlightAPI.getOffers(payload.quoteId)
      .then((res) => {
        let data = res.data;
        commit(mutationName.UPDATE_FLIGHT_OFFERS_SEATMAP, {
          offersRS: data,
        });
      })
      .catch((error) => {
        ElMessage({
          message: error,
          type: "error",
        });
      });
  },
  /* 机加酒相关*/
  verifyPackage({ commit }, payload) {
    PackageAPI.verifyPrice(
      payload.airItinerary,
      payload.roomStays,
      payload.checkInDate,
      payload.checkOutDate,
      payload.passengerInfos,
      payload.username,
      payload.status,
      payload.quoteId
    ).then((res) => {
      let data = res.data;
      if (data.success || data.success == null) {
        commit(mutationName.VERIFY_PRICE_FLIGHT_ITINERARY, {
          verifyPriceRS: {
            pricedItineraryList: [
              data.dynamicPackage.components.airComponents[0],
            ],
          },
        });
        commit(mutationName.UPDATE_CONFIRM, {
          confirmRS: {
            hotelReservationList: [
              data.dynamicPackage.components.hotelComponents[0],
            ],
            pricedItineraryList: [
              data.dynamicPackage.components.airComponents[0],
            ],
          },
        });
      } else if (data.errors && data.errors.errors) {
        let errorMessage =
          data.errors.errors[0].shortText != null
            ? data.errors.errors[0].shortText
            : data.errors.errors[0].stringValue;
        router.push({
          path: "error",
          query: {
            errorMessage: errorMessage,
          },
        });
      } else {
        commit(mutationName.VERIFY_PRICE_FLIGHT_ITINERARY, {
          verifyPriceRS: null,
        });
        ElMessage({
          message: data.errors.errors[0].stringValue,
          type: "error",
        });
      }
    });
  },
  prebookPackage({ commit }, payload) {
    PackageAPI.preBook(
      payload.airItinerary,
      payload.roomStays,
      payload.checkInDate,
      payload.checkOutDate,
      payload.passengerInfos,
      payload.username,
      payload.status,
      payload.quoteId,
      payload.seatRequests,
      payload.pricedOffers
    ).then((res) => {
      let preBookRS = res.data;
      if (preBookRS.success || preBookRS.success == null) {
        let packagePreBookRQ = DynamicPkgUtils.getPackageResRQ(
          payload.airItinerary,
          payload.roomStays,
          payload.checkInDate,
          payload.checkOutDate,
          payload.passengerInfos,
          payload.username,
          payload.status,
          payload.quoteId
        );
        commit(mutationName.UPDATE_PREBOOK, {
          hotelReservation:
            preBookRS.dynamicPackage.components.hotelComponents[0],
          airReservation: preBookRS.dynamicPackage.components.airComponents[0],
          packagePreBookRQ: packagePreBookRQ,
        });
      } else if (preBookRS.errors && preBookRS.errors.errors) {
        let errorMessage =
          preBookRS.errors.errors[0].shortText != null
            ? preBookRS.errors.errors[0].shortText
            : preBookRS.errors.errors[0].stringValue;
        router.push({
          path: "error",
          query: {
            errorMessage: errorMessage,
          },
        });
      } else {
        commit(mutationName.UPDATE_PREBOOK, {
          hotelReservation: null,
          airReservation: null,
        });
        ElMessage({
          message: preBookRS.errors.errors[0].stringValue,
          type: "error",
        });
      }
    });
  },
  bookPackage({ commit }, payload) {
    PackageAPI.book(
      payload.packageBookRQ,
      payload.seatRequests,
      payload.pricedOffers
    ).then((res) => {
      let data = res.data;
      if (data.success || data.success == null) {
        let packagePreBookRQ = DynamicPkgUtils.getPackageResRQ(
          payload.airItinerary,
          payload.roomStays,
          payload.checkInDate,
          payload.checkOutDate,
          payload.passengerInfos,
          payload.username,
          payload.status,
          payload.quoteId
        );
        commit(mutationName.UPDATE_PREBOOK, {
          hotelReservation: data.dynamicPackage.components.hotelComponents[0],
          airReservation: data.dynamicPackage.components.airComponents[0],
          packagePreBookRQ: packagePreBookRQ,
        });
      } else {
        commit(mutationName.UPDATE_PREBOOK, {
          hotelReservation: null,
          airReservation: null,
        });
        ElMessage({
          message: data.errors.errors[0].stringValue,
          type: "error",
        });
      }
    });
  },
};

export default actions;
